import FDVue from "@fd/lib/vue";
import {
  FDColumnDirective,
  FDHiddenArgumentName,
  FDRowNavigateDirective
} from "@fd/lib/vue/utility/dataTable";
import { PersonWithExtraDetails } from "../../dataMixins/peopleList";
import { getYearsDifference } from "@fd/lib/client-util/datetime";

const PeopleList = FDVue.extend({
  name: "fd-people-list",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {},

  props: {
    items: {
      default: () => [],
      type: Array
    },
    tablesearch: {
      default: "",
      type: String
    },
    processing: {
      default: false,
      type: Boolean
    },
    archivedLoading: {
      default: false,
      type: Boolean
    },
    showArchived: {
      default: false,
      type: Boolean
    },
    showArchivedDateRange: {
      default: () => [],
      type: Array
    },
    showArchivedMinDate: {
      default: undefined,
      type: Date
    },
    showArchivedMaxDate: {
      default: undefined,
      type: Date
    },
    anyInactiveUsers: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    iconColumnArgument(): string {
      return this.anyInactiveUsers ? "isActiveWithUnusedLogin" : FDHiddenArgumentName;
    }
  },

  methods: {}
});

export default PeopleList;

