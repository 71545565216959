import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { SkillsChallengeWithDetails, skillsChallengeService } from "../services";

const SkillsChallengePlayerNewDialog = FDVue.extend({
  name: "fd-player-new-dialog",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  mixins: [rules, dialogSupport],

  props: {
    sessionID: String
  },

  data: function() {
    return {
      // used to ensure the history panel is open on load
      panel: 0,
      items: 5,

      saving: false,
      newChallenge: {} as SkillsChallengeWithDetails,
      savedChallenges: [] as SkillsChallengeWithDetails[]
    };
  },

  computed: {
    challengeRules(): any {
      return {
        playerName: [this.rules.required],
        playerEmail: [this.rules.validEmail]
      };
    }
  },

  methods: {
    async startNewChallenge() {
      this.newChallenge = {
        sessionID: this.$props.sessionID,
        playerName: "",
        playerEmailAddress: ""
      } as SkillsChallengeWithDetails;
    },

    async onSubmit(e: Event) {
      await this.saveChallenge();
      e.preventDefault();
    },

    cancelDialog() {
      this.closeDialog(false);
    },

    async saveChallenge() {
      this.inlineMessage.message = "";

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        let newID = await skillsChallengeService.addItem(this.newChallenge);
        this.newChallenge.id = newID;

        var snackbarPayload = {
          text: this.$t("sessions.new-player-added", [this.newChallenge.playerName]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);

        this.savedChallenges.push(this.newChallenge);
        this.startNewChallenge();

        (this.$refs.playerNameField as HTMLInputElement).focus();

        return true;
      } catch (error) {
        this.handleError(error);
        return false;
      } finally {
        this.saving = false;
        this.processing = false;
      }
    }
  },

  mounted: function() {
    // This gets called in mounted as we need to wait until the props are set
    this.startNewChallenge();
  },

  created: async function() {}
});

export default SkillsChallengePlayerNewDialog;

export async function showSkillsChallengePlayerNewDialog(
  sessionID: String | undefined
): Promise<any> {
  let dialog = createDialog(SkillsChallengePlayerNewDialog);
  dialog.optOutOfErrorHandling();
  dialog.$props.sessionID = sessionID;

  let result = await dialog.showDialog();
  return result;
}

