var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "v-toolbar",
            {
              class: [
                _vm.processing
                  ? "view-card-top-color-processing"
                  : "view-card-top-color"
              ],
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0 order-0", attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0 mt-0 pt-md-3 mt-md-1",
                        attrs: {
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:name",
                value: _vm.$t("common.name"),
                expression: "$t('common.name')",
                arg: "name"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:description.hide-when-extra-small",
                value: _vm.$t("common.description"),
                expression: "$t('common.description')",
                arg: "description",
                modifiers: { "hide-when-extra-small": true }
              }
            ],
            attrs: {
              items: _vm.stations,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0",
              "show-expand": ""
            },
            scopedSlots: _vm._u([
              {
                key: "expanded-item",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "td",
                      { attrs: { colspan: 3 } },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass:
                              "fd-expanding-table-single-details-section"
                          },
                          [
                            item.tracksDuration
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "tight-col",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v("\n            Tracks "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "green" } },
                                      [_vm._v("time spent at the station")]
                                    ),
                                    _vm._v(",\n            "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          (item.pointsPerSecond || 0) > 0
                                            ? "adding"
                                            : "removing"
                                        ) +
                                          "\n              " +
                                          _vm._s(
                                            Math.abs(item.pointsPerSecond || 0)
                                          ) +
                                          " point" +
                                          _vm._s(
                                            Math.abs(
                                              item.pointsPerSecond || 0
                                            ) == 1
                                              ? ""
                                              : "s"
                                          )
                                      )
                                    ]),
                                    _vm._v(
                                      "\n            per second recorded.\n          "
                                    )
                                  ]
                                )
                              : _c(
                                  "v-col",
                                  {
                                    staticClass: "tight-col",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v("Does not track "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("time spent at the station")]
                                    ),
                                    _vm._v(".")
                                  ]
                                ),
                            _vm._v(" "),
                            item.tracksSuccesses
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "tight-col",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v("\n            Tracks "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "green" } },
                                      [_vm._v("successful actions")]
                                    ),
                                    _vm._v(",\n            "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          (item.pointsPerSuccess || 0) > 0
                                            ? "adding"
                                            : "removing"
                                        ) +
                                          "\n              " +
                                          _vm._s(
                                            Math.abs(item.pointsPerSuccess || 0)
                                          ) +
                                          " point" +
                                          _vm._s(
                                            Math.abs(
                                              item.pointsPerSuccess || 0
                                            ) == 1
                                              ? ""
                                              : "s"
                                          )
                                      )
                                    ]),
                                    _vm._v(
                                      "\n            per successful action.\n            "
                                    ),
                                    (item.fullSuccessPoints || 0) != 0
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "tight-col",
                                                attrs: { cols: "12" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    * Will\n                "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      (item.fullSuccessPoints ||
                                                        0) > 0
                                                        ? "add"
                                                        : "remove"
                                                    ) +
                                                      "\n                  " +
                                                      _vm._s(
                                                        Math.abs(
                                                          item.fullSuccessPoints ||
                                                            0
                                                        )
                                                      ) +
                                                      " additional point" +
                                                      _vm._s(
                                                        Math.abs(
                                                          item.fullSuccessPoints ||
                                                            0
                                                        ) == 1
                                                          ? ""
                                                          : "s"
                                                      )
                                                  )
                                                ]),
                                                _vm._v(
                                                  " upon reaching " +
                                                    _vm._s(item.successGoal) +
                                                    " success" +
                                                    _vm._s(
                                                      item.successGoal == 1
                                                        ? ""
                                                        : "es"
                                                    ) +
                                                    ".\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    (item.noSuccessPoints || 0) != 0
                                      ? _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "tight-col",
                                                attrs: { cols: "12" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    * Will\n                "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      (item.noSuccessPoints ||
                                                        0) > 0
                                                        ? "add"
                                                        : "remove"
                                                    ) +
                                                      "\n                  " +
                                                      _vm._s(
                                                        Math.abs(
                                                          item.noSuccessPoints ||
                                                            0
                                                        )
                                                      ) +
                                                      " additional point" +
                                                      _vm._s(
                                                        Math.abs(
                                                          item.noSuccessPoints ||
                                                            0
                                                        ) == 1
                                                          ? ""
                                                          : "s"
                                                      )
                                                  )
                                                ]),
                                                _vm._v(
                                                  " when 0% successful.\n              "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _c(
                                  "v-col",
                                  {
                                    staticClass: "tight-col",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v("Does not track "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("successful actions")]
                                    ),
                                    _vm._v(".")
                                  ]
                                ),
                            _vm._v(" "),
                            item.tracksErrors
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "tight-col",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v("\n            Tracks "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "green" } },
                                      [_vm._v("unsuccessful actions")]
                                    ),
                                    _vm._v(",\n            "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          (item.pointsPerError || 0) > 0
                                            ? "adding"
                                            : "removing"
                                        ) +
                                          "\n              " +
                                          _vm._s(
                                            Math.abs(item.pointsPerError || 0)
                                          ) +
                                          " point" +
                                          _vm._s(
                                            Math.abs(
                                              item.pointsPerError || 0
                                            ) == 1
                                              ? ""
                                              : "s"
                                          )
                                      )
                                    ]),
                                    _vm._v(
                                      "\n            per error recorded.\n          "
                                    )
                                  ]
                                )
                              : _c(
                                  "v-col",
                                  {
                                    staticClass: "tight-col",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v("Does not track "),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("unsuccessful actions")]
                                    ),
                                    _vm._v(".")
                                  ]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      { staticClass: "table-actions-column-min-width" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: {
                                              icon: "",
                                              disabled: _vm.processing
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                disabled: _vm.processing
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteStation(item)
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-delete\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("common.remove")))
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }