var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.closeWithSelectedData } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ]
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("sessions.email.confirm-recipients"))
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _vm.inlineMessage.message
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mt-0 mb-3",
                              attrs: { type: _vm.inlineMessage.type }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _vm._l(_vm.recipients, function(recipient, key) {
                        return _c(
                          "v-col",
                          { key: key, attrs: { cols: "12" } },
                          [
                            _c("v-switch", {
                              attrs: {
                                label:
                                  recipient.name +
                                  " (" +
                                  (!!recipient.emailAddress
                                    ? recipient.emailAddress
                                    : _vm.$t("sessions.email.unknown-email")) +
                                  ")",
                                disabled:
                                  !recipient.emailAddress || _vm.processing
                              },
                              model: {
                                value: recipient.selected,
                                callback: function($$v) {
                                  _vm.$set(recipient, "selected", $$v)
                                },
                                expression: "recipient.selected"
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: _vm.$t("sessions.email.copy-current-user"),
                              disabled: _vm.processing
                            },
                            model: {
                              value: _vm.copyCurrentUser,
                              callback: function($$v) {
                                _vm.copyCurrentUser = $$v
                              },
                              expression: "copyCurrentUser"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", disabled: _vm.processing },
                      on: {
                        click: function($event) {
                          return _vm.closeDialog(null)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.processing },
                      on: { click: _vm.closeWithSelectedData }
                    },
                    [_vm._v(_vm._s(_vm.$t("sessions.email.send")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }