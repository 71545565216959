import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import i18n from "@fd/current/client/i18n";
import {
  SessionWithAppointment,
  appointmentService,
  reportService,
  sessionService,
  sessionTypeService,
  SessionTypeWithAppointmentType,
  Appointment
} from "../services";
import {
  showSessionNewDialog,
  showSessionScheduledDialog
} from "./components/SessionNewDialog.vue";
import * as DateUtil from "@fd/lib/client-util/datetime";
import * as SessionsList from "./components/SessionsList.vue";

export default FDVue.extend({
  name: "fd-sessions",

  mixins: [rules, errorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-sessions-list": SessionsList.default
  },

  data: () => ({
    // Variable to hold which expansion panel is currently open
    panel: 0,
    // Overrides from FDItemList
    listRouteUrl: "sessions",
    title: "Sessions",

    tablesearch: "",

    // Data
    scheduledSessions: [] as SessionsList.SessionDetails[],
    unscheduledSessions: [] as SessionsList.SessionDetails[],
    sessionTypes: [] as SessionTypeWithAppointmentType[]
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["startScheduledSession", "createUnscheduledSession"];
    }
  },

  methods: {
    async startScheduledSession(item: any) {
      let appointment = item as Appointment;
      let sessionType = this.sessionTypes.filter(function(type) {
        return (
          !!appointment.appointmentTypeID &&
          appointment.appointmentTypeID == type.acuityAppointmentTypeID
        );
      })[0];

      let newFullDateTimeString = `${DateUtil.isoDateString(
        appointment.date
      )} ${DateUtil.isoTimeString(appointment.time)}`;
      let sessionStartDateTime = new Date(DateUtil.localizedDateTimeString(newFullDateTimeString));
      let data = await showSessionScheduledDialog(sessionType.id!, sessionStartDateTime);
      if (!data) return;

      var session = {
        sessionTypeID: data.sessionTypeID,
        personID: data.personID,
        acuityAppointmentID: item.id,
        startedTime: data.startDateTime,
        enabled: true
      } as SessionWithAppointment;
      var amendedSession = this.startNewSession(session);
      item.session = amendedSession;
    },
    // Called from the "Add New" button.  Opens the dialog, waits for the data, then starts a new UNSCHEDULED session
    async createUnscheduledSession() {
      let data = await showSessionNewDialog();
      if (!data) return;

      this.startUnscheduledSession(data.sessionTypeID, data.personID, data.startDateTime);
    },
    startUnscheduledSession(sessionTypeID: string, personID: string, sessionStartDateTime: Date) {
      if (!sessionStartDateTime) sessionStartDateTime = new Date();
      var session = {
        sessionTypeID: sessionTypeID,
        personID: personID,
        startedTime: sessionStartDateTime,
        enabled: true
      } as SessionWithAppointment;
      this.startNewSession(session);
    },
    async startNewSession(session: SessionWithAppointment) {
      this.processing = true;
      try {
        var newID = await sessionService.addItem(session);
        session.id = newID;
        this.workSession(session);
        return {
          ...session,
          formattedStartedTime: DateUtil.localizedDateTimeString(session.startedTime)
        };
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },
    workSession(session: SessionWithAppointment) {
      let shotTrackingID = this.sessionTypes.filter(x => x.name == "Shot Tracking")[0].id;
      if (session.sessionTypeID == shotTrackingID) {
        this.$router.push(`/shottracker/${session.id}`);
      } else {
        this.$router.push(`/skillschallenge/${session.id}`);
      }
    },
    viewSession(session: SessionWithAppointment) {
      let shotTrackingID = this.sessionTypes.filter(x => x.name == "Shot Tracking")[0].id;
      if (session.sessionTypeID == shotTrackingID) {
        this.$router.push(`/shottracker/${session.id}`);
      } else {
        this.$router.push(`/skillschallenge/${session.id}`);
      }
    }
  },
  mounted: function() {},
  created: async function() {
    this.$store.commit("NOTIFY_NEW_BREADCRUMB", {
      text: this.title,
      to: `/${this.listRouteUrl}`,
      resetHistory: true
    });

    this.processing = true;
    try {
      var sessionTypes = await sessionTypeService.getAll();
      this.sessionTypes = sessionTypes;

      var scheduledSessions: Appointment[] = await appointmentService.getWithDateRange(
        new Date(DateUtil.isoDateString(new Date())),
        new Date("9999-12-31")
      );
      let mappedScheduledSessions = scheduledSessions.map(function(x) {
        return {
          id: x.id,
          name: x.name,
          dateTime: new Date(
            `${DateUtil.stripTimeFromLocalizedDateTime(
              x.date
            )} ${DateUtil.stripDateFromLocalizedDateTime(x.time)}`
          ),
          formattedDateTime: `${DateUtil.stripTimeFromLocalizedDateTime(
            x.date
          )} ${DateUtil.stripDateFromLocalizedDateTime(x.time)}`,
          type: x.type,
          sessionStatus: !x.session
            ? i18n.t("sessions.none")
            : !x.session.completedTime
            ? i18n.t("sessions.in-progress-status-message")
            : i18n.t("sessions.complete-status-message"),
          canHaveSession:
            sessionTypes.filter(function(type) {
              return !!x.appointmentTypeID && x.appointmentTypeID == type.acuityAppointmentTypeID;
            }).length > 0,
          appointment: x,
          session: !!x.session
            ? {
                ...x.session,
                formattedStartedTime: DateUtil.localizedDateTimeString(x.session.startedTime)
              }
            : undefined
        } as SessionsList.SessionDetails;
      });
      this.scheduledSessions = mappedScheduledSessions;

      var unscheduledSessions = await sessionService.getUnscheduled();
      let mappedUnscheduledSessions = unscheduledSessions.map(function(x) {
        return {
          id: x.id,
          name: x.personName,
          dateTime: x.startedTime,
          formattedDateTime: DateUtil.localizedDateTimeString(x.startedTime),
          type: x.sessionTypeName,
          sessionStatus: !x.completedTime
            ? i18n.t("sessions.in-progress-status-message")
            : i18n.t("sessions.complete-status-message"),
          canHaveSession: true,
          appointment: undefined,
          session: x
        } as SessionsList.SessionDetails;
      });
      this.unscheduledSessions = mappedUnscheduledSessions;
    } catch (error) {
      this.inlineMessage.message = this.$t("unexpected-network-error");
      this.inlineMessage.type = "error";
    } finally {
      this.processing = false;
    }
  }
});

