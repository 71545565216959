import FDVue from "@fd/lib/vue";
import { mapActions } from "vuex";
import i18n from "../../i18n";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import userData from "../../dataMixins/person";
import { loginService, personStickService, userService } from "../../services";
import { VForm } from "@fd/lib/vue/types";
import Slim from "@fd/lib/vue/components/SlimCropper.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import fileHandling, {
  confirmUniqueName,
  isFilePreviewable,
  FileData
} from "@fd/lib/vue/mixins/fileHandling";
import ServiceError from "@fd/lib/client-util/serviceError";

const PersonNewDialog = FDVue.extend({
  name: "fd-person-new-dialog",
  mixins: [dialogSupport, userData.new, fileHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "slim-cropper": Slim,
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  data: () => ({
    // *** GLOBAL ***
    step: 1,
    lastStep: 4,

    detailsStep: 1,
    detailserror: false,

    stickStep: 2,
    stickerror: false,

    securityStep: 3,
    permissionserror: false,

    filesStep: 4,
    fileserror: false,

    // The following will control whether or not the save button shows the processing/loading indicator
    saving: false,

    // *** DETAILS ***
    userPhoto: null as File | null,

    // *** FILES ***
    //The following is just to MOCKUP MOBILE NUMBER LISTING
    tablesearchfiles: "",
    files: [] as FileData[]
  }),

  watch: {
    "user.canLogIn": function(newValue, oldValue) {
      if (!newValue) {
        this.user.canConfigureSettings = false;
      }
    }
  },

  computed: {
    // *** DETAILS ***
    userEmailAddress: {
      get(): string | undefined {
        return this.user.emailAddresses?.length
          ? this.user.emailAddresses[0].emailAddress
          : undefined;
      },
      set(val: string) {
        var userEmailAddress;
        if (this.user.emailAddresses?.length) {
          userEmailAddress = this.user.emailAddresses[0];
        } else {
          userEmailAddress = {
            id: undefined,
            emailAddress: "",
            isVerified: false,
            allowNotifications: true,
            isPrimary: true
          };
          this.user.emailAddresses = [userEmailAddress];
        }
        userEmailAddress.emailAddress = val;
      }
    },
    userPhoneNumber: {
      get(): string | undefined {
        return this.user.phoneNumbers?.length ? this.user.phoneNumbers[0].phoneNumber : undefined;
      },
      set(val: string) {
        var userPhoneNumber;
        if (this.user.phoneNumbers?.length) {
          userPhoneNumber = this.user.phoneNumbers[0];
        } else {
          userPhoneNumber = {
            id: undefined,
            phoneNumber: "",
            type: "",
            isVerified: false,
            allowNotifications: true,
            isPrimary: true
          };
          this.user.phoneNumbers = [userPhoneNumber];
        }
        userPhoneNumber.phoneNumber = val;
      }
    }
  },

  methods: {
    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.saveDialog();
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    validate(): boolean {
      this.detailserror = !(this.$refs.detailsform as VForm).validate();
      this.fileserror = !(this.$refs.filesform as VForm).validate();
      return !(this.detailserror || this.fileserror);
    },

    // Method used in conjunction with the Cancel dialog.
    cancelDialog() {
      this.closeDialog!(false);
    },

    //Method used in conjunction with new view dialog.
    async saveDialog() {
      if (!this.user.emailAddresses) return;
      if (!this.validate()) {
        var message = i18n.t("users.new-user.error-message");
        if (this.detailserror) message += "\n\t- " + i18n.t("users.new-user.steps.details");
        if (this.fileserror) message += "\n\t- " + i18n.t("users.new-user.steps.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      try {
        var newUserID = await this.saveUser();
        this.user.stick.personID = newUserID;

        if (!!this.user.stick.id) {
          await personStickService.updateItem(this.user.stick.id!, this.user.stick);
        } else {
          this.user.stick.id = await personStickService.addItem(this.user.stick);
        }

        if (this.userPhoto) {
          await userService.uploadUserPhoto(newUserID, this.userPhoto);
        }

        if (this.files.length) {
          for (let index = 0; index < this.files.length; index++) {
            const file = this.files[index];
            await userService.uploadUserFile(newUserID, file.name, file.file as Blob);
          }
        }

        // TODO: The save user might work but the new account notice might not - how do we address this?
        await loginService.sendNewAccountNotice(
          `${document.location.protocol}//${document.location.host}`,
          newUserID!
        );

        this.closeDialog(true);
      } catch (error) {
        this.handleError(error as ServiceError);
      } finally {
        this.processing = false;
      }
    },

    ...mapActions({}),

    // *** FILES ***
    async addFile(file: any) {
      if (!file) return;

      var resizedFile = file;
      var isImage = isFilePreviewable(file.name);
      if (isImage) {
        resizedFile = await this.resizeImageFile(file, 1024, 768);
      }

      var fileData: FileData = {
        name: confirmUniqueName(file.name, this.files),
        file: resizedFile,
        isPreviewable: isImage
      };
      this.files.push(fileData);
    },
    viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      this.imageSource = this.covertFileToDataURL(fileData.file);
    },
    removeFile(file: any) {
      var fileIndex = this.files.indexOf(file);
      if (fileIndex == undefined) return;

      this.files.splice(fileIndex, 1);
    }
  },

  created: async function() {}
});

export default PersonNewDialog;

export async function createNewPerson(): Promise<boolean> {
  let dialog = createDialog(PersonNewDialog);
  return await dialog.showDialog!();
}

