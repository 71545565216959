import { Module, GetterTree, ActionTree, MutationTree, ModuleTree } from "vuex";
import i18n from "../i18n";
import router from "../router";

import { showDisableInsteadConfirmation } from "../../../common/client/views/components/DisableInsteadDialog.vue";

// This is based on the Module<S, R> type; the S parameter is the local scope for the module,
// and the R parameter is used to figure out what exists in child scopes (i.e. included child
// modules); we need to extend this so that the caller can call our methods with our own state
// being accessible from actions, mutations, etc. without forcing the caller to redefine the
// base available state variables; this type is essentially an overlap with
// Module<TOverrideState & TBaseState, TRootState> but with a little bit of stuff removed from
// the state property; refer to Vue docs for more details on Module<S, R>
export interface OverriddenModule<TOverrideState, TBaseState, TRootState> {
  namespaced?: boolean;
  state?: TOverrideState | (() => TOverrideState);
  getters?: GetterTree<TOverrideState & TBaseState, TRootState>;
  actions?: ActionTree<TOverrideState & TBaseState, TRootState>;
  mutations?: MutationTree<TOverrideState & TBaseState>;
  modules?: ModuleTree<TRootState>;
}

export interface RootState {
  // TODO: Implement me
}

export interface AutomaticCrudObject {
  id?: string;
  name?: string;
  enabled?: boolean;
  archivedDate?: Date | null | undefined;
}

export interface AutomaticCrudState<T extends AutomaticCrudObject> {
  fullList: T[];
}

export interface AutomaticCrudService<T extends AutomaticCrudObject> {
  getAll(): Promise<T[]>;
  getByID(id: string): Promise<T>;
  addItem(item: T): Promise<string>;
  updateItem(id: string, item: T): Promise<void>;
  deleteItem(id: string): Promise<boolean>;
}

export interface AutomaticArchivedCrudService<T extends AutomaticCrudObject> {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null
  ): Promise<T[]>;
  getByID(id: string): Promise<T>;
  addItem(item: T): Promise<string>;
  updateItem(id: string, item: T): Promise<void>;
  deleteItem(id: string): Promise<boolean>;
}

export function compareCrudObjectByName(a: AutomaticCrudObject, b: AutomaticCrudObject): number {
  // TODO: x.name isn't actually optional in this context, we should update typings to reflect this
  let aName = a.name!.toLocaleLowerCase();
  let bName = b.name!.toLocaleLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}

export type OneToOneRelatedObject<TConsumer, TKey extends keyof TConsumer> = {
  [P in TKey]: string;
};

export type OneToManyRelatedObject<TConsumer, TKey extends keyof TConsumer> = {
  [P in TKey]: string[];
};

export function getOneToOneItemsInUseCheck<TConsumer, TKey extends keyof TConsumer>(
  itemOrIDs: OneToOneRelatedObject<TConsumer, TKey>[] | string | string[],
  idPropertyKey: TKey
): (item: AutomaticCrudObject) => boolean {
  return function(item) {
    if (Array.isArray(itemOrIDs)) {
      return (
        itemOrIDs.findIndex((x: OneToOneRelatedObject<TConsumer, TKey> | string) =>
          typeof x === "string" ? x == item.id : x[idPropertyKey] == item.id
        ) !== -1
      );
    } else {
      return itemOrIDs == item.id;
    }
  };
}

export function getOneToManyItemsInUseCheck<TConsumer, TKey extends keyof TConsumer>(
  itemOrIDs: OneToManyRelatedObject<TConsumer, TKey>[] | string | string[],
  idPropertyKey: TKey
): (item: AutomaticCrudObject) => boolean {
  return function(item) {
    // The item is in use if we can find a consuming item that uses it; to make this function
    // easier to use we need to check for either one-to-one IDs (strings) or one-to-many IDs
    // (arrays)
    if (Array.isArray(itemOrIDs)) {
      return (
        itemOrIDs.findIndex((x: OneToManyRelatedObject<TConsumer, TKey> | string) => {
          if (typeof x === "string") {
            return x == item.id;
          } else {
            let keys = x[idPropertyKey];
            return keys ? keys.indexOf(item.id!) !== -1 : false;
          }
        }) !== -1
      );
    } else {
      return itemOrIDs == item.id;
    }
  };
}

const underscoreToUpperCaseRegex = /^.|_./g;

export interface AutomaticCrudStoreOptions<
  T extends AutomaticCrudObject,
  TConsumer = {},
  TExtended = {}
> {
  crudService: AutomaticCrudService<T> | AutomaticArchivedCrudService<T>;
  singularStoreName: string;
  pluralStoreName: string;
  localizationPrefix: string;
  storeExtensions?: OverriddenModule<TExtended, AutomaticCrudState<T>, RootState>;
  consumerRelatedIDProperty?: {
    name: keyof TConsumer;
    type: "array" | "string";
  };
  nameCallback?: (item: T) => string;
}

export function createAutomaticCrudStoreModule<
  T extends AutomaticCrudObject,
  TConsumer,
  TExtended = {}
>(
  options: AutomaticCrudStoreOptions<T, TConsumer, TExtended>
): Module<TExtended & AutomaticCrudState<T>, RootState> {
  const { crudService, singularStoreName, pluralStoreName, localizationPrefix, storeExtensions } = {
    ...options
  };

  const SET_LIST_MUTATION = `SET_${pluralStoreName}`;
  const SET_ITEM_MUTATION = `SET_${singularStoreName}`;
  const DELETE_ITEM_MUTATION = `DELETE_${singularStoreName}`;
  const LOAD_ITEM_ACTION = `LOAD_${singularStoreName}`;
  const LOAD_LIST_ACTION = `LOAD_${pluralStoreName}`;
  const ADD_ITEM_ACTION = `ADD_${singularStoreName}`;
  const UPDATE_ITEM_ACTION = `UPDATE_${singularStoreName}`;
  const DELETE_ITEM_ACTION = `DELETE_${singularStoreName}`;
  const SNACKBAR_ADDED_MESSAGE_ID = `${localizationPrefix}.snack-bar-add-message`;
  const SNACKBAR_UPDATED_MESSAGE_ID = `${localizationPrefix}.snack-bar-updated-message`;
  const SNACKBAR_DELETED_MESSAGE_ID = `${localizationPrefix}.snack-bar-delete-message`;
  const PASCAL_CASE_PLURAL_STORE_NAME = pluralStoreName
    .toLowerCase()
    .replace(underscoreToUpperCaseRegex, function(underscoreAndChar) {
      return underscoreAndChar.charAt(underscoreAndChar.length - 1).toUpperCase();
    });
  const SORTED_LIST_GETTER = `sorted${PASCAL_CASE_PLURAL_STORE_NAME}`;
  const SORTED_ENABLED_LIST_GETTER = `sortedEnabled${PASCAL_CASE_PLURAL_STORE_NAME}`;
  const SORTED_IN_USE_LIST_GETTER = `getSortedInUse${PASCAL_CASE_PLURAL_STORE_NAME}`;
  const SORTED_ENABLED_IN_USE_LIST_GETTER = `getSortedEnabledInUse${PASCAL_CASE_PLURAL_STORE_NAME}`;
  const NAMECALLBACK = options.nameCallback || (item => item.name);
  let advancedGetters:
    | {
        [getterName: string]: (
          state: TExtended & AutomaticCrudState<T>
        ) =>
          | ((
              consumingItems:
                | OneToOneRelatedObject<TConsumer, keyof TConsumer>[]
                | string
                | string[]
            ) => T[])
          | ((
              consumingItems:
                | OneToManyRelatedObject<TConsumer, keyof TConsumer>[]
                | string
                | string[]
            ) => T[]);
      }
    | undefined;
  if (options.consumerRelatedIDProperty) {
    let keyName = options.consumerRelatedIDProperty.name;
    switch (options.consumerRelatedIDProperty.type) {
      case "array":
        advancedGetters = {
          [SORTED_IN_USE_LIST_GETTER](state) {
            return function(
              itemOrIDs: OneToManyRelatedObject<TConsumer, keyof TConsumer>[] | string | string[]
            ) {
              let inUseCheck = getOneToManyItemsInUseCheck<TConsumer, keyof TConsumer>(
                itemOrIDs,
                keyName
              );
              return state.fullList.filter(inUseCheck).sort(compareCrudObjectByName);
            };
          },
          [SORTED_ENABLED_IN_USE_LIST_GETTER](state) {
            return function(
              itemOrIDs: OneToManyRelatedObject<TConsumer, keyof TConsumer>[] | string | string[]
            ) {
              let inUseCheck = getOneToManyItemsInUseCheck<TConsumer, keyof TConsumer>(
                itemOrIDs,
                keyName
              );
              return state.fullList
                .filter(x => (!!x.enabled && x.enabled) || !x.archivedDate || inUseCheck(x))
                .sort(compareCrudObjectByName);
            };
          }
        };
        break;
      case "string":
        advancedGetters = {
          [SORTED_IN_USE_LIST_GETTER](state) {
            return function(
              itemOrIDs: OneToOneRelatedObject<TConsumer, keyof TConsumer>[] | string | string[]
            ) {
              let inUseCheck = getOneToOneItemsInUseCheck<TConsumer, keyof TConsumer>(
                itemOrIDs,
                keyName
              );
              return state.fullList.filter(inUseCheck).sort(compareCrudObjectByName);
            };
          },
          [SORTED_ENABLED_IN_USE_LIST_GETTER](state) {
            return function(
              itemOrIDs: OneToOneRelatedObject<TConsumer, keyof TConsumer>[] | string | string[]
            ) {
              let inUseCheck = getOneToOneItemsInUseCheck<TConsumer, keyof TConsumer>(
                itemOrIDs,
                keyName
              );
              return state.fullList
                .filter(x => (!!x.enabled && x.enabled) || !x.archivedDate || inUseCheck(x))
                .sort(compareCrudObjectByName);
            };
          }
        };
        break;
    }
  }
  return {
    ...(storeExtensions as any),
    state: {
      fullList: [],
      ...storeExtensions?.state
    },
    getters: {
      [SORTED_LIST_GETTER](state) {
        return state.fullList.sort(compareCrudObjectByName);
      },
      [SORTED_ENABLED_LIST_GETTER](state) {
        return state.fullList
          .filter(x => (!!x.enabled && x.enabled) || !x.archivedDate)
          .sort(compareCrudObjectByName);
      },
      ...advancedGetters,
      ...storeExtensions?.getters
    },
    mutations: {
      [SET_LIST_MUTATION](state, payload) {
        state.fullList = payload;
      },
      [SET_ITEM_MUTATION](state, payload) {
        let suppliers = [...state.fullList];
        let supplierIndex = state.fullList.findIndex(x => x.id == payload.id);
        // If payload is not found, it is a new item.
        if (supplierIndex === -1) {
          suppliers.push(payload);
        } else {
          suppliers[supplierIndex] = { ...suppliers[supplierIndex], ...payload };
        }
        state.fullList = suppliers;
      },
      [DELETE_ITEM_MUTATION](state, payload) {
        let itemIndex = state.fullList.findIndex(x => x.id == payload.id);
        // If there was no item found then the slice function will essentially just grab the entire array of objects again.
        // Thus nothing would be deleted.
        state.fullList = state.fullList
          .slice(0, itemIndex)
          .concat(state.fullList.slice(itemIndex + 1));
      },
      ...storeExtensions?.mutations
    },
    actions: {
      async [LOAD_ITEM_ACTION](context, payload: string): Promise<void> {
        let item = await crudService.getByID(payload);
        context.commit(SET_ITEM_MUTATION, item);
      },
      async [LOAD_LIST_ACTION](context, payload = undefined): Promise<void> {
        let list = [];

        if (payload === undefined) {
          list = await (crudService as AutomaticCrudService<T>).getAll();
        } else {
          list = await (crudService as AutomaticArchivedCrudService<T>).getAll(
            payload.forcedArchivedState,
            payload.archivedFromDate,
            payload.archivedToDate
          );
        }
        context.commit(SET_LIST_MUTATION, list);
      },
      async [ADD_ITEM_ACTION](context, payload): Promise<string> {
        let itemID = await crudService.addItem(payload);
        context.commit(SET_ITEM_MUTATION, { ...payload, id: itemID });
        context.dispatch("SHOW_SNACKBAR", {
          text: i18n.t(SNACKBAR_ADDED_MESSAGE_ID, [NAMECALLBACK(payload)]),
          type: "success"
        });
        return itemID;
      },
      async [UPDATE_ITEM_ACTION](context, payload): Promise<void> {
        await crudService.updateItem(payload.id, { ...payload, id: undefined });
        context.commit(SET_ITEM_MUTATION, payload);
        context.dispatch("SHOW_SNACKBAR", {
          text: i18n.t(SNACKBAR_UPDATED_MESSAGE_ID, [NAMECALLBACK(payload)]),
          type: "success"
        });
      },
      async [DELETE_ITEM_ACTION](context, payload): Promise<boolean> {
        let deletedItem = context.state.fullList.find(x => x.id == payload.id)!;
        if (await crudService.deleteItem(payload.id)) {
          context.commit(DELETE_ITEM_MUTATION, payload);
          context.dispatch("SHOW_SNACKBAR", {
            text: i18n.t(SNACKBAR_DELETED_MESSAGE_ID, [NAMECALLBACK(payload)]),
            type: "info",
            undoCallback: async function() {
              context.dispatch(ADD_ITEM_ACTION, deletedItem);
            }
          });
          return true;
        } else {
          let disabled = false;
          if (!!deletedItem.enabled) {
            // If the deleted item has an enabled property, the record is active if it's enabled
            disabled = !deletedItem.enabled;
          }
          if (!!deletedItem.archivedDate) {
            // If the deleted item has an archivedDate property, the record is active if it's empty
            disabled = !!deletedItem.archivedDate;
          }
          if (await showDisableInsteadConfirmation(!disabled)) {
            if (!!deletedItem.enabled) {
              console.log(`setting enabled of ${deletedItem} to false`);
              await context.dispatch(UPDATE_ITEM_ACTION, {
                id: deletedItem.id,
                enabled: false,
                name: deletedItem.name
              });
            } else {
              let archivedDate = new Date(new Date().toUTCString());
              console.log(
                `setting archived date of ${JSON.stringify(deletedItem)} to ${archivedDate}`
              );
              await context.dispatch(UPDATE_ITEM_ACTION, {
                id: deletedItem.id,
                archivedDate: archivedDate,
                name: deletedItem.name
              });
            }
          }
          return false;
        }
      },
      ...storeExtensions?.actions
    }
  };
}
