var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-right": 40,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    staticClass: "fd-time-picker-text-field",
                    attrs: {
                      "data-cy": !!_vm.cy ? _vm.cy : _vm.dataCy,
                      label: _vm.$props.label,
                      value: _vm.formattedTime,
                      rules: _vm.$props.rules,
                      disabled: _vm.$props.disabled,
                      "prepend-inner-icon": "alarm",
                      readonly: "",
                      outlined: _vm.outlined,
                      clearable: _vm.clearable
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menuOpen,
        callback: function($$v) {
          _vm.menuOpen = $$v
        },
        expression: "menuOpen"
      }
    },
    [
      _vm._v(" "),
      _c("v-time-picker", {
        attrs: { disabled: _vm.$props.disabled, locale: _vm.i18nLocale },
        model: {
          value: _vm.valueString,
          callback: function($$v) {
            _vm.valueString = $$v
          },
          expression: "valueString"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }