import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  SessionTypeWithAppointmentType,
  sessionTypeService,
  userService,
  PersonWithDetails
} from "../../services";

type PersonWithFullName = PersonWithDetails & { fullName: string };
export type SessionNewDialogResults = {
  sessionTypeID: string;
  personID: string;
  startDateTime: Date;
};

const SessionNewDialog = FDVue.extend({
  name: "fd-select-acuity-client-dialog",
  mixins: [dialogSupport, rules],

  components: {
    "fd-date-picker": () => import("@fd/lib/vue/components/FP.DatePicker.vue"),
    "fd-time-picker": () => import("@fd/lib/vue/components/TimePicker.vue")
  },

  props: {},

  data() {
    return {
      sessionStartDate: new Date(),
      selectedTypeID: "",
      // Used to determine if the type is preset.  If the schedule type exists, disable the type selection control.
      scheduledTypeID: "",
      selectedPersonID: undefined as string | undefined,
      persons: [] as PersonWithFullName[],
      sessionTypes: [] as SessionTypeWithAppointmentType[]
    };
  },

  computed: {
    dialogRules(): any {
      return {
        sessionType: [this.rules.required],
        client: [this.rules.required]
      };
    }
  },

  methods: {
    async open(
      scheduledSessionTypeID: string | undefined = undefined,
      scheduledSessionStartTime: Date | undefined = undefined
    ): Promise<SessionNewDialogResults> {
      if (!!scheduledSessionTypeID) {
        this.scheduledTypeID = scheduledSessionTypeID;
        this.selectedTypeID = scheduledSessionTypeID;
      }
      if (!!scheduledSessionStartTime) this.sessionStartDate = scheduledSessionStartTime;

      this.optOutOfErrorHandling();
      return await this.showDialog();
    },
    closeWithSelectedData() {
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      this.closeDialog({
        sessionTypeID: this.selectedTypeID,
        personID: this.selectedPersonID,
        startDateTime: this.sessionStartDate
      });
    }
  },

  created: async function() {
    this.$props.processing = true;
    try {
      this.persons = (await userService.getAll(false, null, null))
        .map(x => {
          return {
            ...x,
            fullName: `${x.firstName} ${x.lastName}`
          };
        })
        .sort((a, b) => {
          var aFirstName = a.firstName?.toLowerCase() ?? "";
          var bFirstName = b.firstName?.toLowerCase() ?? "";
          if (aFirstName != bFirstName) {
            if (aFirstName < bFirstName) return -1;
            else return 1;
          }

          var aLastName = a.lastName?.toLowerCase() ?? "";
          var bLastName = b.lastName?.toLowerCase() ?? "";
          if (aLastName != bLastName) {
            if (aLastName < bLastName) return -1;
            else return 1;
          }

          return 0;
        });
      this.sessionTypes = await sessionTypeService.getAll();
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.$props.processing = false;
    }
  }
});

export default SessionNewDialog;

export async function showSessionNewDialog(): Promise<SessionNewDialogResults> {
  let dialog = createDialog(SessionNewDialog);
  let result = await dialog.open();
  console.log("showSessionNewDialog.result: " + JSON.stringify(result));
  return result;
}

export async function showSessionScheduledDialog(
  scheduledSessionTypeID: string,
  scheduledSessionStartTime: Date
): Promise<SessionNewDialogResults> {
  let dialog = createDialog(SessionNewDialog);
  let result = await dialog.open(scheduledSessionTypeID, scheduledSessionStartTime);
  console.log("showSessionNewDialog.result: " + JSON.stringify(result));
  return result;
}

