var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("v-img", {
            staticClass: "mt-3",
            attrs: {
              src: "/assets/img/sticks1.jpg",
              "max-height": "200",
              height: "200",
              gradient: "to top left, rgba(0,0,0,.05), rgba(0,0,0,.6)"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                position: "absolute",
                top: "0px",
                left: "20px",
                color: "white"
              }
            },
            [
              _c("div", { staticStyle: { "font-size": "4em" } }, [
                _vm._v(_vm._s(_vm.$t("welcome-title", [_vm.siteName])))
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view mt-5 sessions" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-icon",
                {
                  staticStyle: { "font-size": "44px", "padding-right": "10px" }
                },
                [_vm._v("mdi-calendar-today")]
              ),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("sessions.todays-sessions")) +
                  "\n      "
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.emailReport }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(
                                  "\n              mdi-email\n            "
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Email me my schedule.")])]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: _vm.$t("common.search"),
                  "single-line": "",
                  "hide-details": "",
                  clearable: ""
                },
                model: {
                  value: _vm.sessionsTablesearch,
                  callback: function($$v) {
                    _vm.sessionsTablesearch = $$v
                  },
                  expression: "sessionsTablesearch"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-subtitle", [
            _vm._v(_vm._s(_vm.$t("sessions.landing-subtitle")) + " ")
          ]),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:name",
                value: _vm.$t("common.name"),
                expression: "$t('common.name')",
                arg: "name"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:formattedDate",
                value: _vm.$t("common.date"),
                expression: "$t('common.date')",
                arg: "formattedDate"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:formattedTime",
                value: _vm.$t("common.time"),
                expression: "$t('common.time')",
                arg: "formattedTime"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:type",
                value: _vm.$t("common.type"),
                expression: "$t('common.type')",
                arg: "type"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:session",
                value: _vm.$t("sessions.started-time"),
                expression: "$t('sessions.started-time')",
                arg: "session"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:actions.no-sort",
                arg: "actions",
                modifiers: { "no-sort": true }
              }
            ],
            attrs: {
              items: _vm.sessions,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "sort-by": ["date", "time"],
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "item.session",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.session && !item.session.completedTime
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("sessions.in-progress-status-message")
                              ) +
                              "\n        "
                          )
                        ])
                      : item.session && !!item.session.completedTime
                      ? _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("sessions.complete-status-message")
                              ) +
                              "\n        "
                          )
                        ])
                      : _c("div", { staticClass: "placeholder" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("sessions.none")) +
                              "\n        "
                          )
                        ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    item.canHaveSession
                      ? _c("div", [
                          item.session && !item.session.completedTime
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.workSession(
                                                            item.session.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-clipboard-play"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("sessions.work-session")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : item.session && !!item.session.completedTime
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.viewSession(
                                                            item.session.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-table-eye")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("sessions.view-session")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.startSession(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("mdi-new-box")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("sessions.start-session")
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }