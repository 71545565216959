import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import {
  clientService,
  Client,
  SessionTypeWithAppointmentType,
  sessionTypeService
} from "../../services";

export type Recipient = {
  name: string;
  emailAddress: string;
  selected: boolean;
};

const SessionEmailSelectionDialog = FDVue.extend({
  name: "fd-session-email-selection-dialog",
  mixins: [dialogSupport, rules],

  props: {},

  data() {
    return {
      copyCurrentUser: false,
      recipients: [] as Recipient[]
    };
  },

  computed: {
    dialogRules(): any {
      return {};
    }
  },

  methods: {
    async open(
      recipients: Recipient[]
    ): Promise<{ recipients: Recipient[]; copyCurrentUser: boolean }> {
      recipients.forEach(x => {
        x.selected = !!x.emailAddress;
      });
      this.recipients = recipients;
      this.optOutOfErrorHandling();
      return await this.showDialog();
    },
    closeWithSelectedData() {
      this.inlineMessage.message = null;
      if (!(this.$refs.form as HTMLFormElement).validate()) return;

      let selectedRecipients = this.recipients.filter(x => x.selected);

      if (selectedRecipients.length == 0 && !this.copyCurrentUser) {
        this.inlineMessage.message = this.$t("sessions.email.one-recipient-required");
        this.inlineMessage.type = "error";
        return;
      }

      this.closeDialog({
        recipients: selectedRecipients,
        copyCurrentUser: this.copyCurrentUser
      });
    }
  },

  created: async function() {
    this.$props.processing = true;
    try {
    } catch (error) {
      this.handleError(error);
    } finally {
      this.$props.processing = false;
    }
  }
});

export default SessionEmailSelectionDialog;

export async function showSessionEmailSelectionDialog(
  recipients: Recipient[]
): Promise<{ recipients: Recipient[]; copyCurrentUser: boolean }> {
  let dialog = createDialog(SessionEmailSelectionDialog);
  let result = await dialog.open(recipients);
  console.log("SessionEmailSelectionDialog.result: " + JSON.stringify(result));
  return result;
}
