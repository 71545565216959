import FDVue from "@fd/lib/vue";
import {
  SessionWithAppointment,
  appointmentService,
  reportService,
  sessionService,
  sessionTypeService,
  SessionTypeWithAppointmentType,
  Appointment
} from "../../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";

export type SessionDetails = {
  id: String | undefined;
  name: String | undefined;
  dateTime: Date | undefined;
  formattedDateTime: String | undefined;
  type: String | undefined;
  sessionStatus: String | undefined;
  canHaveSession: Boolean | undefined;
  appointment: Appointment | undefined;
  session: SessionWithAppointment | undefined;
};

const SessionsList = FDVue.extend({
  name: "fd-sessions-list",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  props: {
    items: {
      default: () => [],
      type: Array
    },
    tablesearch: {
      default: "",
      type: String
    },
    processing: {
      default: false,
      type: Boolean
    },
    showExpand: {
      default: false,
      type: Boolean
    }
  },

  methods: {
    customSort(items: any[], index: any[], isDescending: any[]) {
      items.sort((a: any, b: any) => {
        let desc = isDescending[0] == true;
        let propName = index[0];
        if (propName === "formattedDateTime") {
          propName = "dateTime";
        }

        let val1 = a[propName];
        let val2 = b[propName];
        if (val1 < val2) {
          return desc ? 1 : -1;
        } else if (val1 > val2) {
          return desc == true ? -1 : 1;
        } else {
          return 0;
        }
      });

      return items;
    },
    workSession(session: SessionWithAppointment) {
      this.$emit("workSession", session);
    },
    startScheduledSession(appointment: Appointment) {
      this.$emit("startScheduledSession", appointment);
    },
    viewSession(session: SessionWithAppointment) {
      this.$emit("viewSession", session);
    }
  }
});

export default SessionsList;
