import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import { personalPhoneNumberService, PersonalPhoneNumber } from "@fd/current/client/services";
import { showAccessCodeEntryDialog } from "./components/AccessCodeEntryDialog.vue";
import userAccess from "@fd/current/client/dataMixins/userAccess";

type PersonalPhoneNumberWithVerified = PersonalPhoneNumber & { isVerified: boolean };

export default FDVue.extend({
  name: "fd-mobile-number-existing",

  mixins: [errorHandling, rules, userAccess],

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue")
  },

  props: ["userID"],

  data() {
    return {
      // The following will control whether the controls on screen are disabled while we are conducting any screen
      // wide actions.
      processing: false,

      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      pageHasLoaded: false,

      slidein: false,

      phoneNumber: {} as PersonalPhoneNumberWithVerified
    };
  },

  computed: {
    phoneNumberRules() {
      return {
        phoneNumber: [this.rules.required, this.rules.validPhoneNumber]
      };
    }
  },

  methods: {
    onSubmit(e: any) {
      e.preventDefault();
      this.save(false);
    },

    async verifyPhoneNumber() {
      if (!this.phoneNumber?.phoneNumber) return;

      this.optOutOfErrorHandling();
      if (await showAccessCodeEntryDialog(null, this.phoneNumber.phoneNumber!)) {
        await this.loadPhoneNumber(this.$route.params.phoneNumberID);
      }
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      this.inlineMessage.message = null;

      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }

      this.processing = true;
      this.saving = true;
      try {
        await personalPhoneNumberService.updateItem(this.$route.params.phoneNumberID, {
          ...this.phoneNumber,
          phoneNumber: this.phoneNumber.isVerified ? undefined : this.phoneNumber.phoneNumber
        } as PersonalPhoneNumber);
        // this.$router.push(`/areas/${this.subArea.parentLocationID}`);
        if (closeOnComplete) {
          this.$router.back();
        }
      } catch (error) {
        this.handleError(error, "users.save-network-error");
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Method used in conjunction with the Delete button.
    async deleteItem() {
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await personalPhoneNumberService.deleteItem(this.$route.params.phoneNumberID);
        this.$router.back();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.back();
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB"
    }),
    async loadPhoneNumber(id: any) {
      this.processing = true;
      try {
        let phoneNumber = await personalPhoneNumberService.getByID(id);
        this.phoneNumber = {
          ...phoneNumber,
          isVerified: !!phoneNumber.verifiedDate
        } as PersonalPhoneNumberWithVerified;
        this.pageHasLoaded = !!this.phoneNumber;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    }
  },

  created: async function() {
    this.processing = true;

    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    try {
      await this.loadPhoneNumber(this.$route.params.phoneNumberID);
      this.notifyNewBreadcrumb({
        text: this.phoneNumber.phoneNumber,
        to: `/phonenumber/${this.$route.params.phoneNumberID}`
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

