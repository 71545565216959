



















































import { mapMutations, mapState, mapActions } from "vuex";

export default {
  name: "MobileMoreMenu",

  data: () => ({
    sidebarmenuitems: [
      { icon: "fas fa-home", text: "menu.home", to: "/landingpage" },
      { icon: "fa-calendar-check", text: "menu.sessions", to: "/sessions" },
      {
        icon: "fa-cog",
        "sub-menu-icon": "mdi-chevron-up",
        "sub-menu-icon-alt": "mdi-chevron-down",
        text: "menu.setup.subgroup-title",
        accessCheck: "canConfigureSettings",
        model: false,
        children: [
          { text: "menu.setup.tags", icon: "fa-tags", to: "/tags" },
          { text: "menu.setup.people", icon: "fas fa-users", to: "/people" },
          {
            text: "menu.setup.skills-challenge-stations",
            icon: "fas fa-ruler",
            to: "/skillschallengestations"
          },
          {
            text: "menu.setup.session-types",
            icon: "fas fa-pencil-ruler",
            to: "/sessiontypes"
          }
        ]
      },
      { icon: "fas fa-eye-slash", text: "menu.privacypolicy" },
      { icon: "fas fa-file-alt", text: "menu.termsandconditions" }
    ]
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    ...mapState(["avatarInitials"]),
    ...mapState(["curUserID"]),
    ...mapState(["curUserAccess"]),

    accessibleSidebarMenuItems() {
      return this.sidebarmenuitems.filter(
        x => !x.accessCheck?.length || this.curUserAccess[x.accessCheck] == true
      );
    },

    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: "byron3"
      };
    }
  },

  methods: {
    ...mapMutations({
      setDrawerMini: "SET_DRAWER_MINI"
    }),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      };
    }
  }
};
