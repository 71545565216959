import FDVue from "@fd/lib/vue";
import userAccess from "../dataMixins/userAccess";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { mapMutations } from "vuex";
import Slim from "@fd/lib/vue/components/SlimCropper.vue";
import rules from "@fd/lib/vue/rules";
import { Environment, environmentService } from "../services";

export default FDVue.extend({
  name: "fd-settings",
  mixins: [userAccess, errorHandling, rules],
  components: { "slim-cropper": Slim },
  data: function() {
    return {
      environment: {} as Environment,
      saving: false,
      logo: null as File | null,
      originalLogo: null as File | null,
      logoUrl: "" as string
    };
  },
  computed: {},
  watch: {
    logo() {
      if (!!this.logo) this.logoUrl = URL.createObjectURL(this.logo as Blob);
      else this.logoUrl = "/assets/img/logo.svg";
    }
  },
  methods: {
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),
    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },
    async save() {
      this.inlineMessage.message = null;
      if (!(this.$refs.form as HTMLFormElement).validate()) {
        return;
      }
      this.saving = true;
      try {
        if (!!this.environment.name?.length) {
          await environmentService.updateItem(this.environment.id!, {
            name: this.environment.name
          } as Environment);
        }

        if (this.logo !== this.originalLogo) {
          if (this.logo) {
            await environmentService.uploadOrgLogo(this.logo);
            this.$store.commit("SET_FOOTER_IMAGE_SOURCE", URL.createObjectURL(this.logo));
          } else {
            await environmentService.deleteOrgLogo();
            this.$store.commit("SET_FOOTER_IMAGE_SOURCE", "/assets/img/logo.svg");
          }
        }
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.saving = false;
      }
    }
  },

  mounted: function() {},

  created: async function() {
    this.notifyNewBreadcrumb({
      text: this.$t("settings.title"),
      to: "/settings",
      resetHistory: true
    });

    this.processing = true;
    try {
      let environment = await environmentService.getCurrent();
      if (!!environment) {
        this.environment = {
          ...environment,
          created: undefined,
          createdBy: undefined,
          updated: undefined,
          updatedBy: undefined
        };
      }
      let logo = await environmentService.downloadOrgLogo();
      if (!logo) {
        // There is no logo saved, default the file to the deployed logo.svg file
        const res: Response = await fetch("/assets/img/logo.svg");
        const blob: Blob = await res.blob();
        logo = blob;
      }
      if (logo) {
        this.logo = this.originalLogo = new File([logo], "logo", {
          type: logo.type
        });
      }
    } catch (error) {
      this.handleError(error as Error);
    } finally {
      this.processing = false;
    }
  }
});

