import { mapMutations, mapState, mapActions } from "vuex";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { SessionWithAppointment, SessionTypeWithAppointmentType } from "../services/";
import {
  appointmentService,
  reportService,
  sessionService,
  sessionTypeService,
  environmentService
} from "../services/";
import * as DateUtil from "@fd/lib/client-util/datetime";

export default FDVue.extend({
  mixins: [errorHandling],

  name: "fd-sk-LandingPage",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: () => ({
    siteName: "Skill Zone",

    sessions: [] as any[],
    sessionTypes: [] as SessionTypeWithAppointmentType[]
  }),

  computed: {
    unwatchedMethodNames(): string[] {
      return ["startSession", "workSession", "viewSession", "emailReport"];
    },
    sessionsTablesearch: {
      get() {
        //return this.$store.state.searchStringForFiltering;
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.searchStringForFiltering;
      },
      set(val) {
        this.$store.commit("SET_SEARCH_STRING_FOR_FILTERING", val);
      }
    }
  },

  methods: {
    async startSession(item: any) {
      var session = {
        acuityAppointmentID: item.id,
        startedTime: new Date(),
        enabled: true
      } as SessionWithAppointment;
      this.processing = true;
      try {
        var newID = await sessionService.addItem(session);
        session.id = newID;
        item.session = {
          ...session,
          formattedStartedTime: DateUtil.localizedDateTimeString(session.startedTime)
        };
        this.workSession(session.id);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    },
    workSession(sessionID: string) {
      this.$router.push(`/shottracker/${sessionID}`);
    },
    viewSession(sessionID: string) {
      this.$router.push(`/shottracker/${sessionID}`);
    },
    emailReport() {
      reportService.emailAppointmentList();
    },
    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    })
  },

  created: async function() {
    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "dashboard",
      parentalContext: null,
      searchStringForFiltering: ""
    });

    this.notifyNewBreadcrumb({
      text: "Dashboard",
      to: "/landingpage",
      resetHistory: true
    });

    this.processing = true;
    try {
      let environment = await environmentService.getCurrent();
      if (!!environment) {
        this.siteName = environment.name ?? "";
      }

      var sessionTypes = await sessionTypeService.getAll();
      this.sessionTypes = sessionTypes;

      var sessions = await appointmentService.getToday();
      // var sessions = await appointmentService.getToday();
      this.sessions = sessions.map(function(x) {
        return {
          ...x,
          formattedDate: DateUtil.stripTimeFromLocalizedDateTime(x.date),
          formattedTime: DateUtil.stripDateFromLocalizedDateTime(x.time),
          canHaveSession:
            sessionTypes.filter(function(type) {
              return !!x.appointmentTypeID && x.appointmentTypeID == type.acuityAppointmentTypeID;
            }).length > 0,
          session: x.session
            ? {
                ...x.session,
                formattedStartedTime: DateUtil.localizedDateTimeString(x.session.startedTime)
              }
            : undefined
        };
      });
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

