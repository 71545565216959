var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "tight" },
          on: { "click:outside": _vm.close },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _vm.previousPreviousItem
                        ? _c(
                            "v-row",
                            {
                              staticClass:
                                "previousPreview i-2 itemPreview blurText-2",
                              on: {
                                click: function($event) {
                                  return _vm.rowClick(_vm.previousPreviousItem)
                                }
                              }
                            },
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.previousPreviousItem.sessionShotNumber
                                    ) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.previousPreviousItem.speed) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.previousPreviousItem.accuracyRating
                                    ) +
                                    "\n            "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.previousItem
                        ? _c(
                            "v-row",
                            {
                              staticClass:
                                "previousPreview i-1 itemPreview blurText-1",
                              on: {
                                click: function($event) {
                                  return _vm.rowClick(_vm.previousItem)
                                }
                              }
                            },
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.previousItem.sessionShotNumber) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.previousItem.speed) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.previousItem.accuracyRating) +
                                    "\n            "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.editingItem
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t("shot-tracker.shot-number"),
                                      readonly: "",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.editingItem.sessionShotNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editingItem,
                                          "sessionShotNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "editingItem.sessionShotNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    ref: "onlyField",
                                    attrs: {
                                      label: _vm.$t("shot-tracker.speed"),
                                      disabled: _vm.readonly,
                                      autofocus: "",
                                      rules: _vm.shotRules.speed,
                                      maxlength: "2",
                                      onfocus: "this.select()"
                                    },
                                    on: { keydown: _vm.onlyFieldKeyDown },
                                    model: {
                                      value: _vm.editingItem.speed,
                                      callback: function($$v) {
                                        _vm.$set(_vm.editingItem, "speed", $$v)
                                      },
                                      expression: "editingItem.speed"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-rating", {
                                    ref: "lastField",
                                    attrs: {
                                      label: _vm.$t(
                                        "shot-tracker.accuracy-rating"
                                      ),
                                      "background-color": "orange lighten-3",
                                      color: "orange",
                                      length: "3",
                                      large: "",
                                      disabled: _vm.readonly
                                    },
                                    on: { input: _vm.lastFieldKeyDown },
                                    model: {
                                      value: _vm.editingItem.accuracyRating,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.editingItem,
                                          "accuracyRating",
                                          $$v
                                        )
                                      },
                                      expression: "editingItem.accuracyRating"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.nextItem
                        ? _c(
                            "v-row",
                            {
                              staticClass:
                                "nextPreview i-1 itemPreview blurText-1",
                              on: {
                                click: function($event) {
                                  return _vm.rowClick(_vm.nextItem)
                                }
                              }
                            },
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nextItem.sessionShotNumber) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nextItem.speed) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nextItem.accuracyRating) +
                                    "\n            "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.nextNextItem
                        ? _c(
                            "v-row",
                            {
                              staticClass:
                                "nextPreview i-2 itemPreview blurText-2",
                              on: {
                                click: function($event) {
                                  return _vm.rowClick(_vm.nextNextItem)
                                }
                              }
                            },
                            [
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nextNextItem.sessionShotNumber) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nextNextItem.speed) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.nextNextItem.accuracyRating) +
                                    "\n            "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slidein,
                  expression: "slidein"
                }
              ]
            },
            [
              _c(
                "v-toolbar",
                {
                  class: [
                    _vm.processing
                      ? "view-card-top-color-processing"
                      : "view-card-top-color"
                  ]
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c("v-breadcrumbs", {
                                staticClass: "d-none d-sm-flex",
                                class: [
                                  _vm.processing
                                    ? "breadcrumb-processing-opacity"
                                    : "",
                                  "pl-3"
                                ],
                                attrs: {
                                  items: _vm.$store.state.currentBreadcrumbs,
                                  large: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "divider",
                                    fn: function() {
                                      return [
                                        _c("v-icon", [
                                          _vm._v("fa-chevron-right")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("fd-back-button", {
                                attrs: {
                                  route: function(item) {
                                    return _vm.$router.push(
                                      _vm.$store.getters.backBreadcrumb.to ||
                                        "/sessions"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.processing
                                ? _c("v-progress-circular", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      indeterminate: true,
                                      rotate: 0,
                                      size: 32,
                                      width: 4,
                                      color: "white"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message || _vm.readonly
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-0 mb-3",
                              attrs: { type: _vm.inlineMessage.type }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.readonly
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-0 mb-3",
                              attrs: { type: "info" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("sessions.complete-status-message")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center",
                      attrs: { cols: "12", xl: "6" }
                    },
                    [
                      _c("canvas", {
                        ref: "canvas",
                        staticStyle: {
                          "max-width": "612px",
                          "max-height": "377px",
                          border: "1px solid blue"
                        },
                        attrs: { id: "c" },
                        on: { click: _vm.canvasClicked }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xl: "6" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                        [
                          _c(
                            "v-tabs",
                            {
                              ref: "tabs",
                              attrs: { "icons-and-text": "" },
                              model: {
                                value: _vm.active_tab,
                                callback: function($$v) {
                                  _vm.active_tab = $$v
                                },
                                expression: "active_tab"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                {
                                  key: _vm.snapShotTab.key,
                                  ref: "tab",
                                  attrs: {
                                    href: "#tab-" + _vm.snapShotTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.snapShotTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("shot-tracker.tabs.snap-shot")
                                      ) +
                                      "\n                "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-circle")])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.wristShotTab.visible ||
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c(
                                    "v-tab",
                                    {
                                      key: _vm.wristShotTab.key,
                                      attrs: {
                                        href: "#tab-" + _vm.wristShotTab.key
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.tabSelected(
                                            _vm.wristShotTab
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "shot-tracker.tabs.wrist-shot"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("v-icon", [_vm._v("fas fa-square")])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.backhandShotTab.visible ||
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c(
                                    "v-tab",
                                    {
                                      key: _vm.backhandShotTab.key,
                                      attrs: {
                                        href: "#tab-" + _vm.backhandShotTab.key
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.tabSelected(
                                            _vm.backhandShotTab
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "shot-tracker.tabs.backhand-shot"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("v-icon", [_vm._v("fas fa-diamond")])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.slapShotTab.visible ||
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c(
                                    "v-tab",
                                    {
                                      key: _vm.slapShotTab.key,
                                      attrs: {
                                        href: "#tab-" + _vm.slapShotTab.key
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.tabSelected(
                                            _vm.slapShotTab
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "shot-tracker.tabs.slap-shot"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                      _c("v-icon", [_vm._v("fas fa-triangle")])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.summaryTab.visible ||
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c(
                                    "v-tab",
                                    {
                                      key: _vm.summaryTab.key,
                                      attrs: {
                                        href: "#tab-" + _vm.summaryTab.key
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.tabSelected(_vm.summaryTab)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("shot-tracker.tabs.summary")
                                          ) +
                                          "\n                "
                                      ),
                                      _c("v-icon", [_vm._v("fas fa-clipboard")])
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-menu",
                                {
                                  attrs: { bottom: "", left: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                                  staticStyle: {
                                                    position: "relative"
                                                  },
                                                  attrs: { text: "" }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("common.more")
                                                  ) +
                                                  "\n\n                    "
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("fas fa-ellipsis-h")
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    { staticClass: "grey lighten-3" },
                                    _vm._l(_vm.tabDefinitions, function(item) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: item.tabname,
                                          on: {
                                            click: function($event) {
                                              return _vm.showNewTabFromMoreMenu(
                                                item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(item.tabname) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.active_tab,
                                callback: function($$v) {
                                  _vm.active_tab = $$v
                                },
                                expression: "active_tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                {
                                  key: _vm.snapShotTab.key,
                                  attrs: { value: "tab-" + _vm.snapShotTab.key }
                                },
                                [
                                  _c("fd-shot-list", {
                                    attrs: {
                                      items: _vm.snapShots,
                                      processing: _vm.processing,
                                      selectedId: _vm.editingId
                                    },
                                    on: {
                                      rowClick: _vm.rowClick,
                                      mouseover: _vm.rowHover,
                                      "remove-latest-shot": _vm.removeLatestShot
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                {
                                  key: _vm.wristShotTab.key,
                                  attrs: {
                                    value: "tab-" + _vm.wristShotTab.key
                                  }
                                },
                                [
                                  _c("fd-shot-list", {
                                    attrs: {
                                      items: _vm.wristShots,
                                      processing: _vm.processing,
                                      selectedId: _vm.editingId
                                    },
                                    on: {
                                      rowClick: _vm.rowClick,
                                      mouseover: _vm.rowHover,
                                      "remove-latest-shot": _vm.removeLatestShot
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                {
                                  key: _vm.slapShotTab.key,
                                  attrs: { value: "tab-" + _vm.slapShotTab.key }
                                },
                                [
                                  _c("fd-shot-list", {
                                    attrs: {
                                      items: _vm.slapShots,
                                      processing: _vm.processing,
                                      selectedId: _vm.editingId
                                    },
                                    on: {
                                      rowClick: _vm.rowClick,
                                      mouseover: _vm.rowHover,
                                      "remove-latest-shot": _vm.removeLatestShot
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                {
                                  key: _vm.backhandShotTab.key,
                                  attrs: {
                                    value: "tab-" + _vm.backhandShotTab.key
                                  }
                                },
                                [
                                  _c("fd-shot-list", {
                                    attrs: {
                                      items: _vm.backhandShots,
                                      processing: _vm.processing,
                                      selectedId: _vm.editingId
                                    },
                                    on: {
                                      rowClick: _vm.rowClick,
                                      mouseover: _vm.rowHover,
                                      "remove-latest-shot": _vm.removeLatestShot
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                {
                                  key: _vm.summaryTab.key,
                                  attrs: { value: "tab-" + _vm.summaryTab.key }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:details",
                                                    arg: "details"
                                                  }
                                                ],
                                                attrs: {
                                                  items: _vm.summaryData,
                                                  "hide-default-footer": "",
                                                  "mobile-breakpoint": "0"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.details",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        item.shotCount == 0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#333333"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            No shots.\n                          "
                                                                )
                                                              ]
                                                            )
                                                          : _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "fd-expanding-table-single-details-section px-0 py-1"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "5",
                                                                          md:
                                                                            "3"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Shots"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.shotCount
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "5",
                                                                          md:
                                                                            "3"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Avg Speed"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.averageSpeed
                                                                          ) +
                                                                            " mph"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols:
                                                                            "5",
                                                                          md:
                                                                            "3"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              "Avg Accuracy"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.averageAccuracy
                                                                          ) +
                                                                            " stars"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                !!item.fastestShot &&
                                                                !!item.fastestSpeed
                                                                  ? _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "5",
                                                                              md:
                                                                                "3"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  "Burner"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          [
                                                                            !!item.fastestShotTypeName
                                                                              ? _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item.fastestShotTypeName
                                                                                      ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              "Shot #" +
                                                                                _vm._s(
                                                                                  item.fastestShot
                                                                                ) +
                                                                                " at\n                                " +
                                                                                _vm._s(
                                                                                  item.fastestSpeed
                                                                                ) +
                                                                                " mph"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "align-center justify-space-between",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c("fd-date-picker", {
                                                attrs: {
                                                  value:
                                                    _vm.session.startedTime,
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  label: _vm.$t(
                                                    "sessions.start-date"
                                                  ),
                                                  outlined: ""
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.session,
                                                      "startedTime",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "align-center justify-space-between",
                                              attrs: { cols: "12", sm: "6" }
                                            },
                                            [
                                              _c("fd-time-picker", {
                                                attrs: {
                                                  value:
                                                    _vm.session.startedTime,
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  label: _vm.$t(
                                                    "sessions.start-time"
                                                  ),
                                                  outlined: ""
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.session,
                                                      "startedTime",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "justify-space-between pt-0 pb-0",
                                              attrs: {
                                                align: "center",
                                                cols: "12",
                                                md: "12"
                                              }
                                            },
                                            [
                                              _c("fd-textarea", {
                                                attrs: {
                                                  disabled:
                                                    _vm.readonly ||
                                                    _vm.processing,
                                                  label: _vm.$t(
                                                    "shot-tracker.session-notes"
                                                  ),
                                                  rows: "1",
                                                  "auto-grow": ""
                                                },
                                                model: {
                                                  value: _vm.session.notes,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.session,
                                                      "notes",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "session.notes"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pt-0 pb-0 mt-0 mb-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-subheader",
                                                { staticClass: "pl-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "people.stick.label"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider", {
                                                staticClass: "mt-0 mb-5"
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { staticClass: "pt-0 pb-0" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pb-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "people.stick.brand"
                                                          ),
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.session.brand,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.session,
                                                              "brand",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "session.brand"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "pb-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          outlined: "",
                                                          label: _vm.$t(
                                                            "people.handedness.label"
                                                          ),
                                                          items:
                                                            _vm.handednessOptions,
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.session
                                                              .handednessID,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.session,
                                                              "handednessID",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "session.handednessID"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pt-1 pb-0 pt-lg-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "people.stick.flex"
                                                          ),
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.session.flex,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.session,
                                                              "flex",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "session.flex"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "pt-1 pb-0 pt-lg-0",
                                                      attrs: {
                                                        cols: "12",
                                                        sm: "12",
                                                        md: "6",
                                                        lg: "6"
                                                      }
                                                    },
                                                    [
                                                      _c("fd-text-field", {
                                                        attrs: {
                                                          label: _vm.$t(
                                                            "people.stick.curve"
                                                          ),
                                                          disabled:
                                                            _vm.processing
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.session.curve,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.session,
                                                              "curve",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "session.curve"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass:
                    "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outlined: "",
                        color: "primary",
                        loading: _vm.saving,
                        disabled: _vm.processing,
                        small: ""
                      },
                      on: { click: _vm.confirmEmail }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("sessions.email-action")) + "\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "d-none d-sm-flex",
                      attrs: {
                        outlined: "",
                        color: "primary",
                        loading: _vm.saving,
                        disabled: _vm.processing
                      },
                      on: { click: _vm.confirmEmail }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("sessions.email-action")) + "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                    },
                    [_vm._v("|")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        link: "",
                        disabled: _vm.processing,
                        small: _vm.$vuetify.breakpoint.xsOnly
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm._v(" "),
                  _c("fd-menu-save", {
                    attrs: {
                      loading: _vm.saving,
                      disabled: _vm.processing || _vm.readonly,
                      small: _vm.$vuetify.breakpoint.xsOnly
                    },
                    on: {
                      "click:save": function($event) {
                        return _vm.saveSession(false)
                      },
                      "click:save-and-close": function($event) {
                        return _vm.saveSession(true)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("img", {
        ref: "netImage",
        staticStyle: { display: "none" },
        attrs: { id: "image", src: "/assets/img/SkillZoneHockeyNet.png" },
        on: { load: _vm.imageLoaded }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }