import { loginService, LoginInformation } from "./services";
import { registerLoginHandler } from "@fd/lib/client-util/serviceRequest";
export { LoginInformation } from "./services";
import { showReauthentication } from "./views/components/ReauthenticateDialog.vue";
import store from "./store";
import { getuid } from "process";

var serverChecked = false;
var lastRetrievedLogin = null as LoginInformation | null;

export async function checkLogin(forceServerVerification?: boolean): Promise<boolean> {
  //Make sure the list of possible preferred languages have been loaded.
  if (store.state.languages.fullList.length == 0) {
    await loadPossibleLanguages();
  }

  return !!(await getLogin(forceServerVerification));
}

export async function getLogin(
  forceServerVerification?: boolean
): Promise<LoginInformation | null> {
  if (forceServerVerification || !serverChecked) {
    lastRetrievedLogin = await loginService.getCurrentLoginInformation();
    if (lastRetrievedLogin) {
      setUserInfoInStore(lastRetrievedLogin);
    }
  }

  // Recheck the user's access information on every page load
  var accessInformation = await loginService.getCurrentUserAccessInformation();
  store.commit("SET_CUR_USER_ACCESS", accessInformation);

  return lastRetrievedLogin;
}

export async function setLogin(loginInformation: LoginInformation | null): Promise<void> {
  serverChecked = true;
  lastRetrievedLogin = loginInformation;
  if (lastRetrievedLogin) {
    setUserInfoInStore(lastRetrievedLogin);
  }

  // Recheck the user's access information on every page load
  var accessInformation = await loginService.getCurrentUserAccessInformation();
  store.commit("SET_CUR_USER_ACCESS", accessInformation);
}

registerLoginHandler(async function() {
  return await showReauthentication(lastRetrievedLogin?.emailAddress!);
});

async function loadPossibleLanguages(): Promise<boolean> {
  await store.dispatch("LOAD_LANGUAGES");
  return true;
}

// The following function simply pulls the first character of the users first name and last name and puts those together in the
// vuex store so that they can be used in the avatar object in the upper right of the platform menu bar as the profile indicator.
async function setUserInfoInStore(loginInformation: LoginInformation) {
  //After retrieving the currently signed in user information set the initials of the user in the store for use in the avatar.
  let initials =
    loginInformation.firstName.slice(0, 1).toUpperCase() +
    loginInformation.lastName.slice(0, 1).toUpperCase();
  store.commit("SET_AVATAR_INITIALS", initials);
  store.commit("SET_CUR_USERID", loginInformation.userID);

  if (loginInformation.languageID != null) {
    let language = store.state.languages.fullList.find(x => x.id == loginInformation.languageID)!;
    store.commit("SET_PREFERRED_LANGUAGE", {
      shortCode: language.shortCode,
      shortCodeExt: language.shortCodeExt,
      number: language.number
    });
  } else {
    //Since there is no preferred language for this user. Set the language defaults to English.
    let language = store.state.languages.fullList.find(x => x.number == 0)!;
    store.commit("SET_PREFERRED_LANGUAGE", {
      shortCode: language.shortCode,
      shortCodeExt: language.shortCodeExt,
      number: language.number
    });
  }
}
