import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import { mapMutations } from "vuex";
import rules from "@fd/lib/vue/rules";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import {
  TrackedShot,
  trackedShotService,
  sessionService,
  reportService,
  SessionWithAppointment,
  ShotTypes,
  PersonHandedness
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import {
  Recipient,
  showSessionEmailSelectionDialog
} from "./components/SessionEmailSelectionDialog.vue";
import i18n from "../i18n";
import VueI18n, { TranslateResult } from "vue-i18n";

class RelativePoint {
  xRatio: number;
  yRatio: number;

  constructor(xRatio: number, yRatio: number) {
    this.xRatio = +xRatio.toFixed(3);
    this.yRatio = +yRatio.toFixed(3);
  }
  get location() {
    return `{${(this.xRatio * 100).toFixed(0)}%, ${(this.yRatio * 100).toFixed(0)}%}`;
  }
}
class AbsolutePoint {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
  get location() {
    return `{${this.x}, ${this.y}}`;
  }
}

// Specifies a size where its width & height values are a % relative to a width, instead of specific units
class RelativeSize {
  widthRatio: number;
  heightRatio: number;
  constructor(widthRatio: number, heightRatio: number) {
    this.widthRatio = +widthRatio.toFixed(3);
    this.heightRatio = +heightRatio.toFixed(3);
  }
  get description() {
    return `{${(this.widthRatio * 100).toFixed(0)}%, ${(this.heightRatio * 100).toFixed(0)}%}`;
  }
}

// Specifies a size where its width & height values are absolute values
class AbsoluteSize {
  width: number;
  height: number;
  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
  get description() {
    return `{${this.width}, ${this.height}}`;
  }
}

class RelativeRectangle {
  relativeOrigin: RelativePoint;
  relativeSize: RelativeSize;
  constructor(relativeOrigin: RelativePoint, relativeSize: RelativeSize) {
    this.relativeOrigin = relativeOrigin;
    this.relativeSize = relativeSize;
  }
  get endXRatio(): number {
    return this.relativeOrigin.xRatio + this.relativeSize.widthRatio;
  }
  get endYRatio(): number {
    return this.relativeOrigin.yRatio + this.relativeSize.heightRatio;
  }
  get description() {
    return `Relative Origin: ${this.relativeOrigin.location}, Relative Size: ${this.relativeSize.description}`;
  }
  /// Creates and returns a bigger rectangle with an increase of the provided scale on each side
  /// Example: a scale of .5 means add 50% to each side, a scale of 1 means double each side
  biggerRectFromSideScale(sideScale: number): RelativeRectangle {
    let widthIncrease = +(this.relativeSize.widthRatio * sideScale).toFixed(3);
    let newX = +(this.relativeOrigin.xRatio - widthIncrease / 2).toFixed(3);
    let newWidth = this.relativeSize.widthRatio + widthIncrease;

    let heightIncrease = +(this.relativeSize.heightRatio * sideScale).toFixed(3);
    let newY = +(this.relativeOrigin.yRatio - heightIncrease / 2).toFixed(3);
    let newHeight = this.relativeSize.heightRatio + heightIncrease;

    return new RelativeRectangle(
      new RelativePoint(newX, newY),
      new RelativeSize(newWidth, newHeight)
    );
  }
}
class AbsoluteRectangle {
  origin: AbsolutePoint;
  size: AbsoluteSize;
  constructor(origin: AbsolutePoint, size: AbsoluteSize) {
    this.origin = origin;
    this.size = size;
  }
  get endX(): number {
    return this.origin.x + this.size.width;
  }
  get endY(): number {
    return this.origin.y + this.size.height;
  }
  get description() {
    return `Origin: ${this.origin.location}, Size: ${this.size.description}`;
  }
  /// Creates and returns a bigger rectangle with an increase of the provided scale on each side
  /// Example: a scale of .5 means add 50% to each side, a scale of 1 means double each side
  biggerRectFromSideScale(sideScale: number): AbsoluteRectangle {
    let widthIncrease = +(this.size.width * sideScale).toFixed(3);
    let newX = +(this.origin.x - widthIncrease / 2).toFixed(3);
    let newWidth = this.size.width + widthIncrease;

    let heightIncrease = +(this.size.height * sideScale).toFixed(3);
    let newY = +(this.origin.y - heightIncrease / 2).toFixed(3);
    let newHeight = this.size.height + heightIncrease;

    return new AbsoluteRectangle(
      new AbsolutePoint(newX, newY),
      new AbsoluteSize(newWidth, newHeight)
    );
  }
}

function GetFriendlyShotTypeName(shotType: ShotTypes) {
  var shotTypeKey = "snap";
  if (shotType == ShotTypes.Backhand) {
    shotTypeKey = "backhand";
  } else if (shotType == ShotTypes.SlapShot) {
    shotTypeKey = "slap";
  } else if (shotType == ShotTypes.WristShot) {
    shotTypeKey = "wrist";
  }
  return i18n.t(`shot-tracker.shot-types.${shotTypeKey}`);
}

/// x & y are relative positions, consisting of a ratio of the full canvas
function NewShot(
  sessionID: string,
  sessionShotNumber: number,
  x: number,
  y: number,
  speed: number,
  accuracyRating: number
) {
  return {
    sessionID: sessionID,
    sessionShotNumber: sessionShotNumber,
    horizontalPosition: x,
    verticalPosition: y,
    speed: speed,
    accuracyRating: accuracyRating,
    enabled: true
  } as TrackedShot;
}

const areaWidthRatio = 0.15;
const areaHeightRatio = 0.2;
// This works for drawing, but the check for if the shot is within the area doesn't take the missing corners into account
const areaCornerRadius = 0;
const DefaultTargetSize = new RelativeSize(areaWidthRatio, areaHeightRatio);

// offset % of the width/height of the target area (not the canvas)
const areaAlmostRatio = 0.67;

// The opacity of the LEAST VISIBLE portion
// Because the almost area rect is drawn over the target area, the target area will be darker automatically
const targetOpacity = 0.3;

const enum AnchorPositions {
  BottomLeft, // {0, 1}
  BottomMid, // {0.5, 1}
  BottomRight, // {1, 1}
  MidLeft, // {0, 0.5}
  Center, // {0.5, 0.5}
  MidRight, // {1, 0.5}
  TopLeft, // {0, 0}
  TopMid, // {0.5, 0}
  TopRight // {1, 0}
}
function GetBaseAnchorPointForPosition(position: AnchorPositions): RelativePoint {
  switch (position) {
    case AnchorPositions.TopLeft:
      return new RelativePoint(0, 0);
      break;
    case AnchorPositions.TopMid:
      return new RelativePoint(0.5, 0);
      break;
    case AnchorPositions.TopRight:
      return new RelativePoint(1, 0);
      break;
    case AnchorPositions.MidLeft:
      return new RelativePoint(0, 0.5);
      break;
    case AnchorPositions.Center:
      return new RelativePoint(0.5, 0.5);
      break;
    case AnchorPositions.MidRight:
      return new RelativePoint(1, 0.5);
      break;
    case AnchorPositions.BottomLeft:
      return new RelativePoint(0, 1);
      break;
    case AnchorPositions.BottomMid:
      return new RelativePoint(0.5, 1);
      break;
    case AnchorPositions.BottomRight:
      return new RelativePoint(1, 1);
      break;
  }
}
function GetOriginFromAnchor(
  anchorPoint: RelativePoint,
  position: AnchorPositions,
  width: number,
  height: number
): RelativePoint {
  var halfWidth = +(width / 2).toFixed(3);
  var halfHeight = +(height / 2).toFixed(3);
  switch (position) {
    case AnchorPositions.TopLeft:
      return new RelativePoint(anchorPoint.xRatio, anchorPoint.yRatio);
      break;
    case AnchorPositions.TopMid:
      return new RelativePoint(anchorPoint.xRatio - halfWidth, anchorPoint.yRatio);
      break;
    case AnchorPositions.TopRight:
      return new RelativePoint(anchorPoint.xRatio - width, anchorPoint.yRatio);
      break;
    case AnchorPositions.MidLeft:
      return new RelativePoint(anchorPoint.xRatio, anchorPoint.yRatio - halfHeight);
      break;
    case AnchorPositions.Center:
      return new RelativePoint(anchorPoint.xRatio - halfWidth, anchorPoint.yRatio - halfHeight);
      break;
    case AnchorPositions.MidRight:
      return new RelativePoint(anchorPoint.xRatio - width, anchorPoint.yRatio - halfHeight);
      break;
    case AnchorPositions.BottomLeft:
      return new RelativePoint(anchorPoint.xRatio, anchorPoint.yRatio - height);
      break;
    case AnchorPositions.BottomMid:
      return new RelativePoint(anchorPoint.xRatio - halfWidth, anchorPoint.yRatio - height);
      break;
    case AnchorPositions.BottomRight:
      return new RelativePoint(anchorPoint.xRatio - width, anchorPoint.yRatio - height);
      break;
  }
}
type TargetLocation = {
  areaRatio: number;
  anchorOffset: RelativePoint;
  anchorPosition: AnchorPositions;
};
const TargetLocations = {
  bottomLeft: {
    areaRatio: 0.7,
    anchorOffset: new RelativePoint(0.115, -0.07),
    anchorPosition: AnchorPositions.BottomLeft
  } as TargetLocation,
  bottomRight: {
    areaRatio: 0.7,
    anchorOffset: new RelativePoint(-0.115, -0.07),
    anchorPosition: AnchorPositions.BottomRight
  } as TargetLocation,
  midLeft: {
    areaRatio: 0.6,
    anchorOffset: new RelativePoint(0.12, 0),
    anchorPosition: AnchorPositions.MidLeft
  } as TargetLocation,
  midRight: {
    areaRatio: 0.6,
    anchorOffset: new RelativePoint(-0.12, 0),
    anchorPosition: AnchorPositions.MidRight
  } as TargetLocation,
  topLeft: {
    areaRatio: 1,
    anchorOffset: new RelativePoint(0.125, 0.12),
    anchorPosition: AnchorPositions.TopLeft
  } as TargetLocation,
  topRight: {
    areaRatio: 1,
    anchorOffset: new RelativePoint(-0.125, 0.12),
    anchorPosition: AnchorPositions.TopRight
  } as TargetLocation,
  bottomMid: {
    areaRatio: 0.7,
    anchorOffset: new RelativePoint(0, -0.155),
    anchorPosition: AnchorPositions.BottomMid
  } as TargetLocation
};

function GetTargetArea(location: TargetLocation): RelativeRectangle {
  var sideRatio = Math.sqrt(location.areaRatio);

  var baseAnchorPoint = GetBaseAnchorPointForPosition(location.anchorPosition);
  var anchorX = baseAnchorPoint.xRatio + location.anchorOffset.xRatio;
  var anchorY = baseAnchorPoint.yRatio + location.anchorOffset.yRatio;
  var anchorPoint = new RelativePoint(anchorX, anchorY);

  var width = +(DefaultTargetSize.widthRatio * sideRatio).toFixed(3);
  var height = +(DefaultTargetSize.heightRatio * sideRatio).toFixed(3);

  var origin = GetOriginFromAnchor(anchorPoint, location.anchorPosition, width, height);

  var size = new RelativeSize(width, height);
  return new RelativeRectangle(origin, size);
}

export default FDVue.extend({
  name: "fd-shot-tracker",

  mixins: [rules, errorHandling, tabbedView],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-date-picker": () => import("@fd/lib/vue/components/FP.DatePicker.vue"),
    "fd-time-picker": () => import("@fd/lib/vue/components/TimePicker.vue"),
    "fd-shot-list": () => import("./components/ShotList.vue")
  },

  data: function() {
    return {
      saving: false,
      slidein: false,

      readonly: false, // Becomes true once the session is loaded and we see it's completed

      // Data for editable table
      editingItem: null as TrackedShot | null,
      editingId: null as string | undefined | null,
      editingIndex: -1 as number,
      previousPreviousItem: null as TrackedShot | null,
      previousItem: null as TrackedShot | null,
      nextItem: null as TrackedShot | null,
      nextNextItem: null as TrackedShot | null,
      dialog: false,

      canvas: null as HTMLCanvasElement | null,

      session: {} as SessionWithAppointment,
      shots: [] as TrackedShot[],

      targetAreas: [
        GetTargetArea(TargetLocations.bottomLeft),
        GetTargetArea(TargetLocations.bottomRight),
        GetTargetArea(TargetLocations.midLeft),
        GetTargetArea(TargetLocations.midRight),
        GetTargetArea(TargetLocations.topLeft),
        GetTargetArea(TargetLocations.topRight),
        GetTargetArea(TargetLocations.bottomMid)
      ] as RelativeRectangle[],

      // The following defines all the non "Details" tabs for the view and controls whether they are visible.
      // This is quite relevant for when the platform is on a mobile device in portrait orientation.
      backhandShotTab: {
        tabname: "Backhand Shots",
        key: `${ShotTypes.Backhand}`,
        visible: true
      } as Tab,
      slapShotTab: { tabname: "Slap Shots", key: `${ShotTypes.SlapShot}`, visible: false } as Tab,
      snapShotTab: { tabname: "Snap Shots", key: `${ShotTypes.SnapShot}`, visible: false } as Tab,
      wristShotTab: {
        tabname: "Wrist Shots",
        key: `${ShotTypes.WristShot}`,
        visible: false
      } as Tab,
      summaryTab: {
        tabname: "Summary",
        key: `${ShotTypes.Backhand + 1}`,
        visible: false
      } as Tab,
      firstTabKey: `${ShotTypes.SnapShot}`,

      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      breadcrumbs: [
        {
          text: "Sessions",
          disabled: false,
          to: "/sessions"
        },
        {
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        }
      ]
    };
  },

  computed: {
    // Classes making use of errorHandling can identify methods that are NOT to be wrapped in the exception handler
    unwatchedMethodNames(): string[] {
      return [
        "getAverageSpeed",
        "getAverageAccuracy",
        "getFastestShotInfo",
        "getShotsSummary",
        "setBreadCrumb",
        "tabSelectionCompleted",
        "imageLoaded",
        "resetCanvas",
        "drawShot",
        "drawSelectedShots",
        "getAbsoluteRectFromRelativeRect",
        "modifiedRectByRatio",
        "isPointInArea",
        "drawTargetArea",
        "drawTargetAreas",
        "canvasClicked",
        "addShot",
        "saveEditedItem",
        "saveSession",
        "onlyFieldKeyDown",
        "firstFieldKeyDown",
        "lastFieldKeyDown",
        "rowClick",
        "rowHover",
        "editItemAtRow",
        "editItem",
        "close",
        "cancel",
        "complete",
        "confirmEmail",
        "emailReport"
      ];
    },
    summaryData(): any[] {
      var overallSummary = this.getShotsSummary("Overall", this.shots, true);
      var snapSummary = this.getShotsSummary("Snap", this.snapShots);
      var slapSummary = this.getShotsSummary("Slap", this.slapShots);
      var wristSummary = this.getShotsSummary("Wrist", this.wristShots);
      var backhandSummary = this.getShotsSummary("Backhand", this.backhandShots);

      return [overallSummary, snapSummary, wristSummary, backhandSummary, slapSummary];
    },
    selectedRows(): number[] {
      return [this.editingIndex];
    },
    shotRules() {
      return {
        speed: [this.rules.numeric]
      };
    },

    selectedShots(): TrackedShot[] {
      let selectedTabKey = this.activeTabKey;
      if (selectedTabKey == this.backhandShotTab.key) {
        return this.backhandShots;
      } else if (selectedTabKey == this.slapShotTab.key) {
        return this.slapShots;
      } else if (selectedTabKey == this.snapShotTab.key) {
        return this.snapShots;
      } else if (selectedTabKey == this.wristShotTab.key) {
        return this.wristShots;
      } else if (selectedTabKey == this.summaryTab.key) {
        return this.shots;
      }
      return [];
    },

    backhandShots(): TrackedShot[] {
      return this.shots.filter(shot => shot.shotType == ShotTypes.Backhand);
    },

    slapShots(): TrackedShot[] {
      return this.shots.filter(shot => shot.shotType == ShotTypes.SlapShot);
    },

    snapShots(): TrackedShot[] {
      return this.shots.filter(shot => shot.shotType == ShotTypes.SnapShot);
    },

    wristShots(): TrackedShot[] {
      return this.shots.filter(shot => shot.shotType == ShotTypes.WristShot);
    },

    handednessOptions(): { value: number; text: string | TranslateResult }[] {
      var values = Object.keys(PersonHandedness);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: this.$t("people.handedness." + PersonHandedness[x].toLowerCase())
        };
      });
      return items;
    },

    tabDefinitions(): Tab[] {
      // Snap is not included since it's the first tab and is always visible
      return [this.backhandShotTab, this.slapShotTab, this.wristShotTab, this.summaryTab] as Tab[];
    }
  },

  methods: {
    getAverageSpeed(shots: TrackedShot[]): number {
      let speeds = shots.map(x => x.speed ?? 0);
      let totalSpeed = speeds.reduce((a, b) => a! + b!, 0);
      let avgSpeed = !!totalSpeed && speeds.length > 0 ? totalSpeed / speeds.length : 0;
      return +avgSpeed.toFixed(2);
    },
    getAverageAccuracy(shots: TrackedShot[]): number {
      let accuracies = shots.map(x => x.accuracyRating);
      let totalAccuracy = accuracies.reduce((a, b) => a! + b!, 0);
      let avgAcc = !!totalAccuracy && accuracies.length > 0 ? totalAccuracy / accuracies.length : 0;
      return +avgAcc.toFixed(2);
    },
    getFastestShotInfo(
      shots: TrackedShot[],
      includeShotType: boolean = false
    ): {
      fastestType?: ShotTypes | null | undefined;
      fastestTypeName?: string | VueI18n.TranslateResult | null | undefined;
      fastestSpeed: number | null | undefined;
      fastestShot: number | null | undefined;
    } {
      // The largest number at first should be the first element or null for empty array
      var fastestType = null as ShotTypes | null | undefined;
      var fastestTypeName = null as string | VueI18n.TranslateResult | null | undefined;
      var fastestSpeed = null as number | null | undefined;
      var fastestShot = null as number | null | undefined;

      shots.forEach(x => {
        var number = x.speed ?? 0;
        // Compares stored largest number with current number, stores the largest one
        if (fastestSpeed && number <= fastestSpeed) return;

        fastestSpeed = number;
        fastestShot = x.sessionShotNumber;
        if (includeShotType) {
          fastestType = x.shotType;
          var type = GetFriendlyShotTypeName(x.shotType ?? 0);
          fastestTypeName = type;
        }
      });

      return {
        fastestType: fastestType,
        fastestTypeName: fastestTypeName,
        fastestSpeed: fastestSpeed,
        fastestShot: fastestShot
      };
    },
    getShotsSummary(
      name: string,
      shots: TrackedShot[],
      includeFastestShotType: boolean = false
    ): any {
      let avgSpeed = this.getAverageSpeed(shots);
      var avgAcc = this.getAverageAccuracy(shots);

      var fastestShotInfo = this.getFastestShotInfo(shots, includeFastestShotType);

      return {
        name: name,
        shotCount: shots.length,
        averageSpeed: avgSpeed,
        averageAccuracy: avgAcc,
        fastestSpeed: fastestShotInfo.fastestSpeed,
        fastestShot: fastestShotInfo.fastestShot,
        fastestShotTypeName: fastestShotInfo.fastestTypeName
      };
    },
    setBreadCrumb() {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/sessions") {
        this.notifyNewBreadcrumb({
          text: "Sessions",
          to: "/sessions",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.notifyNewBreadcrumb({
        text: this.$t("shot-tracker.title", [
          this.session.personName,
          DateUtil.localizedDateTimeString(this.session.startedTime)
        ]),
        to: `/shottracker/${this.$route.params.sessionID}`
      });
    },

    tabSelectionCompleted(selectedTab: Tab) {
      this.resetCanvas(this.canvas!);
    },

    imageLoaded() {
      this.resetCanvas(this.canvas!);
    },

    resetCanvas(canvas: HTMLCanvasElement) {
      if (!canvas) return;
      let canvasContext = canvas.getContext("2d")!;
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      var img = this.$refs.netImage as HTMLImageElement;
      canvas.width = img.width;
      canvas.height = img.height;
      canvasContext.drawImage(img, 0, 0);

      // this.drawGridLines(canvas);

      let shotType = +this.activeTabKey;
      if (shotType < 4) {
        let shotCount = this.selectedShots.length;
        if (shotCount < this.targetAreas.length) {
          let currentTarget = this.targetAreas[shotCount];
          this.drawTargetArea(currentTarget, shotCount + 1, canvas);
        } else {
          this.drawTargetAreas(canvas);
        }
      } else {
        this.drawTargetAreas(canvas);
      }

      this.drawSelectedShots(canvas);
    },
    drawShot(shot: TrackedShot, canvas: HTMLCanvasElement) {
      let x = (shot.horizontalPosition || 0) * canvas.width;
      let y = (shot.verticalPosition || 0) * canvas.height;
      let centre = new AbsolutePoint(x, y);

      let shotType = +(shot.shotType ?? 0);
      if (shotType == ShotTypes.SnapShot) {
        this.drawCircle(centre, shot.sessionShotNumber || 0, canvas);
      } else if (shotType == ShotTypes.WristShot) {
        this.drawSquare(centre, shot.sessionShotNumber || 0, canvas);
      } else if (shotType == ShotTypes.SlapShot) {
        this.drawTriangle(centre, shot.sessionShotNumber || 0, canvas);
      } else if (shotType == ShotTypes.Backhand) {
        this.drawDiamond(centre, shot.sessionShotNumber || 0, canvas);
      }
    },
    drawSelectedShots(canvas: HTMLCanvasElement) {
      this.selectedShots.forEach(element => {
        this.drawShot(element, canvas);
      });
    },

    getAbsoluteRectFromRelativeRect(
      relativeRect: RelativeRectangle,
      canvas: HTMLCanvasElement
    ): AbsoluteRectangle {
      let canvasWidth = canvas.width;
      let canvasHeight = canvas.height;

      let width = canvasWidth * relativeRect.relativeSize.widthRatio;
      let height = canvasHeight * relativeRect.relativeSize.heightRatio;
      let x = canvasWidth * relativeRect.relativeOrigin.xRatio;
      let y = canvasHeight * relativeRect.relativeOrigin.yRatio;

      let absoluteRect = new AbsoluteRectangle(
        new AbsolutePoint(x, y),
        new AbsoluteSize(width, height)
      );
      return absoluteRect;
    },

    isPointInArea(point: RelativePoint, area: RelativeRectangle) {
      return (
        point.xRatio > area.relativeOrigin.xRatio &&
        point.xRatio < area.endXRatio &&
        point.yRatio > area.relativeOrigin.yRatio &&
        point.yRatio < area.endYRatio
      );
    },

    // Draws a rectangle using the RELATIVE values passed in
    drawTargetArea(targetArea: RelativeRectangle, targetOrder: number, canvas: HTMLCanvasElement) {
      // The rectangle value of the targetArea is relative.
      // Turn the relative values into absolute values based on canvas size
      let area = this.getAbsoluteRectFromRelativeRect(targetArea, canvas);
      let almostArea = area.biggerRectFromSideScale(areaAlmostRatio);

      this.drawRectangle(almostArea, 0, canvas);
      this.drawRectangle(area, targetOrder, canvas);
    },
    drawTargetAreas(canvas: HTMLCanvasElement) {
      this.targetAreas.forEach((element, index) => {
        this.drawTargetArea(element, index + 1, canvas);
      });
    },
    async canvasClicked(event: MouseEvent) {
      if (this.readonly) return;
      if (this.activeTabKey == this.summaryTab.key) return;

      let clickLocation = new AbsolutePoint(event.offsetX, event.offsetY);
      let newShot = await this.addShot(clickLocation, event.target as HTMLCanvasElement);
      if (!!newShot) {
        this.editItem(newShot);
      }
    },
    drawCircle(center: AbsolutePoint, id: number, canvas: HTMLCanvasElement) {
      let canvasContext = canvas.getContext("2d")!;

      var centerX = center.x;
      var centerY = center.y;
      var radius = 10;
      canvasContext.globalAlpha = 1;

      canvasContext.beginPath();
      canvasContext.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      canvasContext.fillStyle = "green";
      canvasContext.fill();
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = "#003300";
      canvasContext.stroke();

      if (id > 0) {
        canvasContext.strokeStyle = "#FFFFFF";
        canvasContext.textAlign = "center";
        canvasContext.textBaseline = "middle";
        canvasContext.strokeText(`${id}`, centerX, centerY);
      }
    },

    drawGridLines(canvas: HTMLCanvasElement, verLineNum: number = 1, horLineNum: number = 1) {
      var vertLineSpace = canvas.width / (verLineNum + 1);
      for (let index = 0; index < verLineNum; index++) {
        var lineX = vertLineSpace * index + vertLineSpace;
        var topY = 0;
        var bottomY = canvas.height;

        var topPoint = new AbsolutePoint(lineX, topY);
        var bottomPoint = new AbsolutePoint(lineX, bottomY);

        this.drawLine(topPoint, bottomPoint, canvas);
      }

      var horLineSpace = canvas.height / (horLineNum + 1);
      for (let index = 0; index < horLineNum; index++) {
        var lineY = horLineSpace + horLineSpace * index;
        var leftX = 0;
        var rightX = canvas.width;

        var leftPoint = new AbsolutePoint(leftX, lineY);
        var rightPoint = new AbsolutePoint(rightX, lineY);

        this.drawLine(leftPoint, rightPoint, canvas);
      }
    },

    drawLine(from: AbsolutePoint, to: AbsolutePoint, canvas: HTMLCanvasElement) {
      let canvasContext = canvas.getContext("2d")!;
      canvasContext.globalAlpha = 1;

      canvasContext.beginPath();
      canvasContext.moveTo(from.x, from.y);
      canvasContext.lineTo(to.x, to.y);
      canvasContext.closePath();

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = "#00FF00";
      canvasContext.stroke();
    },

    drawSquare(center: AbsolutePoint, id: number, canvas: HTMLCanvasElement) {
      let canvasContext = canvas.getContext("2d")!;

      var dimensionSize = 20;
      var originX = center.x - dimensionSize / 2;
      var originY = center.y - dimensionSize / 2;
      canvasContext.globalAlpha = 1;

      canvasContext.beginPath();
      canvasContext.rect(originX, originY, dimensionSize, dimensionSize);
      canvasContext.fillStyle = "green";
      canvasContext.fill();
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = "#003300";
      canvasContext.stroke();

      if (id > 0) {
        canvasContext.strokeStyle = "#FFFFFF";
        canvasContext.textAlign = "center";
        canvasContext.textBaseline = "middle";
        canvasContext.strokeText(`${id}`, center.x, center.y);
      }
    },

    drawTriangle(center: AbsolutePoint, id: number, canvas: HTMLCanvasElement) {
      let canvasContext = canvas.getContext("2d")!;

      var dimensionSize = 20;
      var originX = center.x - dimensionSize / 2;
      var originY = center.y - dimensionSize / 2;
      canvasContext.globalAlpha = 1;

      let topX = center.x;
      let leftX = center.x - dimensionSize / 2;
      let rightX = center.x + dimensionSize / 2;

      let topY = center.y - dimensionSize / 2;
      let bottomY = center.y + dimensionSize / 2;

      canvasContext.beginPath();
      canvasContext.moveTo(topX, topY);
      canvasContext.lineTo(leftX, bottomY);
      canvasContext.lineTo(rightX, bottomY);
      canvasContext.closePath();

      canvasContext.fillStyle = "green";
      canvasContext.fill();
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = "#003300";
      canvasContext.stroke();

      if (id > 0) {
        canvasContext.strokeStyle = "#FFFFFF";
        canvasContext.textAlign = "center";
        canvasContext.textBaseline = "middle";
        canvasContext.strokeText(`${id}`, center.x, center.y + dimensionSize / 6);
      }
    },

    drawDiamond(center: AbsolutePoint, id: number, canvas: HTMLCanvasElement) {
      let canvasContext = canvas.getContext("2d")!;

      var dimensionSize = 20;
      canvasContext.globalAlpha = 1;

      let topX = center.x;
      let leftX = center.x - dimensionSize / 2;
      let rightX = center.x + dimensionSize / 2;
      let bottomX = center.x;

      let topY = center.y - dimensionSize / 2;
      let leftY = center.y;
      let rightY = center.y;
      let bottomY = center.y + dimensionSize / 2;

      canvasContext.beginPath();
      canvasContext.moveTo(topX, topY);
      canvasContext.lineTo(leftX, leftY);
      canvasContext.lineTo(bottomX, bottomY);
      canvasContext.lineTo(rightX, rightY);
      canvasContext.closePath();

      canvasContext.fillStyle = "green";
      canvasContext.fill();
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = "#003300";
      canvasContext.stroke();

      if (id > 0) {
        canvasContext.strokeStyle = "#FFFFFF";
        canvasContext.textAlign = "center";
        canvasContext.textBaseline = "middle";
        canvasContext.strokeText(`${id}`, center.x, center.y);
      }
    },

    // Draws a rectangle using the ABSOLUTE values passed in
    drawRectangle(rect: AbsoluteRectangle, id: number, canvas: HTMLCanvasElement) {
      let canvasContext = canvas.getContext("2d")!;

      canvasContext.globalAlpha = targetOpacity;

      canvasContext.beginPath();
      var radius = areaCornerRadius;
      if (radius == 0) {
        canvasContext.rect(rect.origin.x, rect.origin.y, rect.size.width, rect.size.height);
      } else {
        var halfWidth = +(rect.size.width / 2).toFixed(3);
        var halfHeight = +(rect.size.height / 2).toFixed(3);
        // Ensure the radius is between 0 and half of whatever's smaller between the width and height
        radius = Math.max(0, Math.min(radius, Math.min(halfWidth, halfHeight)));

        var left = rect.origin.x;
        var right = left + rect.size.width;
        var leftRad = left + radius;
        var rightRad = right - radius;

        var top = rect.origin.y;
        var bottom = top + rect.size.height;
        var topRad = top + radius;
        var bottomRad = bottom - radius;

        var topLeftRad = new AbsolutePoint(leftRad, top);
        var topRightRad = new AbsolutePoint(rightRad, top);
        var rightTopRad = new AbsolutePoint(right, topRad);
        var rightBottomRad = new AbsolutePoint(right, bottomRad);
        var bottomRightRad = new AbsolutePoint(rightRad, bottom);
        var bottomLeftRad = new AbsolutePoint(leftRad, bottom);
        var leftBottomRad = new AbsolutePoint(left, bottomRad);
        var leftTopRad = new AbsolutePoint(left, topRad);

        canvasContext.moveTo(topLeftRad.x, topLeftRad.y);
        canvasContext.lineTo(topRightRad.x, topRightRad.y);
        //Top-right-corner:
        canvasContext.arc(
          rightTopRad.x - radius,
          rightTopRad.y,
          radius,
          (Math.PI / 180) * 270,
          (Math.PI / 180) * 0,
          false
        );
        canvasContext.lineTo(rightBottomRad.x, rightBottomRad.y);
        //Bottom-right-corner:
        canvasContext.arc(
          bottomRightRad.x,
          bottomRightRad.y - radius,
          radius,
          (Math.PI / 180) * 0,
          (Math.PI / 180) * 90,
          false
        );
        canvasContext.lineTo(bottomLeftRad.x, bottomLeftRad.y);
        //Bottom-left-corner:
        canvasContext.arc(
          leftBottomRad.x + radius,
          leftBottomRad.y,
          radius,
          (Math.PI / 180) * 90,
          (Math.PI / 180) * 180,
          false
        );
        canvasContext.lineTo(leftTopRad.x, leftTopRad.y);
        //Top-left-corner:
        canvasContext.arc(
          topLeftRad.x,
          topLeftRad.y + radius,
          radius,
          (Math.PI / 180) * 180,
          (Math.PI / 180) * 270,
          false
        );
        canvasContext.closePath();
      }
      canvasContext.fillStyle = "blue";
      canvasContext.fill();
      canvasContext.lineWidth = 1;
      canvasContext.strokeStyle = "#000033";
      canvasContext.stroke();

      if (id > 0) {
        canvasContext.globalAlpha = 1;

        let centerX = rect.origin.x + rect.size.width / 2;
        let centerY = rect.origin.y + rect.size.height / 2;
        canvasContext.strokeStyle = "#FFFFFF";
        canvasContext.textAlign = "center";
        canvasContext.textBaseline = "middle";
        canvasContext.strokeText(`${id}`, centerX, centerY);
      }
    },
    async addShot(location: AbsolutePoint, canvas: HTMLCanvasElement): Promise<TrackedShot | null> {
      if (this.readonly) return null;
      if (this.processing) return null;

      var sessionID = this.$route.params.sessionID;
      let shotX = +(location.x / canvas!.clientWidth).toFixed(2);
      let shotY = +(location.y / canvas!.clientHeight).toFixed(2);
      let sessionShotNumber = this.selectedShots.length + 1;
      if (sessionShotNumber > 7) {
        return null;
      }

      let shot = NewShot(sessionID, sessionShotNumber, shotX, shotY, 0, 0);

      shot.shotType = +this.activeTabKey;

      let accuracyRating = 1;
      if (this.selectedShots.length < this.targetAreas.length) {
        let targetArea = this.targetAreas[this.selectedShots.length];
        let shotLocation = new RelativePoint(shotX, shotY);

        if (this.isPointInArea(shotLocation, targetArea)) {
          accuracyRating = 3;
        } else {
          let almostArea = targetArea.biggerRectFromSideScale(areaAlmostRatio);
          if (this.isPointInArea(shotLocation, almostArea)) {
            accuracyRating = 2;
          }
        }
      }
      shot.accuracyRating = accuracyRating;

      this.processing = true;
      this.saving = true;
      try {
        var id = await trackedShotService.addItem(shot);
        shot.id = id;
        shot = shot;
        this.shots.push(shot);
        this.resetCanvas(canvas);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
      return shot;
    },

    shotIsLatest(shot: TrackedShot): boolean {
      if (!this.selectedShots.length) return false;
      let latestShot = this.selectedShots[this.selectedShots.length - 1];
      return shot.id == latestShot?.id;
    },
    async removeLatestShot() {
      if (!this.selectedShots.length) return;
      this.processing = true;
      this.saving = true;
      try {
        let latestShot = this.selectedShots[this.selectedShots.length - 1];
        await trackedShotService.deleteItem(latestShot.id!);
        this.shots.splice(this.shots.map(x => x.id!).indexOf(latestShot.id!), 1);
        this.resetCanvas(this.canvas!);

        var snackbarPayload = {
          text: this.$t("shot-tracker.snack-bar-shot-removed-message", [
            GetFriendlyShotTypeName(latestShot.shotType!),
            latestShot.sessionShotNumber
          ]),
          type: "info",
          undoCallback: async () => {
            var id = await trackedShotService.addItem(latestShot);
            latestShot.id = id;
            this.shots.push(latestShot);
            this.resetCanvas(this.canvas!);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveEditedItem(item: TrackedShot) {
      if (this.readonly) return true;

      this.processing = true;
      this.saving = true;
      try {
        if (item.id) {
          // Value comes from a text field so we need to ensure it's a numeric value
          // It's also user input so we need to ensure it's a valid number
          item.speed = isNaN(item.speed ?? 0) ? 0 : +(item.speed || 0);
          item.accuracyRating = +(item.accuracyRating || 1);
          await trackedShotService.updateItem(item.id!, item);
          return true;
        }
      } catch (error) {
        this.handleError(error);
        return false;
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async saveSession(closeOnComplete: boolean) {
      if (this.readonly) return;
      if (!this.session.id) return;

      this.processing = true;
      this.saving = true;
      try {
        await Promise.all(
          this.shots.map(async shot => {
            await trackedShotService.updateItem(shot.id!, shot);
          })
        );

        await sessionService.updateItem(this.session.id!, this.session);
        this.setBreadCrumb();

        var playerName = `${this.session.personName}`;
        var snackbarPayload = {
          text: this.$t("shot-tracker.snack-bar-updated-message", [playerName]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
        if (closeOnComplete) {
          this.$router.push(this.$store.getters.backBreadcrumb?.to || "/sessions");
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    // Editable Table Row
    async onlyFieldKeyDown(e: KeyboardEvent) {
      if (e.key == "Tab") {
        let index = this.editingIndex;
        if (e.shiftKey) {
          // Moving backwards
          index = this.editingIndex - 1;
        } else {
          // Moving forward
          index = this.editingIndex + 1;
        }
        this.editItemAtRow(index);
        (this.$refs.onlyField as HTMLInputElement)?.focus();
        e.preventDefault();
      }
    },

    async firstFieldKeyDown(e: KeyboardEvent) {
      if (e.shiftKey && e.key == "Tab") {
        this.editItemAtRow(this.editingIndex - 1);
        (this.$refs.lastField as HTMLInputElement)?.focus();
        e.preventDefault();
      }
    },

    async lastFieldKeyDown(e: KeyboardEvent) {
      if (!e.shiftKey && e.key == "Tab") {
        this.editItemAtRow(this.editingIndex + 1);
        (this.$refs.firstField as HTMLInputElement)?.focus();
        e.preventDefault();
      }
    },

    rowClick(item: TrackedShot, event: any) {
      if (!item) return;
      this.editItem(item);
    },

    rowHover(item: TrackedShot, event: any) {},

    editItemAtRow(row: number) {
      let item = this.selectedShots[row];
      this.editItem(item);
    },

    async editItem(selectedShot: TrackedShot) {
      if (!selectedShot) {
        this.close();
        return;
      }
      if (this.editingItem) {
        var didSave = await this.saveEditedItem(this.editingItem!);
        if (!didSave) {
          return;
        }
      }
      this.editingIndex = this.selectedShots.indexOf(selectedShot);
      this.editingItem = this.selectedShots[this.editingIndex];
      this.editingId = this.editingItem.id;

      this.previousPreviousItem = this.selectedShots[this.editingIndex - 2];
      this.previousItem = this.selectedShots[this.editingIndex - 1];
      this.nextItem = this.selectedShots[this.editingIndex + 1];
      this.nextNextItem = this.selectedShots[this.editingIndex + 2];
      this.dialog = true;
    },

    async close() {
      this.editingItem!.speed = isNaN(this.editingItem?.speed ?? 0) ? 0 : this.editingItem!.speed;
      var didSave = await this.saveEditedItem(this.editingItem!);
      if (!didSave) {
        return;
      }
      this.dialog = false;
      this.$nextTick(() => {
        this.editingItem = null;
        this.editingIndex = -1;
        this.editingId = null;
      });
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/sessions");
    },

    async complete() {
      this.processing = true;
      this.saving = true;
      try {
        await Promise.all(
          this.shots.map(async shot => {
            await trackedShotService.updateItem(shot.id!, shot);
          })
        );

        var sessionID = this.$route.params.sessionID;
        var completedTime = await sessionService.completeSession(sessionID);
        this.readonly = !!completedTime;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async confirmEmail() {
      var recipient = {
        name: `${this.session.personName}`,
        emailAddress: this.session.personEmailAddress,
        selected: true
      } as Recipient;
      let results = await showSessionEmailSelectionDialog([recipient]);
      if (!!results) {
        this.emailReport(results.recipients, results.copyCurrentUser);
      }
    },

    async emailReport(recipients: Recipient[], copyCurrentUser: boolean) {
      this.inlineMessage.message = null;

      let recipient = recipients.length ? recipients[0] : null;
      if (!recipient && !copyCurrentUser) return;

      this.processing = true;
      this.saving = true;
      try {
        var sessionID = this.$route.params.sessionID;
        await reportService.emailShotTrackingSummary(
          sessionID,
          recipient?.name ?? "",
          recipient?.emailAddress ?? "",
          copyCurrentUser
        );

        var snackbarPayload = {
          text: this.$t("sessions.email-success"),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    ...mapMutations({
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT",
      setSelectedTab: "SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT"
    })
  },

  watch: {
    session(newValue: SessionWithAppointment) {
      this.setBreadCrumb();
    }
  },

  mounted: function() {
    this.canvas = this.$refs.canvas as HTMLCanvasElement;
  },
  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "shot-tracker",
      parentalContext: "sessions",
      selectedTab: ""
    });

    var sessionID = this.$route.params.sessionID;

    this.processing = true;
    try {
      this.session = await sessionService.getByID(sessionID);
      this.readonly = !!this.session.completedTime;
      if (this.readonly) {
        this.tabSelected(this.summaryTab);
      } else {
        this.tabSelected(this.snapShotTab);
      }

      var shots = await trackedShotService.getBySessionID(sessionID);
      this.shots = shots.sort((a, b) => {
        return (a.sessionShotNumber || 0) - (b.sessionShotNumber || 0);
      });
      this.resetCanvas(this.canvas!);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.processing = false;
    }
  }
});

