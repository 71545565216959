import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import { skillsChallengeStationService, SkillsChallengeStation } from "../services";

export default FDVue.extend({
  name: "fd-skills-challenge-stations-list",

  mixins: [rules, errorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: () => ({
    tablesearch: "",

    stations: [] as SkillsChallengeStation[]
  }),

  computed: {},

  methods: {
    async refreshItemList() {
      console.log(`SkillsChallengeStations.vue.ts: methods.refreshItemList`);
      this.processing = true;
      try {
        this.stations = await skillsChallengeStationService.getAll();
      } catch (error) {
        this.inlineMessage.message = this.$t("unexpected-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },

    async flipEnabled(item: any) {
      console.log(`SkillsChallengeStations.vue.ts: methods.flipEnabled`);
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await skillsChallengeStationService.updateItem(item.id, {
          ...item,
          enabled: !item.enabled
        });
        this.stations = this.stations.map(function(x) {
          return {
            ...x,
            enabled: x.id === item.id ? !x.enabled : x.enabled
          };
        });
      } catch (error) {
        this.inlineMessage.message = this.$t("workpackages.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    },

    async deleteStation(item: SkillsChallengeStation) {
      console.log(`SkillsChallengeStations.vue.ts: methods.deleteStation`);
      this.inlineMessage.message = null;
      this.processing = true;
      try {
        await skillsChallengeStationService.deleteItem(item.id || "");
      } catch (error) {
        this.inlineMessage.message = this.$t("workpackages.save-network-error");
        this.inlineMessage.type = "error";
      } finally {
        this.processing = false;
      }
    }
  },

  mounted: function() {
    console.log(`SkillsChallengeStations.vue.ts: mounted`);
  },

  created: async function() {
    console.log(`SkillsChallengeStations.vue.ts: created`);
    this.$store.commit("NOTIFY_NEW_BREADCRUMB", {
      text: "Skills Stations",
      to: "/skillschallengestations",
      resetHistory: true
    });

    this.refreshItemList();
  }
});
