var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800px", persistent: "" },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: [
                _vm.processing
                  ? "dialog-new-top-color-processing"
                  : "dialog-new-top-color"
              ]
            },
            [
              _c(
                "span",
                {
                  class: [_vm.processing ? "breadcrumb-processing-opacity" : ""]
                },
                [_vm._v(_vm._s(_vm.$t("users.new-dialog-title")))]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.processing
                ? _c("v-progress-circular", {
                    attrs: {
                      indeterminate: true,
                      rotate: 0,
                      size: 32,
                      width: 4,
                      color: "white"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper",
            {
              attrs: { "non-linear": "" },
              model: {
                value: _vm.step,
                callback: function($$v) {
                  _vm.step = $$v
                },
                expression: "step"
              }
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "fd-responsive-stepper" },
                [
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.detailsStep,
                        step: _vm.detailsStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("users.new-user.steps.details")) +
                          "\n          "
                      ),
                      _vm.detailserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.new-user-details-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.stickStep,
                        step: _vm.stickStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("users.new-user.steps.stick")) +
                          "\n          "
                      ),
                      _vm.stickerror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.new-user-stick-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.securityStep,
                        step: _vm.securityStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("users.new-user.steps.security")) +
                          "\n          "
                      ),
                      _vm.permissionserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.new-user-security-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-stepper-step",
                    {
                      attrs: {
                        complete: _vm.step > _vm.filesStep,
                        step: _vm.filesStep,
                        editable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("users.new-user.steps.files")) +
                          "\n          "
                      ),
                      _vm.fileserror
                        ? _c("small", { staticClass: "mt-1" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "users.new-user-files-step-error-message"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-progress-linear", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.processing || _vm.saving,
                        expression: "processing || saving"
                      }
                    ],
                    attrs: { indeterminate: _vm.processing || _vm.saving }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.detailsStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "detailsform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("fd-chip-selector", {
                                        attrs: {
                                          cy: "tags",
                                          "available-items":
                                            _vm.userValues.tags,
                                          "selected-items": _vm.user.tags,
                                          "item-label": "name",
                                          disabled: _vm.processing,
                                          "control-label": _vm.$t(
                                            "common.add-tags"
                                          ),
                                          "chip-class":
                                            "tags-selection-chip-colors",
                                          outlined: false
                                        },
                                        on: {
                                          "update:selectedItems": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.user,
                                              "tags",
                                              $event
                                            )
                                          },
                                          "update:selected-items": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.user,
                                              "tags",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "avatar-cropper-container",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12",
                                        md: "6"
                                      }
                                    },
                                    [
                                      _c(
                                        "slim-cropper",
                                        {
                                          staticClass: "profile-image-new",
                                          attrs: {
                                            image: _vm.userPhoto,
                                            label: _vm.$t(
                                              "common.drop-avatar-image-here"
                                            )
                                          },
                                          on: {
                                            "update:image": function($event) {
                                              _vm.userPhoto = $event
                                            }
                                          }
                                        },
                                        [
                                          _c("input", {
                                            attrs: {
                                              type: "file",
                                              name: "slim"
                                            }
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "justify-space-between",
                                      attrs: {
                                        align: "center",
                                        cols: "12",
                                        sm: "12",
                                        md: "6"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "firstname",
                                              label: _vm.$t("common.firstname"),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.firstName
                                            },
                                            model: {
                                              value: _vm.user.firstName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "firstName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.firstName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "lastname",
                                              label: _vm.$t("common.lastname"),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.lastName
                                            },
                                            model: {
                                              value: _vm.user.lastName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "lastName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.lastName"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-date-picker", {
                                            attrs: {
                                              cy: "plannederect",
                                              label: _vm.$t(
                                                "common.birth-date"
                                              ),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.birthDate
                                            },
                                            model: {
                                              value: _vm.user.birthDate,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "birthDate",
                                                  $$v
                                                )
                                              },
                                              expression: "user.birthDate"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              outlined: "",
                                              label: _vm.$t("people.sex.label"),
                                              items: _vm.sexOptions,
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.user.sexID,
                                              callback: function($$v) {
                                                _vm.$set(_vm.user, "sexID", $$v)
                                              },
                                              expression: "user.sexID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "email",
                                              label: _vm.$t("common.email"),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.emailAddress
                                            },
                                            model: {
                                              value: _vm.userEmailAddress,
                                              callback: function($$v) {
                                                _vm.userEmailAddress = $$v
                                              },
                                              expression: "userEmailAddress"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "phone",
                                              label: _vm.$t("common.phone"),
                                              disabled: _vm.processing,
                                              rules: _vm.userRules.phoneNumber,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "users.phone-number-formatting"
                                              )
                                            },
                                            model: {
                                              value: _vm.userPhoneNumber,
                                              callback: function($$v) {
                                                _vm.userPhoneNumber = $$v
                                              },
                                              expression: "userPhoneNumber"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          outlined: "",
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.userValues.languageID,
                                          label: _vm.$t(
                                            "languages.preferred-language-select"
                                          ),
                                          disabled: _vm.processing,
                                          clearable: "",
                                          "persistent-hint": "",
                                          hint: _vm.$t(
                                            "languages.preferred-language-inline-info"
                                          )
                                        },
                                        model: {
                                          value: _vm.user.languageID,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "languageID",
                                              $$v
                                            )
                                          },
                                          expression: "user.languageID"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pl-0 pr-0 pb-0",
                      staticStyle: { "padding-top": "2px" },
                      attrs: { step: _vm.stickStep }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "stickform", on: { submit: _vm.preventSubmit } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2 pt-5",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "brand",
                                              label: _vm.$t(
                                                "people.stick.brand"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.user.stick.brand,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user.stick,
                                                  "brand",
                                                  $$v
                                                )
                                              },
                                              expression: "user.stick.brand"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              cy: "handedness",
                                              outlined: "",
                                              label: _vm.$t(
                                                "people.handedness.label"
                                              ),
                                              items: _vm.handednessOptions,
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value:
                                                _vm.user.stick.handednessID,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user.stick,
                                                  "handednessID",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.stick.handednessID"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between pt-0",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0 my-0 py-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "flex",
                                              label: _vm.$t(
                                                "people.stick.flex"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.user.stick.flex,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user.stick,
                                                  "flex",
                                                  $$v
                                                )
                                              },
                                              expression: "user.stick.flex"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "align-center justify-space-between pt-0",
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-0 ml-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("fd-text-field", {
                                            attrs: {
                                              cy: "flex",
                                              label: _vm.$t(
                                                "people.stick.curve"
                                              ),
                                              disabled: _vm.processing
                                            },
                                            model: {
                                              value: _vm.user.stick.curve,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user.stick,
                                                  "curve",
                                                  $$v
                                                )
                                              },
                                              expression: "user.stick.curve"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.securityStep }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "securityform",
                          on: { submit: _vm.preventSubmit }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "d-flex align-center justify-start fd-inline-radio-button-qualifier ml-0 mr-0 pt-5 pb-5"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "fd-security-tab-extra-info d-none d-sm-flex"
                                    },
                                    [_vm._v("fas fa-user-shield")]
                                  ),
                                  _vm._v(" "),
                                  _c("v-switch", {
                                    staticClass:
                                      "fd-security-tab-switch-placement",
                                    attrs: {
                                      label: _vm.$t("people.can-sign-in-label"),
                                      disabled: _vm.processing,
                                      "persistent-hint": "",
                                      hint: _vm.$t("people.can-sign-in-hint")
                                    },
                                    model: {
                                      value: _vm.user.isLoginActive,
                                      callback: function($$v) {
                                        _vm.$set(_vm.user, "isLoginActive", $$v)
                                      },
                                      expression: "user.isLoginActive"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mx-2 ml-5" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", sm: "12", md: "6" }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "people.user-security-configure-settings"
                                          ),
                                          disabled:
                                            !_vm.user.isLoginActive ||
                                            _vm.processing,
                                          "persistent-hint": "",
                                          hint: _vm.$t(
                                            "people.user-security-configure-settings-hint"
                                          )
                                        },
                                        model: {
                                          value: _vm.user.canConfigureSettings,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "canConfigureSettings",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.canConfigureSettings"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-stepper-content",
                    {
                      staticClass: "pt-1 pl-0 pr-0 pb-0",
                      attrs: { step: _vm.filesStep }
                    },
                    [
                      _c(
                        "v-form",
                        { ref: "filesform", on: { submit: _vm.preventSubmit } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "mx-2",
                                  attrs: { align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c("fd-add-file-button", {
                                            attrs: { disabled: _vm.processing },
                                            on: { change: _vm.addFile }
                                          }),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "people.files-table-listing-tooltip"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.tablesearchfiles,
                                              callback: function($$v) {
                                                _vm.tablesearchfiles = $$v
                                              },
                                              expression: "tablesearchfiles"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:name",
                                            value: _vm.$t("common.name"),
                                            expression: "$t('common.name')",
                                            arg: "name"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "class_fd-actions-cell": true
                                            }
                                          }
                                        ],
                                        staticClass: "fd-actions-table",
                                        attrs: {
                                          items: _vm.files,
                                          search: _vm.tablesearchfiles,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["name"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { left: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            !item.isPreviewable ||
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.viewFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-eye\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.view"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeFile(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.onSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mx-2 mb-3 d-flex d-sm-none justify-end align-center"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            "data-cy": "xs-save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-none d-sm-flex",
                          attrs: {
                            "data-cy": "save",
                            outlined: _vm.step != _vm.lastStep,
                            color: "primary",
                            loading: _vm.processing,
                            disabled: _vm.processing,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.saveDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.save-and-close")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 mr-3 fd-button-context-separator-hide-when-mobile"
                        },
                        [_vm._v("|")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            disabled: _vm.saving,
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: { click: _vm.cancelDialog }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.cancel")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.step == _vm.lastStep ||
                              _vm.processing ||
                              _vm.saving,
                            color: "primary",
                            small: _vm.$vuetify.breakpoint.xsOnly
                          },
                          on: {
                            click: function($event) {
                              _vm.step = Number(_vm.step) + 1
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.continue")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }