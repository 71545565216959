import FDVue from "@fd/lib/vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import rules from "@fd/lib/vue/rules";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import {
  sessionService,
  SessionWithAppointment,
  reportService,
  skillsChallengeService,
  SkillsChallengeWithDetails,
  SkillsChallengeDetail,
  skillsChallengeStationService,
  SkillsChallengeStation
} from "../services";
import * as DateUtil from "@fd/lib/client-util/datetime";
import { showSkillsChallengePlayerNewDialog } from "./SkillsChallengeNewPlayerDialog.vue";
import { showSkillsChallengePlayerEditDialog } from "./SkillsChallengePlayerEditDialog.vue";

type SkillsChallengeDetailWithStationInfo = SkillsChallengeDetail & {
  stationName: string;
  stationNumber: number;
  stationAttemptLabel: string;
  stationSuccessLabel: string;
  stationErrorLabel: string;
  stationTracksDuration: boolean;
  stationTracksSuccesses: boolean;
  stationTracksErrors: boolean;
};

export default FDVue.extend({
  name: "fd-shot-tracker",

  mixins: [rules, errorHandling],

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  components: {
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-player-edit-dialog": () => import("./SkillsChallengePlayerEditDialog.vue")
  },

  data: function() {
    return {
      /*** GLOBAL ***/
      saving: false,
      readonly: false, // Becomes true once the session is loaded and we see it's completed
      slidein: false,
      playerSearch: "",

      /*** NAV ***/
      //The following object is used in conjunction with the breadcrumbs that are presented to the user for sub-view navigation.
      breadcrumbs: [
        {
          text: "Sessions",
          disabled: false,
          to: "/sessions"
        },
        {
          text: this.$t("loading-dot-dot-dot"),
          disabled: true
        }
      ],

      tabDefinitions: [
        { tabname: "Players", visible: true },
        { tabname: "Challenge", visible: false }
      ],

      editingId: null as string | null,

      /*** PAGE DATA ***/
      skillsChallengeStations: [] as SkillsChallengeStation[],
      session: null as SessionWithAppointment | null,
      skillsChallenges: [] as SkillsChallengeWithDetails[]
    };
  },

  computed: {
    active_tab: {
      get(): any {
        return this.$store.state.filters.find(
          (x: any) => x.context == this.$store.state.filteringContext
        )!.selectedTab;
      },
      set(val: any) {
        this.$store.commit("SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT", val);
      }
    },

    challengeRules() {
      return {
        playerName: [this.rules.required],
        playerEmail: [this.rules.validEmail],
        details: {
          duration: [],
          successValue: [],
          errorValue: []
        }
      };
    }
  },

  methods: {
    tabSelected(selectedTabKey: any) {
      this.$store.commit("SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT", selectedTabKey);

      //If the user clicks on the "Players" tab you don't want to remove the one dynamic tab.
      if (selectedTabKey != "Players") {
        //Since the user clicked on a tab look for any tab definitions that have been set to visible
        //and set it to invisible unless of course it is the currently visible tab.
        if (this.tabDefinitions.find(x => x.visible == true)!.tabname != selectedTabKey) {
          this.tabDefinitions.find(x => x.visible == true)!.visible = false;
        }
        this.tabDefinitions.find(x => x.tabname == selectedTabKey)!.visible = true;
        this.$nextTick(() => {
          this.active_tab = "tab-" + selectedTabKey;
        });
      }
    },

    /*** ACTIONS ***/
    async addPlayerChallenge() {
      if (this.readonly) return;

      let result = await showSkillsChallengePlayerNewDialog(this.session!.id);
      if (result) {
        await this.loadSessionData();
      }
    },

    async rowClick(item: any) {
      this.editingId = item.id;
      await showSkillsChallengePlayerEditDialog(
        this.skillsChallenges,
        item,
        this.saveChallenge,
        this.readonly
      );
    },

    rowHover() {},

    /// Goes through every challenge and ensures there's a detail record for each station.
    /// If any station details don't exist for any challenge, added it with default values and returns `True` to indicate saving is needed
    ///
    /// Also maps the details to add the station information to the details records for table generation
    confirmChallengeStationDetails(challenge: SkillsChallengeWithDetails): boolean {
      if (!challenge.details) challenge.details = [];
      var addedData = false;

      this.skillsChallengeStations.forEach(station => {
        var found = false;
        challenge.details!.every(detail => {
          found = found || detail.skillsChallengeStationID == station.id;
          return !found;
        });
        if (!found) {
          console.log(
            `Details Missing: Station ${station.name} for player ${challenge.playerName}`
          );
          var detail = {
            skillsChallengeID: challenge.id,
            skillsChallengeStationID: station.id
          } as SkillsChallengeDetail;
          challenge.details!.push(detail);
          addedData = true;
        }
      });

      challenge.details = challenge.details!.map(detail => {
        var station = this.skillsChallengeStations.filter(
          x => x.id == detail.skillsChallengeStationID
        )[0];
        return {
          ...detail,
          stationName: station.name,
          stationNumber: station.stationNumber,
          stationAttemptLabel: station.attemptLabel,
          stationSuccessLabel: station.successLabel,
          stationErrorLabel: station.errorLabel,
          stationTracksDuration: station.tracksDuration,
          stationTracksSuccesses: station.tracksSuccesses,
          stationTracksErrors: station.tracksErrors
        };
      });

      return addedData;
    },

    async saveChallenge(challenge: SkillsChallengeWithDetails) {
      console.log("saveChallenge - challenge: " + JSON.stringify(challenge));
      if (this.readonly) return true;

      this.inlineMessage.message = "";
      this.processing = true;
      this.saving = true;
      try {
        challenge.details?.forEach(detail => {
          if (detail.duration) detail.duration = +detail.duration!;
          if (detail.successValue) detail.successValue = +detail.successValue!;
          if (detail.errorValue) detail.errorValue = +detail.errorValue!;
        });

        await skillsChallengeService.updateItem(challenge.id!, challenge);
        return true;
      } catch (error) {
        this.handleError(error);
        return false;
      } finally {
        this.saving = false;
        this.processing = false;
      }
    },

    /*** SKILLS CHALLENGE DATA ***/

    challengeDataChanged(challenge: SkillsChallengeWithDetails) {
      this.saveChallenge(challenge);
    },

    /*** PAGE ACTIONS ***/
    // Method used in conjunction with the Cancel button.
    cancel() {
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/sessions");
    },

    async complete() {
      if (this.readonly) return;

      this.inlineMessage.message = "";
      this.processing = true;
      this.saving = true;
      try {
        var sessionID = this.$route.params.sessionID;

        await Promise.all(
          this.skillsChallenges.map(async challenge => {
            if (!this.readonly) {
              await skillsChallengeService.updateItem(challenge.id!, challenge);
            }
          })
        );

        var completedTime = await sessionService.completeSession(sessionID);
        this.readonly = !!completedTime; // If the session is completed we can't change anything
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async emailReport() {
      this.processing = true;
      this.saving = true;
      try {
        var sessionID = this.$route.params.sessionID;
        await reportService.emailSkillsChallengeSummary(sessionID, null);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
        this.saving = false;
      }
    },

    async loadSessionData() {
      this.inlineMessage.message = "";
      var sessionID = this.$route.params.sessionID;

      this.processing = true;
      try {
        var session = await sessionService.getByID(sessionID);
        this.session = session;

        this.readonly = !!session.completedTime;

        this.skillsChallengeStations = await skillsChallengeStationService.getAll();

        // Load challenges for each player plus details
        this.skillsChallenges = (await skillsChallengeService.getBySessionID(session.id!)).sort(
          (a, b) => {
            return (a.playerName ?? "").localeCompare(b.playerName ?? "");
          }
        );

        await Promise.all(
          this.skillsChallenges.map(async challenge => {
            var needsSaving = this.confirmChallengeStationDetails(challenge);
            challenge.details = (challenge.details! as SkillsChallengeDetailWithStationInfo[]).sort(
              (a, b) => {
                return a.stationNumber - b.stationNumber;
              }
            );
            if (needsSaving && !this.readonly) {
              try {
                await skillsChallengeService.updateItem(challenge.id!, challenge);
              } catch (error) {
              } finally {
              }
            }
          })
        );
      } catch (error) {
        this.handleError(error);
      } finally {
        this.processing = false;
      }
    }
  },
  mounted: function() {},

  watch: {
    session(newValue: SessionWithAppointment) {
      // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
      // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
      if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/sessions") {
        this.$store.commit("NOTIFY_NEW_BREADCRUMB", {
          text: "Sessions",
          to: "/sessions",
          resetHistory: true
        });
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");
      }

      this.$store.commit("NOTIFY_NEW_BREADCRUMB", {
        text: newValue.personName + " at " + DateUtil.localizedDateTimeString(newValue.startedTime),
        to: `/skillschallenge/${this.$route.params.sessionID}`
      });
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.$store.commit("SET_FILTERING_CONTEXT", {
      context: "skills-challenge",
      parentalContext: "sessions",
      searchStringForFiltering: "",
      tagsForFiltering: [],
      selectedTab: "Players"
    });

    await this.loadSessionData();
  }
});

