import FDVue from "@fd/lib/vue";
import dialogSupport, { createDialog } from "@fd/lib/vue/mixins/dialogSupport";
import rules from "@fd/lib/vue/rules";
import { SkillsChallengeWithDetails } from "../services";

const SkillsChallengePlayerEditDialog = FDVue.extend({
  name: "fd-player-edit-dialog",

  mixins: [dialogSupport, rules],

  props: {
    readonly: Boolean,
    items: Array,
    save: Function,
    itemEditing: Function
  },

  data: function() {
    return {
      // Data for editable table
      editingItem: null as any | null,
      previousPreviousItem: null as any | null,
      previousItem: null as any | null,
      editingIndex: -1 as number,
      nextItem: null as any | null,
      nextNextItem: null as any | null
    };
  },

  computed: {
    challengeRules() {
      return {
        playerName: [],
        playerEmail: []
      };
    }
  },

  methods: {
    /** EDITABLE TABLE ***/

    rowClick(item: any, event: any) {
      console.log("rowClicked");
      // event?.select(true);
      this.editItem(item);
    },
    rowHover(item: any, event: any) {},

    editItemAtRow(row: number) {
      console.log(`editItemAtRow - row: ${row}`);
      let item = this.items[row];
      this.editItem(item);
    },

    editItem(item: any) {
      console.log(`editItem - item: ${item}`);
      if (!item) {
        console.log("\tNo item.  Closing.");
        this.close();
        return;
      }
      this.$props.editingIndex = this.items.indexOf(item);
      this.editingItem = item;

      this.previousPreviousItem = this.items[this.$props.editingIndex - 2];
      this.previousItem = this.items[this.$props.editingIndex - 1];
      this.nextItem = this.items[this.$props.editingIndex + 1];
      this.nextNextItem = this.items[this.$props.editingIndex + 2];
    },

    close() {
      this.$nextTick(() => {
        this.editingItem = null;
        this.$props.editingIndex = -1;
      });
      this.closeDialog();
    },

    async saveEditedItem(item: any) {
      if (this.readonly) return true;

      console.log(`saveEditedItem: ${item}`);
      console.log(`this.$props.save: ${this.$props.save}`);
      this.processing = true;
      let saved = await this.$props.save(item);
      this.processing = false;
      return saved;
    },

    // Editable Table Row
    async onlyFieldKeyDown(e: KeyboardEvent) {
      console.log("onlyFieldKeyDown");
      if (e.key == "Tab") {
        var didSave = await this.saveEditedItem(this.editingItem!);
        if (!didSave) {
          return;
        }
        let index = this.$props.editingIndex;
        if (e.shiftKey) {
          // Moving backwards
          index = this.$props.editingIndex - 1;
        } else {
          // Moving forward
          index = this.$props.editingIndex + 1;
        }
        this.editItemAtRow(index);
        (this.$refs.onlyField as HTMLInputElement)?.focus();
        e.preventDefault();
      } else {
        console.log("\tContinue on...");
      }
    },

    async firstFieldKeyDown(e: KeyboardEvent) {
      console.log("firstFieldKeyDown");
      if (e.shiftKey && e.key == "Tab") {
        var didSave = await this.saveEditedItem(this.editingItem!);
        if (!didSave) {
          console.log("\tSave failed! Exit.");
          return;
        }

        console.log("\tSave success.  Exit previous row.");
        this.editItemAtRow(this.$props.editingIndex - 1);
        (this.$refs.lastField as HTMLInputElement)?.focus();
        e.preventDefault();
      }
    },

    async lastFieldKeyDown(e: KeyboardEvent) {
      console.log("lastFieldKeyDown");
      if (!e.shiftKey && e.key == "Tab") {
        var didSave = await this.saveEditedItem(this.editingItem!);
        if (!didSave) {
          console.log("\tSave Failed.  Exiting.");
          return;
        }

        console.log(`\tEdit Item at Row: ${this.$props.editingIndex + 1}`);
        this.editItemAtRow(this.$props.editingIndex + 1);
        console.log(`\t${this.$refs.firstField as HTMLInputElement}?.focus()`);
        (this.$refs.firstField as HTMLInputElement)?.focus();
        e.preventDefault();
      }
    }
  },

  mounted: function() {
    this.processing = false;
  },

  created: function() {
    this.processing = false;
  }
});

export default SkillsChallengePlayerEditDialog;

export async function showSkillsChallengePlayerEditDialog(
  items: any[],
  editingItem: any,
  saveFunc: Function,
  readonly: Boolean = false
): Promise<any> {
  let dialog = createDialog(SkillsChallengePlayerEditDialog);
  dialog.optOutOfErrorHandling();
  dialog.$props.readonly = readonly;
  dialog.$props.save = saveFunc;
  dialog.$props.items = items;
  dialog.editItem(editingItem);

  let result = await dialog.showDialog();
  console.log("showSkillsChallengePlayerEditDialog.result: " + result);
  return result;
}
