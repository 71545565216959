var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    directives: [
      {
        name: "fd-column",
        rawName: "v-fd-column:name",
        value: _vm.$t("common.name"),
        expression: "$t('common.name')",
        arg: "name"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:dateTime",
        value: _vm.$t("common.start-date"),
        expression: "$t('common.start-date')",
        arg: "dateTime"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:type.hide-when-extra-small",
        value: _vm.$t("common.type"),
        expression: "$t('common.type')",
        arg: "type",
        modifiers: { "hide-when-extra-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:sessionStatus.hide-when-small",
        value: _vm.$t("sessions.status"),
        expression: "$t('sessions.status')",
        arg: "sessionStatus",
        modifiers: { "hide-when-small": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:actions.no-sort",
        arg: "actions",
        modifiers: { "no-sort": true }
      }
    ],
    class: ["fd-actions-table", { "fd-expanding-table": _vm.showExpand }],
    attrs: {
      items: _vm.items,
      search: _vm.tablesearch,
      loading: _vm.processing,
      "loading-text": _vm.$t("common.table-loading-message"),
      "custom-sort": _vm.customSort,
      "sort-by": ["dateTime"],
      "sort-desc": true,
      "mobile-breakpoint": "0",
      "show-expand": _vm.showExpand
    },
    scopedSlots: _vm._u([
      {
        key: "item.dateTime",
        fn: function(ref) {
          var item = ref.item
          return [_vm._v("\n    " + _vm._s(item.formattedDateTime) + "\n  ")]
        }
      },
      {
        key: "expanded-item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("td", { attrs: { colspan: 6 } }, [
              _c(
                "div",
                { staticClass: "fd-expanding-table-single-details-section" },
                [
                  !!item.appointment && item.appointment.forms.length == 1
                    ? _c(
                        "span",
                        _vm._l(item.appointment.forms[0].fields, function(
                          field,
                          fieldKey
                        ) {
                          return _c("div", { key: fieldKey }, [
                            _c("strong", [_vm._v(_vm._s(field.name))]),
                            _vm._v(" " + _vm._s(field.value) + "\n          ")
                          ])
                        }),
                        0
                      )
                    : !!item.appointment && item.appointment.forms.length > 1
                    ? _c(
                        "span",
                        _vm._l(item.appointment.forms, function(form, formKey) {
                          return _c(
                            "div",
                            { key: formKey },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(form.name) +
                                  "\n            "
                              ),
                              _vm._l(form.fields, function(field, fieldKey) {
                                return _c("div", { key: fieldKey }, [
                                  _vm._v("\n                "),
                                  _c("strong", [_vm._v(_vm._s(field.name))]),
                                  _vm._v(
                                    " " + _vm._s(field.value) + "\n            "
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("common.no-details")) +
                            "\n        "
                        )
                      ])
                ]
              )
            ])
          ]
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            item.canHaveSession
              ? _c("div", [
                  !!item.session && !item.session.completedTime
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.workSession(
                                                    item.session
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-clipboard-play")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("sessions.work-session")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : !!item.session && !!item.session.completedTime
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.viewSession(
                                                    item.session
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-table-eye")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("sessions.view-session")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : !!item.appointment
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.startScheduledSession(
                                                    item.appointment
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-new-box")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("sessions.start-session")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }