// GENERATED CODE - DO NOT EDIT
import { executeServiceRequest, executeUploadServiceRequest } from "@fd/lib/client-util/serviceRequest";
export const appointmentService = {
  getAll(
  ): Promise<Array<Appointment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AppointmentService.GetAll", {
      }
    );
  },
  getToday(
  ): Promise<Array<Appointment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AppointmentService.GetToday", {
      }
    );
  },
  getWithDateRange(
    minDate: Date,
    maxDate: Date,
  ): Promise<Array<Appointment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AppointmentService.GetWithDateRange", {
        minDate,
        maxDate,
      }
    );
  },
  getByID(
    id: number,
  ): Promise<Appointment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AppointmentService.GetByID", {
        id,
      }
    );
  },
}
export const appointmentTypeService = {
  getAll(
  ): Promise<Array<AppointmentType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AppointmentTypeService.GetAll", {
      }
    );
  },
  getByID(
    id: number,
  ): Promise<AppointmentType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AppointmentTypeService.GetByID", {
        id,
      }
    );
  },
}
export const clientService = {
  getAll(
  ): Promise<Array<Client>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.ClientService.GetAll", {
      }
    );
  },
}
export const loginService = {
  getLoginChallenge(
    emailAddress: string,
    phoneNumber: string,
    personalIdentifier: string,
  ): Promise<LoginChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetLoginChallenge", {
        emailAddress,
        phoneNumber,
        personalIdentifier,
      }
    );
  },
  respondToLoginChallenge(
    loginContext: Uint8Array,
    publicHash: Uint8Array,
  ): Promise<LoginInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.RespondToLoginChallenge", {
        loginContext,
        publicHash,
      }
    );
  },
  getCurrentLoginInformation(
  ): Promise<LoginInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetCurrentLoginInformation", {
      }
    );
  },
  logout(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.Logout", {
      }
    );
  },
  getCurrentUserAccessInformation(
  ): Promise<AccessInformation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.GetCurrentUserAccessInformation", {
      }
    );
  },
  sendNewAccountNotice(
    baseUrl: string,
    personID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.SendNewAccountNotice", {
        baseUrl,
        personID,
      }
    );
  },
  updateUserPassword(
    publicSalt: Uint8Array,
    publicHash: Uint8Array,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LoginService.UpdateUserPassword", {
        publicSalt,
        publicHash,
      }
    );
  },
}
export const personStickService = {
  getAll(
  ): Promise<Array<PersonStick>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonStickService.GetAll", {
      }
    );
  },
  getByPersonID(
    personID: string,
  ): Promise<Array<PersonStick>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonStickService.GetByPersonID", {
        personID,
      }
    );
  },
  getCurrentForPerson(
    personID: string,
  ): Promise<PersonStick> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonStickService.GetCurrentForPerson", {
        personID,
      }
    );
  },
  addItem(
    data: PersonStick,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonStickService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: PersonStick,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonStickService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonStickService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const searchService = {
  globalSearch(
    searchString: string,
    tagIDs: Array<string>,
  ): Promise<Array<SearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SearchService.GlobalSearch", {
        searchString,
        tagIDs,
      }
    );
  },
  searchPeople(
    searchTerms: Array<string>,
    tagIDs: Array<string>,
  ): Promise<Array<SearchResult>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SearchService.SearchPeople", {
        searchTerms,
        tagIDs,
      }
    );
  },
}
export const sessionService = {
  getAll(
  ): Promise<Array<SessionWithAppointment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.GetAll", {
      }
    );
  },
  getUnscheduled(
  ): Promise<Array<SessionWithAppointment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.GetUnscheduled", {
      }
    );
  },
  getByID(
    sessionID: string,
  ): Promise<SessionWithAppointment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.GetByID", {
        sessionID,
      }
    );
  },
  getByASAppointmentID(
    appointmentID: number,
  ): Promise<SessionWithAppointment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.GetByASAppointmentID", {
        appointmentID,
      }
    );
  },
  getByPersonID(
    personID: string,
  ): Promise<Array<SessionWithType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.GetByPersonID", {
        personID,
      }
    );
  },
  addItem(
    data: SessionWithAppointment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: SessionWithAppointment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  completeSession(
    sessionID: string,
  ): Promise<Date | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.CompleteSession", {
        sessionID,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const sessionTypeService = {
  getAll(
  ): Promise<Array<SessionTypeWithAppointmentType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.GetAll", {
      }
    );
  },
  getAllWithIncludes(
    includeAppointmentTypeName: boolean,
  ): Promise<Array<SessionTypeWithAppointmentType>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.GetAllWithIncludes", {
        includeAppointmentTypeName,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<SessionTypeWithAppointmentType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.GetByID", {
        id,
      }
    );
  },
  getByIDWithIncludes(
    recordID: string,
    includeAppointmentTypeName: boolean,
  ): Promise<SessionTypeWithAppointmentType> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.GetByIDWithIncludes", {
        recordID,
        includeAppointmentTypeName,
      }
    );
  },
  addItem(
    data: SessionTypeWithAppointmentType,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: SessionTypeWithAppointmentType,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SessionTypeService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const skillsChallengeDetailService = {
  getAll(
  ): Promise<Array<SkillsChallengeDetail>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.GetAll", {
      }
    );
  },
  getByID(
    recordID: string,
  ): Promise<SkillsChallengeDetail> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.GetByID", {
        recordID,
      }
    );
  },
  getByChallengeID(
    parentID: string,
  ): Promise<Array<SkillsChallengeDetail>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.GetByChallengeID", {
        parentID,
      }
    );
  },
  getByStationID(
    parentID: string,
  ): Promise<Array<SkillsChallengeDetail>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.GetByStationID", {
        parentID,
      }
    );
  },
  addItem(
    data: SkillsChallengeDetail,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: SkillsChallengeDetail,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeDetailService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const skillsChallengeService = {
  getAll(
  ): Promise<Array<SkillsChallengeWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.GetAll", {
      }
    );
  },
  getAllWithIncludes(
    includeDetails: boolean,
  ): Promise<Array<SkillsChallengeWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.GetAllWithIncludes", {
        includeDetails,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<SkillsChallengeWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.GetByID", {
        id,
      }
    );
  },
  getByIDWithIncludes(
    recordID: string,
    includeDetails: boolean,
  ): Promise<SkillsChallengeWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.GetByIDWithIncludes", {
        recordID,
        includeDetails,
      }
    );
  },
  getBySessionID(
    sessionID: string,
  ): Promise<Array<SkillsChallengeWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.GetBySessionID", {
        sessionID,
      }
    );
  },
  addItem(
    data: SkillsChallengeWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: SkillsChallengeWithDetails,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const skillsChallengeStationService = {
  getAll(
  ): Promise<Array<SkillsChallengeStation>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeStationService.GetAll", {
      }
    );
  },
  getByID(
    recordID: string,
  ): Promise<SkillsChallengeStation> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeStationService.GetByID", {
        recordID,
      }
    );
  },
  addItem(
    data: SkillsChallengeStation,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeStationService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: SkillsChallengeStation,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeStationService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.SkillsChallengeStationService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const trackedShotService = {
  getAll(
  ): Promise<Array<TrackedShot>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TrackedShotService.GetAll", {
      }
    );
  },
  getBySessionID(
    sessionID: string,
  ): Promise<Array<TrackedShot>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TrackedShotService.GetBySessionID", {
        sessionID,
      }
    );
  },
  addItem(
    data: TrackedShot,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TrackedShotService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    recordID: string,
    data: TrackedShot,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TrackedShotService.UpdateItem", {
        recordID,
        data,
      }
    );
  },
  deleteItem(
    recordID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TrackedShotService.DeleteItem", {
        recordID,
      }
    );
  },
}
export const userService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getAllWithIncludes(
    includeChildren: boolean,
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<PersonWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetAllWithIncludes", {
        includeChildren,
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getCurrent(
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetCurrent", {
      }
    );
  },
  getCurrentUserFullName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetCurrentUserFullName", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByID", {
        id,
      }
    );
  },
  getByIDWithIncludes(
    id: string,
    includeChildren: boolean,
  ): Promise<PersonWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetByIDWithIncludes", {
        id,
        includeChildren,
      }
    );
  },
  addItem(
    data: PersonWithDetails,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: PersonWithDetails,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteItem", {
        id,
      }
    );
  },
  downloadUserPhoto(
    id: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DownloadUserPhoto", {
        id,
      }
    );
  },
  uploadUserPhoto(
    personId: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UploadUserPhoto", requestBody, {
        personId,
      }
    );
  },
  deleteUserPhoto(
    personId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteUserPhoto", {
        personId,
      }
    );
  },
  getUserFileList(
    personId: string,
  ): Promise<Array<string>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.GetUserFileList", {
        personId,
      }
    );
  },
  downloadUserFile(
    personId: string,
    fileName: string,
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DownloadUserFile", {
        personId,
        fileName,
      }
    );
  },
  uploadUserFile(
    personId: string,
    fileName: string,
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.UploadUserFile", requestBody, {
        personId,
        fileName,
      }
    );
  },
  deleteUserFile(
    personId: string,
    fileName: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteUserFile", {
        personId,
        fileName,
      }
    );
  },
  deleteAllUserFiles(
    personId: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.UserService.DeleteAllUserFiles", {
        personId,
      }
    );
  },
}
export const accessCodeService = {
  validateAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ValidateAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  resendAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ResendAccessCode", {
        accessCodeChallenge,
      }
    );
  },
  verifyUnusedEmailAddressForSignup(
    emailAddress: string,
    languageCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.VerifyUnusedEmailAddressForSignup", {
        emailAddress,
        languageCode,
      }
    );
  },
  verifyEmailAddressOrPhoneNumber(
    emailAddress: string,
    phoneNumber: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.VerifyEmailAddressOrPhoneNumber", {
        emailAddress,
        phoneNumber,
      }
    );
  },
  confirmVerifyEmailOrPhoneNumberWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ConfirmVerifyEmailOrPhoneNumberWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  activateUserInformation(
    loginChallenge: LoginChallenge,
    emailAddress: string,
    phoneNumber: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ActivateUserInformation", {
        loginChallenge,
        emailAddress,
        phoneNumber,
      }
    );
  },
  activateAccountWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.ActivateAccountWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
  generateAccessCode(
    loginChallenge: LoginChallenge,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.GenerateAccessCode", {
        loginChallenge,
      }
    );
  },
  loginWithAccessCode(
    accessCodeChallenge: AccessCodeChallenge,
    accessCode: string,
  ): Promise<AccessCodeChallenge> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.AccessCodeService.LoginWithAccessCode", {
        accessCodeChallenge,
        accessCode,
      }
    );
  },
}
export const diagnosticService = {
  generateServerErrorCodes(
  ): Promise<{ [key: string]: string }> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GenerateServerErrorCodes", {
      }
    );
  },
  getStatus(
  ): Promise<AppStatus> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GetStatus", {
      }
    );
  },
  getCurrentVersion(
  ): Promise<Version> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.DiagnosticService.GetCurrentVersion", {
      }
    );
  },
}
export const baseEnvironmentService = {
  getReadOperationalLegacyData(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetReadOperationalLegacyData", {
      }
    );
  },
  getEnvironmentThemeName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetEnvironmentThemeName", {
      }
    );
  },
  getEnvironmentSelfSignUp(
  ): Promise<boolean | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetEnvironmentSelfSignUp", {
      }
    );
  },
  getCurrentEnvironmentID(
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetCurrentEnvironmentID", {
      }
    );
  },
  getCurrent(
  ): Promise<EnvironmentWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetCurrent", {
      }
    );
  },
  downloadOrgLogo(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.DownloadOrgLogo", {
      }
    );
  },
  uploadOrgLogo(
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.UploadOrgLogo", requestBody, {
      }
    );
  },
  deleteOrgLogo(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.DeleteOrgLogo", {
      }
    );
  },
  getAll(
  ): Promise<Array<Environment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Environment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Environment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Environment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.BaseEnvironmentService.DeleteItem", {
        id,
      }
    );
  },
}
export const environmentService = {
  getReadOperationalLegacyData(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetReadOperationalLegacyData", {
      }
    );
  },
  getEnvironmentThemeName(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentThemeName", {
      }
    );
  },
  getEnvironmentSelfSignUp(
  ): Promise<boolean | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetEnvironmentSelfSignUp", {
      }
    );
  },
  getCurrentEnvironmentID(
  ): Promise<string | null> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetCurrentEnvironmentID", {
      }
    );
  },
  getCurrent(
  ): Promise<EnvironmentWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetCurrent", {
      }
    );
  },
  downloadOrgLogo(
  ): Promise<Blob> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DownloadOrgLogo", {
      }
    );
  },
  uploadOrgLogo(
    requestBody: Blob,
  ): Promise<void> {
    return executeUploadServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.UploadOrgLogo", requestBody, {
      }
    );
  },
  deleteOrgLogo(
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DeleteOrgLogo", {
      }
    );
  },
  getAll(
  ): Promise<Array<Environment>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Environment> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Environment,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Environment,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.EnvironmentService.DeleteItem", {
        id,
      }
    );
  },
}
export const languageService = {
  getLanguageCodeForLanguageID(
    id: string,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetLanguageCodeForLanguageID", {
        id,
      }
    );
  },
  getAll(
  ): Promise<Array<Language>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Language> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: Language,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    id: string,
    data: Language,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.UpdateItem", {
        id,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.LanguageService.DeleteItem", {
        id,
      }
    );
  },
}
export const notificationService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<NotificationWithDetails> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetByID", {
        id,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.DeleteItem", {
        id,
      }
    );
  },
  addItem(
    data: Notification,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: Notification,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  getCurrentUserActiveNotifications(
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetCurrentUserActiveNotifications", {
      }
    );
  },
  getCurrentUserUnreadNotifications(
  ): Promise<Array<NotificationWithDetails>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.GetCurrentUserUnreadNotifications", {
      }
    );
  },
  markNotificationWithReadStatus(
    notificationID: string,
    readStatus: boolean,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.MarkNotificationWithReadStatus", {
        notificationID,
        readStatus,
      }
    );
  },
  sendTestNotificationToLoggedInPerson(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.NotificationService.SendTestNotificationToLoggedInPerson", {
      }
    );
  },
}
export const paymentService = {
  getPublishableKey(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.GetPublishableKey", {
      }
    );
  },
  startPayment(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.StartPayment", {
      }
    );
  },
  createPaymentMethod(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.CreatePaymentMethod", {
      }
    );
  },
  deletePaymentMethod(
    paymentMethodID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.DeletePaymentMethod", {
        paymentMethodID,
      }
    );
  },
  getPaymentMethods(
  ): Promise<Array<PaymentMethodInfo>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.GetPaymentMethods", {
      }
    );
  },
  getAvailableSubscriptions(
  ): Promise<Array<SubscriptionInfo>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.GetAvailableSubscriptions", {
      }
    );
  },
  createSubscription(
    paymentMethodID: string,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PaymentService.CreateSubscription", {
        paymentMethodID,
      }
    );
  },
}
export const personalEmailAddressService = {
  getAll(
  ): Promise<Array<PersonalEmailAddress>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonalEmailAddress> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonalEmailAddress,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: PersonalEmailAddress,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.DeleteItem", {
        id,
      }
    );
  },
  getByPersonID(
    PersonId: string,
  ): Promise<PersonalEmailAddress> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalEmailAddressService.GetByPersonID", {
        PersonId,
      }
    );
  },
}
export const personalPhoneNumberService = {
  getAll(
  ): Promise<Array<PersonalPhoneNumber>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetAll", {
      }
    );
  },
  getByID(
    id: string,
  ): Promise<PersonalPhoneNumber> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetByID", {
        id,
      }
    );
  },
  addItem(
    data: PersonalPhoneNumber,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.AddItem", {
        data,
      }
    );
  },
  updateItem(
    itemID: string,
    data: PersonalPhoneNumber,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.UpdateItem", {
        itemID,
        data,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.DeleteItem", {
        id,
      }
    );
  },
  getByPersonID(
    PersonId: string,
  ): Promise<PersonalPhoneNumber> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.PersonalPhoneNumberService.GetByPersonID", {
        PersonId,
      }
    );
  },
}
export const tagService = {
  getAll(
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetAll", {
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByNames(
    tagNames: Array<string>,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetByNames", {
        tagNames,
      }
    );
  },
  getAllWithArchivedStatus(
    showAll: boolean,
    forcedArchivedState: boolean,
    archivedFromDate: Date | null,
    archivedToDate: Date | null,
  ): Promise<Array<Tag>> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetAllWithArchivedStatus", {
        showAll,
        forcedArchivedState,
        archivedFromDate,
        archivedToDate,
      }
    );
  },
  getByID(
    id: string,
  ): Promise<Tag> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.GetByID", {
        id,
      }
    );
  },
  addItem(
    clientData: Tag,
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.AddItem", {
        clientData,
      }
    );
  },
  archiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.ArchiveItem", {
        itemID,
      }
    );
  },
  unarchiveItem(
    itemID: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.UnarchiveItem", {
        itemID,
      }
    );
  },
  updateItem(
    itemID: string,
    clientData: Tag,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.UpdateItem", {
        itemID,
        clientData,
      }
    );
  },
  deleteItem(
    id: string,
  ): Promise<boolean> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.TagService.DeleteItem", {
        id,
      }
    );
  },
}
export const versionService = {
  getVersion(
  ): Promise<string> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.V1.VersionService.GetVersion", {
      }
    );
  },
}
export const reportService = {
  emailAppointmentList(
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.EmailAppointmentList", {
      }
    );
  },
  emailSkillsChallengeSummary(
    sessionID: string,
    challengeID: string | null,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.EmailSkillsChallengeSummary", {
        sessionID,
        challengeID,
      }
    );
  },
  emailShotTrackingSummary(
    sessionID: string,
    recipient: string,
    recipientEmailAddress: string,
    copyCurrentUser: boolean,
  ): Promise<void> {
    return executeServiceRequest(
      "./services/FormidableDesigns.Services.jsreport.ReportService.EmailShotTrackingSummary", {
        sessionID,
        recipient,
        recipientEmailAddress,
        copyCurrentUser,
      }
    );
  },
}
export interface Appointment {
  id: number | null | undefined
  firstName: string | undefined
  lastName: string | undefined
  name: string | undefined
  email: string | undefined
  date: Date | null | undefined
  time: Date | null | undefined
  appointmentTypeID: number | null | undefined
  type: string | undefined
  session: SessionWithAppointment
  forms: Array<AppointmentForm>
}
export interface AppointmentType {
  id: number | null | undefined
  name: string | undefined
  description: string | undefined
  color: string | undefined
  enabled: boolean | null | undefined
}
export interface Client {
  firstName: string | undefined
  lastName: string | undefined
  name: string | undefined
  email: string | undefined
  phone: string | undefined
  notes: string | undefined
}
export interface LoginChallenge {
  loginContext: Uint8Array
  publicSalt: Uint8Array
  needsActivation: boolean
}
export interface LoginInformation {
  firstName: string
  lastName: string
  emailAddress: string
  userID: string
  languageID: string | null
}
export interface AccessInformation {
  canConfigureSettings: boolean | undefined
}
export interface PersonStick {
  id: string | undefined
  personID: string | undefined
  brand: string | undefined
  handednessID: PersonHandedness | undefined
  flex: string | undefined
  curve: string | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  archivedDate: Date | null | undefined
}
export interface SearchResult {
  person: PersonResult
  resultID: string | undefined | null
  foundTagIDs: Array<string>
}
export interface SessionWithAppointment {
  personName: string | undefined
  personEmailAddress: string | undefined
  sessionTypeName: string | undefined
  id: string | undefined
  sessionTypeID: string | undefined
  personID: string | undefined
  acuityAppointmentID: number | null | undefined
  notes: string | undefined
  brand: string | undefined
  handednessID: PersonHandedness | undefined
  flex: string | undefined
  curve: string | undefined
  startedTime: Date | undefined
  completedTime: Date | null | undefined
  enabled: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SessionWithType {
  sessionTypeName: string | undefined
  id: string | undefined
  sessionTypeID: string | undefined
  personID: string | undefined
  acuityAppointmentID: number | null | undefined
  notes: string | undefined
  brand: string | undefined
  handednessID: PersonHandedness | undefined
  flex: string | undefined
  curve: string | undefined
  startedTime: Date | undefined
  completedTime: Date | null | undefined
  enabled: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface SessionTypeWithAppointmentType {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  acuityAppointmentTypeID: number | null | undefined
  acuityAppointmentTypeName: string | undefined
  enabled: boolean | undefined
}
export interface SkillsChallengeDetail {
  id: string | undefined
  skillsChallengeID: string | undefined
  skillsChallengeStationID: string | undefined
  duration: number | undefined
  successValue: number | undefined
  errorValue: number | undefined
  result: number | undefined
  enabled: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
  parentIDColumnName: string
}
export interface SkillsChallengeWithDetails {
  id: string | undefined
  sessionID: string | undefined
  playerName: string | undefined
  playerEmailAddress: string | undefined
  result: number | undefined
  details: Array<SkillsChallengeDetail> | undefined
  enabled: boolean | undefined
}
export interface SkillsChallengeStation {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  stationNumber: number | undefined
  tracksDuration: boolean | undefined
  durationLabel: string | undefined
  pointsPerSecond: number | undefined
  attemptLabel: string | undefined
  successLabel: string | undefined
  errorLabel: string | undefined
  tracksSuccesses: boolean | undefined
  successGoal: number | undefined
  pointsPerSuccess: number | undefined
  fullSuccessPoints: number | undefined
  noSuccessPoints: number | undefined
  tracksErrors: boolean | undefined
  pointsPerError: number | undefined
  enabled: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface TrackedShot {
  id: string | undefined
  sessionID: string | undefined
  sessionShotNumber: number | undefined
  horizontalPosition: number | undefined
  verticalPosition: number | undefined
  speed: number | undefined
  accuracyRating: number | undefined
  shotType: ShotTypes | undefined
  enabled: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonWithDetails {
  tagIDs: Array<string> | undefined
  emailAddresses: Array<PersonalEmailAddressWithVerified> | undefined
  phoneNumbers: Array<PersonalPhoneNumberWithVerified> | undefined
  loginDisabledDate: Date | null | undefined
  loginState: PersonLoginStates | undefined
  isLoginActive: boolean | undefined
  isArchived: boolean | undefined
  canSignIn: boolean | undefined
  hasVerifiedContactMethod: boolean | undefined
  canConfigureSettings: boolean | undefined
  birthDate: Date | null | undefined
  sexID: PersonSexes | undefined
  id: string | undefined
  environmentID: string | null | undefined
  securityCredentialID: string | null | undefined
  personalIdentifier: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  languageID: string | null | undefined
  tagIDJson: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface AccessCodeChallenge {
  loginContext: Uint8Array
  codeContext: Uint8Array
  expires: Date
  attempts: number
  verifyEmailAddressID: Uint8Array
  sentToEmailAddress: string
  sentToEmailAddresses: Array<Uint8Array>
  verifyPhoneNumberID: Uint8Array
  sentToPhoneNumberTail: string
  sentToPhoneNumbers: Array<Uint8Array>
  loginInformation: LoginInformation
}
export interface AppStatus {
  appVersion: string
  databaseConnection: string
  legacyDatabaseConnection: string
  notifications: string
}
export interface Version {
  major: number
  minor: number
  build: number
  revision: number
  majorRevision: number
  minorRevision: number
}
export interface EnvironmentWithDetails {
  readOperationalLegacyData: boolean | null
  enableTimesheets: boolean | null
  enableLabourEntry: boolean | null
  allowExperimentalFeatures: boolean | null
  allowSelfSignUp: boolean | null
  theme: string
  id: string | undefined
  name: string | undefined
  description: string | undefined
  enableNotifications: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Environment {
  id: string | undefined
  name: string | undefined
  description: string | undefined
  enableNotifications: boolean | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Language {
  id: string | undefined
  name: string | undefined
  number: number | undefined
  shortCode: string | undefined
  shortCodeExt: string | undefined
}
export interface NotificationWithDetails {
  isRead: boolean | undefined
  sentTime: Date | undefined
  id: string | undefined
  title: string | undefined
  description: string | undefined
  severity: NotificationSeverity | undefined
  personID: string | null | undefined
  trigger: string | undefined
  context: string | undefined
  contextID: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Notification {
  id: string | undefined
  title: string | undefined
  description: string | undefined
  severity: NotificationSeverity | undefined
  personID: string | null | undefined
  trigger: string | undefined
  context: string | undefined
  contextID: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PaymentMethodInfo {
  id: string
  description: string
}
export interface SubscriptionInfo {
  id: string
  name: string
  priceSummary: string
  selected: boolean
}
export interface PersonalEmailAddress {
  id: string | undefined
  personID: string | undefined
  emailAddress: string | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
  verifiedDate: Date | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface PersonalPhoneNumber {
  id: string | undefined
  personID: string | undefined
  phoneNumber: string | undefined
  type: string | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
  verifiedDate: Date | null | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface Tag {
  id: string | undefined
  environmentID: string | null | undefined
  name: string | undefined
  description: string | undefined
  archivedDate: Date | null | undefined
  created: Date | undefined
  createdBy: string | null | undefined
  updated: Date | null | undefined
  updatedBy: string | null | undefined
}
export interface AppointmentForm {
  name: string
  fields: Array<AppointmentFormField>
}
export enum PersonHandedness {
  NoneEntered = 0,
  Right = 1,
  Left = 2,
  Both = 3,
}
export interface PersonResult {
  id: string | null
  firstName: string
  lastName: string
  tagIDs: Array<string>
}
export enum ShotTypes {
  SlapShot = 0,
  WristShot = 1,
  SnapShot = 2,
  Backhand = 3,
}
export interface PersonalEmailAddressWithVerified {
  id: string | undefined
  emailAddress: string | undefined
  isVerified: boolean | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
}
export interface PersonalPhoneNumberWithVerified {
  id: string | undefined
  phoneNumber: string | undefined
  type: string | undefined
  isVerified: boolean | undefined
  allowNotifications: boolean | undefined
  isPrimary: boolean | undefined
}
export enum PersonLoginStates {
  None = 0,
  Unused = 1,
  Active = 2,
  Deactivated = 3,
}
export enum PersonSexes {
  NoneEntered = 0,
  Male = 1,
  Female = 2,
}
export enum NotificationSeverity {
  Info = 1,
  Warning = 10,
  Critical = 100,
}
export interface AppointmentFormField {
  value: string
  name: string
}
