var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-slide-x-reverse-transition",
        [
          _c(
            "v-card",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slidein,
                  expression: "slidein"
                }
              ]
            },
            [
              _c(
                "v-toolbar",
                {
                  class: [
                    _vm.processing
                      ? "view-card-top-color-processing"
                      : "view-card-top-color"
                  ]
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c("v-breadcrumbs", {
                                staticClass: "d-none d-sm-flex",
                                class: [
                                  _vm.processing
                                    ? "breadcrumb-processing-opacity"
                                    : "",
                                  "pl-3"
                                ],
                                attrs: {
                                  items: _vm.$store.state.currentBreadcrumbs,
                                  large: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "divider",
                                    fn: function() {
                                      return [
                                        _c("v-icon", [
                                          _vm._v("fa-chevron-right")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("fd-back-button", {
                                attrs: {
                                  route: function(item) {
                                    return _vm.$router.push(
                                      _vm.$store.getters.backBreadcrumb.to ||
                                        "/sessions"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.processing
                                ? _c("v-progress-circular", {
                                    staticClass: "mr-3",
                                    attrs: {
                                      indeterminate: true,
                                      rotate: 0,
                                      size: 32,
                                      width: 4,
                                      color: "white"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.inlineMessage.message || _vm.readonly
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.inlineMessage.message
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-0 mb-3",
                              attrs: { type: _vm.inlineMessage.type }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.inlineMessage.message) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.readonly
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-0 mb-3",
                              attrs: { type: "info" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t("sessions.complete-status-message")
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "pb-0 px-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      ref: "tabs",
                      attrs: { "icons-and-text": "" },
                      model: {
                        value: _vm.active_tab,
                        callback: function($$v) {
                          _vm.active_tab = $$v
                        },
                        expression: "active_tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          key: "Players",
                          ref: "tab",
                          attrs: { href: "#tab-Players" },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected("Players")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("sessions.players")) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-edit")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab",
                        {
                          key: "Challenge",
                          attrs: { href: "#tab-Challenge" },
                          on: {
                            click: function($event) {
                              return _vm.tabSelected("Challenge")
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("sessions.challenge")) +
                              "\n            "
                          ),
                          _c("v-icon", [_vm._v("fas fa-calendar-check")])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.active_tab,
                        callback: function($$v) {
                          _vm.active_tab = $$v
                        },
                        expression: "active_tab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { key: "Players", attrs: { value: "tab-Players" } },
                        [
                          _c(
                            "v-card-title",
                            { staticStyle: { "margin-top": "15px" } },
                            [
                              !_vm.readonly
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-1 mr-1",
                                          attrs: {
                                            color: "primary",
                                            dark: "",
                                            fab: ""
                                          },
                                          on: { click: _vm.addPlayerChallenge }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "mx-4",
                                        attrs: { inset: "", vertical: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass: "ml-1",
                                                          attrs: {
                                                            color: "black",
                                                            dark: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [_vm._v("info")]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            92516267
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "sessions.player-listing-information"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "pl-0",
                                attrs: {
                                  "append-icon": "search",
                                  label: _vm.$t("common.search"),
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: ""
                                },
                                model: {
                                  value: _vm.playerSearch,
                                  callback: function($$v) {
                                    _vm.playerSearch = $$v
                                  },
                                  expression: "playerSearch"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            directives: [
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:playerName",
                                value: "Player Name",
                                expression: "'Player Name'",
                                arg: "playerName"
                              },
                              {
                                name: "fd-column",
                                rawName: "v-fd-column:playerEmailAddress",
                                value: "Player Email",
                                expression: "'Player Email'",
                                arg: "playerEmailAddress"
                              }
                            ],
                            attrs: {
                              search: _vm.playerSearch,
                              items: _vm.skillsChallenges,
                              "mobile-breakpoint": "0",
                              "disable-sort": ""
                            },
                            on: {
                              "click:row": _vm.rowClick,
                              mouseover: _vm.rowHover
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "tr",
                                      {
                                        class: {
                                          selected: item.id == _vm.editingId
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.rowClick(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-right" },
                                          [
                                            !!item.playerName
                                              ? _c("strong", [
                                                  _vm._v(
                                                    _vm._s(item.playerName) +
                                                      " "
                                                  )
                                                ])
                                              : _c("v-subheader", [
                                                  _vm._v("New Player")
                                                ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-right" },
                                          [
                                            _vm._v(
                                              _vm._s(item.playerEmailAddress)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "top",
                                fn: function() {
                                  return [_c("fd-player-edit-dialog")]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { key: "Challenge", attrs: { value: "tab-Challenge" } },
                        [
                          _c(
                            "v-card-title",
                            { staticStyle: { "margin-top": "15px" } },
                            [
                              !_vm.readonly
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ml-1 mr-1",
                                          attrs: {
                                            color: "primary",
                                            dark: "",
                                            fab: ""
                                          },
                                          on: { click: _vm.addPlayerChallenge }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-plus")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", {
                                        staticClass: "mx-4",
                                        attrs: { inset: "", vertical: "" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass: "ml-1",
                                                          attrs: {
                                                            color: "black",
                                                            dark: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [_vm._v("info")]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            92516267
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "sessions.player-listing-information"
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-simple-table", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function() {
                                      return [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            [
                                              _c("td"),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.skillsChallengeStations,
                                                function(station) {
                                                  return _c(
                                                    "td",
                                                    {
                                                      key: station.id,
                                                      class:
                                                        station.stationNumber %
                                                          2 ==
                                                        0
                                                          ? ""
                                                          : "stationAlt",
                                                      attrs: {
                                                        colspan:
                                                          (station.tracksDuration
                                                            ? 1
                                                            : 0) +
                                                          (station.tracksSuccesses
                                                            ? 1
                                                            : 0) +
                                                          (station.tracksErrors
                                                            ? 1
                                                            : 0)
                                                      }
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(station.name)
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "tr",
                                            [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-subheader", [
                                                    _vm._v("Player ")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.skillsChallengeStations,
                                                function(station) {
                                                  return [
                                                    station.tracksDuration
                                                      ? _c(
                                                          "td",
                                                          {
                                                            key:
                                                              "DurationHeader_" +
                                                              station.name,
                                                            class:
                                                              station.stationNumber %
                                                                2 ==
                                                              0
                                                                ? ""
                                                                : "stationAlt"
                                                          },
                                                          [
                                                            _c("v-subheader", [
                                                              _vm._v(
                                                                "\n                            Duration\n                          "
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    station.tracksSuccesses
                                                      ? _c(
                                                          "td",
                                                          {
                                                            key:
                                                              "SuccessesHeader_" +
                                                              station.name,
                                                            class:
                                                              station.stationNumber %
                                                                2 ==
                                                              0
                                                                ? ""
                                                                : "stationAlt"
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticStyle: {
                                                                  color: "green"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      !!station.successLabel
                                                                        ? station.successLabel
                                                                        : "Successes"
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    station.tracksErrors
                                                      ? _c(
                                                          "td",
                                                          {
                                                            key:
                                                              "ErrorsHeader_" +
                                                              station.name,
                                                            class:
                                                              station.stationNumber %
                                                                2 ==
                                                              0
                                                                ? ""
                                                                : "stationAlt"
                                                          },
                                                          [
                                                            _c(
                                                              "v-subheader",
                                                              {
                                                                staticStyle: {
                                                                  color: "red"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      !!station.errorLabel
                                                                        ? station.errorLabel
                                                                        : "Errors"
                                                                    ) +
                                                                    "\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.skillsChallenges, function(
                                            challenge
                                          ) {
                                            return _c(
                                              "tr",
                                              { key: challenge.id },
                                              [
                                                _c(
                                                  "td",
                                                  [
                                                    !!challenge.playerName
                                                      ? _c("strong", [
                                                          _vm._v(
                                                            _vm._s(
                                                              challenge.playerName
                                                            ) + " "
                                                          )
                                                        ])
                                                      : _c("v-subheader", [
                                                          _vm._v("New Player")
                                                        ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  challenge.details,
                                                  function(detail) {
                                                    return [
                                                      detail.stationTracksDuration
                                                        ? _c(
                                                            "td",
                                                            {
                                                              key:
                                                                "duration_" +
                                                                detail.id,
                                                              class:
                                                                detail.stationNumber %
                                                                  2 ==
                                                                0
                                                                  ? ""
                                                                  : "stationAlt"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readonly
                                                                  },
                                                                  on: {
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.challengeDataChanged(
                                                                        challenge
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      detail.duration,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        detail,
                                                                        "duration",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "detail.duration"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      detail.stationTracksSuccesses
                                                        ? _c(
                                                            "td",
                                                            {
                                                              key:
                                                                "successes_" +
                                                                detail.id,
                                                              class:
                                                                detail.stationNumber %
                                                                  2 ==
                                                                0
                                                                  ? ""
                                                                  : "stationAlt"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readonly
                                                                  },
                                                                  on: {
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.challengeDataChanged(
                                                                        challenge
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      detail.successValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        detail,
                                                                        "successValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "detail.successValue"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      detail.stationTracksErrors
                                                        ? _c(
                                                            "td",
                                                            {
                                                              key:
                                                                "errors_" +
                                                                detail.id,
                                                              class:
                                                                detail.stationNumber %
                                                                  2 ==
                                                                0
                                                                  ? ""
                                                                  : "stationAlt"
                                                            },
                                                            [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.readonly
                                                                  },
                                                                  on: {
                                                                    blur: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.challengeDataChanged(
                                                                        challenge
                                                                      )
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      detail.errorValue,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        detail,
                                                                        "errorValue",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "detail.errorValue"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          }),
                                          0
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", link: "", disabled: _vm.processing },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.close")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "secondary",
                        loading: _vm.saving,
                        disabled: _vm.processing
                      },
                      on: { click: _vm.emailReport }
                    },
                    [_vm._v(_vm._s(_vm.$t("sessions.email-action")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        loading: _vm.saving,
                        disabled: _vm.processing || _vm.readonly
                      },
                      on: { click: _vm.complete }
                    },
                    [_vm._v(_vm._s(_vm.$t("sessions.complete")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }