import FDVue from "@fd/lib/vue";
import rules from "@fd/lib/vue/rules";
import store from "../store";
import {
  Tag,
  PersonWithDetails,
  PersonalEmailAddressWithVerified,
  PersonalPhoneNumberWithVerified,
  PersonLoginStates,
  PersonSexes,
  PersonHandedness,
  personStickService,
  PersonStick
} from "../services";
import { checkLogin } from "../login";
import { getYearsDifference } from "../../../lib/client-util/datetime";
import { TranslateResult } from "vue-i18n";

// TODO: Review this code - why a mixin? Replace with configuration method use?
type UserWithExtraDetails = PersonWithDetails & {
  tags: Tag[];
  isActiveWithUnusedLogin: boolean;
  age: number | null | undefined;
  ageString: string | null | undefined;
  stick: PersonStick;
};

// TODO: Review this code - why a mixin? Replace with configuration method use?

var newUserMixin = FDVue.extend({
  mixins: [rules],
  data() {
    return {
      user: { stick: {} } as UserWithExtraDetails
    };
  },
  computed: {
    userRules() {
      return {
        firstName: [this.rules.required],
        lastName: [this.rules.required],
        emailAddress: [this.rules.required, this.rules.validEmail],
        phoneNumber: [this.rules.validPhoneNumber],
        addFile: [this.rules.limitedFileSize]
      };
    },
    userValues() {
      return {
        languageID: this.$store.state.languages.fullList,
        tags: this.$store.getters.sortedEnabledTags
      };
    },
    sexOptions(): { value: number; text: string | TranslateResult }[] {
      var values = Object.keys(PersonSexes);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: this.$t("people.sex." + PersonSexes[x].toLowerCase())
        };
      });
      return items;
    },
    handednessOptions(): { value: number; text: string | TranslateResult }[] {
      var values = Object.keys(PersonHandedness);
      var keys = values.filter(x => !isNaN(Number(x))).map(x => Number(x)) as number[];
      var items = keys.map(x => {
        return {
          value: x,
          text: this.$t("people.handedness." + PersonHandedness[x].toLowerCase())
        };
      });
      return items;
    }
  },
  methods: {
    async initUser() {
      this.user = {
        firstName: "",
        lastName: "",
        birthDate: undefined,
        emailAddresses: [] as PersonalEmailAddressWithVerified[],
        phoneNumbers: [] as PersonalPhoneNumberWithVerified[],
        languageID: undefined as string | undefined,
        tags: [] as Tag[],
        isActiveWithUnusedLogin: false,
        isLoginActive: false,
        canConfigureSettings: false,
        stick: {}
      } as UserWithExtraDetails;
    },
    async saveUser() {
      var newID = await store.dispatch("ADD_USER", {
        ...this.user,
        tagIDs: this.user.tags.length > 0 ? this.user.tags.map(x => x.id) : null
      });

      //Now do a call to checkLogin which will load loginInformation and will call to the store to replace user information which should refresh since
      //this could have updated the current signed in user's profile and the UI for things like the Name for the Avatar and the Preferred language.
      await checkLogin();

      return newID;
    },
    async refreshUserReferenceData() {
      await Promise.all([this.$store.dispatch("LOAD_TAGS")]);
    }
  },
  created() {
    this.refreshUserReferenceData();
    this.initUser();
  }
});

function createLoadUserMixin() {
  return newUserMixin.extend({
    created() {},
    data() {
      return {
        userID: ""
      };
    },
    computed: {
      // TODO: We don't really need these computed values once we get the state strongly typed
      allUsers(): PersonWithDetails[] {
        return (store.state as any).users.fullList as PersonWithDetails[];
      },
      allTags(): Tag[] {
        return (store.state as any).tags.fullList as Tag[];
      }
    },
    methods: {
      async loadUser(userID: string) {
        this.userID = userID;
        await store.dispatch("LOAD_USER", userID);
        let loadedUser = this.allUsers.find(x => x.id == userID);
        let stick = await personStickService.getCurrentForPerson(userID);
        if (!stick) {
          stick = {
            personID: userID
          } as PersonStick;
        }
        if (loadedUser) {
          this.user = {
            ...loadedUser,
            id: undefined,
            isActiveWithUnusedLogin:
              !loadedUser.isArchived && loadedUser.loginState == PersonLoginStates.Unused,
            tagIDs: undefined,
            tags: loadedUser.tagIDs
              ? loadedUser.tagIDs.map(x => this.allTags.find(y => y.id == x)).filter(x => !!x)
              : [],
            age: getYearsDifference(loadedUser.birthDate),
            ageString: !!loadedUser.birthDate
              ? `${getYearsDifference(loadedUser.birthDate)} ${this.$t("common.years")}`
              : undefined,
            stick: stick
          } as UserWithExtraDetails;
        } else {
          // TODO: Should we raise an error here?
        }
      },
      async saveUser() {
        await store.dispatch("UPDATE_USER", {
          ...this.user,
          id: this.userID,
          tagIDs: this.user.tags.length > 0 ? this.user.tags.map(x => x.id) : null
        });

        //Now do a call to checkLogin which will load loginInformation and will call to the store to replace user information which should refresh since
        //this could have updated the current signed in user's profile and the UI for things like the Name for the Avatar and the Preferred language.
        await checkLogin();
      },
      // Used to save the user info when saving from the "My Profile" page
      async savePartialUser() {
        var userToUpdate = {
          ...this.user,
          id: this.userID,
          // The following properties are not allowed to be updated from the "My Profile" page and need to be set to undefined so the server ignores them
          tagIDs: undefined,
          archivedDate: undefined,
          canConfigureSettings: undefined
        };

        await store.dispatch("UPDATE_USER", userToUpdate);

        //Now do a call to checkLogin which will load loginInformation and will call to the store to replace user information which should refresh since
        //this could have updated the current signed in user's profile and the UI for things like the Name for the Avatar and the Preferred language.
        await checkLogin();
      },
      async deleteUser() {
        await store.dispatch("DELETE_USER", { id: this.userID });
      }
    }
  });
}

export default {
  new: newUserMixin,
  load: createLoadUserMixin
};

