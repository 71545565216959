import Vue from "vue";
import Vuex from "vuex";
import { pause } from "@fd/lib/client-util/util";

import moment from "moment";

import { AutomaticCrudState } from "./store/automatic";
import UserStore from "./store/users";
import TagStore from "./store/tags";
import LanguageStore from "./store/languages";
import { Tag, PersonWithDetails, Language, AccessInformation } from "./services";

// TODO: We need to export this to re-export our state variable elsewhere
export interface Breadcrumb {
  disabled?: boolean;
  exact?: boolean;
  href?: string;
  link?: boolean;
  text?: string | number;
  to?: string | object;
}

export interface Filter {
  context: string;
  parentalContext?: string;
  showArchivedForFiltering?: boolean;
  showArchivedForFilteringFromDate?: Date;
  showArchivedForFilteringToDate?: Date;
  searchStringForFiltering?: string;
  tagsForFiltering?: string[];
  selectedTab?: number;
}

interface BreadcrumbUpdate {
  text: string | number;
  to: string; // TODO: API supports objects but since we don't deep compare when checking history we can't do the same; we should allow objects and do deep compare for history check
  resetHistory?: boolean;
}

import i18n from "./i18n";
import { LANGUAGES } from "./i18n";
import { showDisableInsteadConfirmation } from "../../common/client/views/components/DisableInsteadDialog.vue";

import VueI18n from "vue-i18n";
import { Lang } from "vuetify/types/services/lang";
Vue.use(Vuex);

export interface StoreState {
  // Modules
  tags: AutomaticCrudState<Tag>;
  users: AutomaticCrudState<PersonWithDetails>;
  languages: AutomaticCrudState<Language>;
  // State Properties
  barColor: string;
  barImage: string;
  lastBreadcrumbs: Breadcrumb[];
  currentBreadcrumbs: Breadcrumb[];
  breadcrumbs: Breadcrumb[];
  loginReturnPath: string | null;
  showAppBar: boolean;
  showDrawer: boolean;
  showFooter: boolean;
  showBottomBar: boolean;
  drawer: any; // TODO: TYPE THIS!
  drawerMini: boolean;
  dialogPrivacy: boolean;
  dialogTerms: boolean;
  snackbar: boolean;
  snackbarText: string;
  snackbarType: string;
  snackbarUndoCallback: (() => Promise<void>) | null;
  snackbarCountdownInterval: number | null;
  snackbarCountdown: number;
  filteringContext: string | null;
  searchStringForFiltering: string;
  tagsForFiltering: [];
  suppliersForFiltering: [];
  statusesForFiltering: [];
  contractorsForFiltering: [];
  filters: Array<Filter>;
  inlineDialogRef: any; // TODO: TYPE THIS
  avatarInitials: string;
  curUserID: string | null;
  language: LANGUAGES;
  curUserAccess: AccessInformation;
  datepickerLanguage: string;
  footerImageSource: string;
  brandImageWidthValue: number;
  brandImageHeightValue: number;
  brandImageWidthSmallerValue: number;
  brandImageHeightSmallerValue: number;
  imageSourceLogo: string;
  imageSourceLogoReversed: string;
  imageSourceLogoGray: string;
}

export default new Vuex.Store<StoreState>({
  modules: {
    tags: TagStore,
    users: UserStore,
    languages: LanguageStore
  },
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage: "https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg",
    lastBreadcrumbs: [] as Breadcrumb[],
    currentBreadcrumbs: [] as Breadcrumb[],
    breadcrumbs: [] as Breadcrumb[],
    loginReturnPath: null as string | null,
    showAppBar: false,
    showDrawer: false,
    showFooter: false,
    showBottomBar: false,
    drawer: null,
    drawerMini: false,
    dialogPrivacy: false,
    dialogTerms: false,
    snackbar: false,
    snackbarText: "",
    snackbarType: "info",
    snackbarUndoCallback: null as (() => Promise<void>) | null,
    // Holds a reference to the numeric interval timer ID so that it can be cleared if needed.
    snackbarCountdownInterval: null as number | null,
    snackbarCountdown: 20,
    filteringContext: null,
    searchStringForFiltering: "",
    tagsForFiltering: [],
    filters: [] as Filter[],
    inlineDialogRef: null,
    avatarInitials: "",
    curUserID: null,
    language: LANGUAGES.ENGLISH,
    datepickerLanguage: "en-us",
    footerImageSource: "/assets/img/logo.svg",
    brandImageWidthValue: -1,
    brandImageHeightValue: -1,
    brandImageWidthSmallerValue: -1,
    brandImageHeightSmallerValue: -1,
    imageSourceLogo: "/assets/img/logo.svg",
    imageSourceLogoReversed: "/assets/img/logo_reversed.svg",
    imageSourceLogoGray: "/assets/img/logo_gray.svg"
  } as StoreState,
  mutations: {
    SET_BRAND_LOGO_WIDTH(state, payload) {
      state.brandImageWidthValue = payload;
    },
    SET_BRAND_LOGO_HEIGHT(state, payload) {
      state.brandImageHeightValue = payload;
    },
    SET_BRAND_LOGO_WIDTH_SMALLER(state, payload) {
      state.brandImageWidthSmallerValue = payload;
    },
    SET_BRAND_LOGO_HEIGHT_SMALLER(state, payload) {
      state.brandImageHeightSmallerValue = payload;
    },
    SET_FOOTER_IMAGE_SOURCE(state, payload) {
      state.footerImageSource = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_SHOW_APP_BAR(state, payload) {
      state.showAppBar = payload;
    },
    SET_SHOW_DRAWER(state, payload) {
      state.showDrawer = payload;
    },
    SET_SHOW_FOOTER(state, payload) {
      state.showFooter = payload;
    },
    SET_SHOW_BOTTOM_BAR(state, payload) {
      state.showBottomBar = payload;
    },
    SET_LOGIN_RETURN_PATH(state, payload) {
      state.loginReturnPath = payload;
    },
    CLEAR_LOGIN_RETURN_PATH(state) {
      state.loginReturnPath = null;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_DRAWER_MINI(state, payload) {
      state.drawerMini = payload;
    },
    SET_PRIVACY_DIALOG(state, payload) {
      state.dialogPrivacy = payload;
    },
    SET_TERMS_DIALOG(state, payload) {
      state.dialogTerms = payload;
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload;
    },
    SET_SNACKBAR_TEXT(state, payload) {
      state.snackbarText = payload;
    },
    SET_SNACKBAR_TYPE(state, payload) {
      state.snackbarType = payload;
    },
    SET_SNACKBAR_UNDO_CALLBACK(state, payload) {
      state.snackbarUndoCallback = payload;
    },
    SET_SNACKBAR_COUNTDOWN_INTERVAL(state, payload) {
      state.snackbarCountdownInterval = payload;
    },
    SET_SNACKBAR_COUNTDOWN(state, payload) {
      state.snackbarCountdown = payload;
    },
    CLEAR_SNACKBAR_COUNTDOWN_INTERVAL(state, payload) {
      if (state.snackbarCountdownInterval != null) {
        clearInterval(state.snackbarCountdownInterval);
        state.snackbarCountdownInterval = null;
      }
    },
    SET_AVATAR_INITIALS(state, payload) {
      state.avatarInitials = payload;
    },
    SET_CUR_USERID(state, payload: string) {
      state.curUserID = payload;
    },
    SET_CUR_USER_ACCESS(state, payload: AccessInformation) {
      state.curUserAccess = payload;
    },
    SET_PREFERRED_LANGUAGE(state, payload) {
      // The "number" associated to the language is used to qualify the items chosen in the language menu.
      state.language = payload.number;
      //This updates the i18n library to know which language to display
      i18n.locale = payload.shortCode;
      //This qualifies the various vuetify datepickers to use the appropriate language
      state.datepickerLanguage = payload.shortCodeExt;
      //The moment library allows us to give fully qualified dates (in long form) in any language.
      moment.locale(payload.shortCodeExt);
    },
    // *** USER FILTERING MANAGMENT ***
    SET_FILTERING_CONTEXT(state, payload) {
      // First check to see if this filter context has been saved previously
      if (state.filters.findIndex(x => x.context == payload.context) == -1) {
        // This is a new filter context, next check to see if it has any parental heritage stored in the existing filters
        if (state.filters.findIndex(x => x.context == payload.parentalContext) == -1) {
          // There is no entry of a parent for this new filter context so empty out the filters array,
          // you are in a new chain of potential screens with related filter contexts.

          state.filters = [];
        }

        // This is a new Filter Context so add it to the Filters array
        state.filters.push(payload);
      } else {
        // This filter context was already being tracked. Likely that someone was on a sub screen like "Modifier Values"
        // then clicked the breadcrumb to go back to the main "Modifiers" listing screen. For this situation we want to
        // clear the cached filtering data of any child screens so the user has to start over setting them up in this
        // situation. To do this we need to remove any items that have the parental heritage of the item.
        if (payload.parentalContext == null) {
          // This means that this is a top context item in a chain so you can clip all other entries below it.
          state.filters = state.filters.filter(
            x => x.context == payload.context && x.parentalContext == null
          );
        } else {
          // If we are here we know that the current Filter Context has related contextual children that are being tracked.
          // The behavior we want is that since the user has returned up the contextual chain that these child filter contexts
          // are to have their caching removed.

          let immediateChildFilterContext: Filter;
          let immediateChildFilterIndex;

          // First find the immediate contextual child of the current item if it exists (there should be at least one if we are here.)
          immediateChildFilterIndex = state.filters.findIndex(
            x => x.parentalContext == payload.context
          )!;
          while (immediateChildFilterIndex > -1) {
            // Save the info of this immediate child.
            immediateChildFilterContext = state.filters.find(
              x => x.parentalContext == payload.context
            )!;
            // Filter the overall store filters to remove this immediate child.
            state.filters = state.filters.filter(
              x => x.parentalContext != payload.context && x.parentalContext != null
            );
            // Check to see if the child that was removed had any children and repeat the process.
            immediateChildFilterIndex = state.filters.findIndex(
              x => x.parentalContext == immediateChildFilterContext.context
            );
          }
        }
      }
      state.filteringContext = payload.context;
    },
    SET_SHOW_ARCHIVED_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.showArchivedForFiltering = payload;
    },
    SET_SHOW_ARCHIVED_FOR_FILTERING_FROM_DATE(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.showArchivedForFilteringFromDate = payload;
    },
    SET_SHOW_ARCHIVED_FOR_FILTERING_TO_DATE(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.showArchivedForFilteringToDate = payload;
    },
    SET_SEARCH_STRING_FOR_FILTERING(state, payload) {
      state.filters.find(
        x => x.context == state.filteringContext
      )!.searchStringForFiltering = payload;
    },
    SET_TAGS_FOR_FILTERING(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.tagsForFiltering = payload;
    },
    SET_SELECTED_TAB_INDEX_IN_FILTERING_CONTEXT(state, payload) {
      state.filters.find(x => x.context == state.filteringContext)!.selectedTab = payload;
    },
    SET_INLINE_DIALOG_REF(state, payload) {
      if (state.inlineDialogRef == null) {
        state.inlineDialogRef = payload;
      } else if (state.inlineDialogRef != payload) {
        (state.inlineDialogRef as any).cancelDialog();
        state.inlineDialogRef = payload;
      }
    },

    // *** BREADCRUMB MANAGEMENT ***
    NOTIFY_NAVIGATION_STARTED(state) {
      // This is called before each navigation; on a page that is breadcrumb-aware the
      // currentBreadcrumb array will be non-empty; we'll copy that to our backup, clear the
      // current list of breadcrumbs, and wait for the next page to notify us that it's ready
      // for breadcrumbs; if navigation works through a page that doesn't know about breadcrumbs,
      // the currentBreadcrumb array will be empty and we'll basically be resetting the list of
      // breadcrumbs for a new navigation
      state.lastBreadcrumbs = state.currentBreadcrumbs;
      state.currentBreadcrumbs = [];
    },
    NOTIFY_NEW_BREADCRUMB(state, payload: BreadcrumbUpdate) {
      // Set the current breadcrumbs, using the last breadcrumbs as a chain unless the caller is
      // requesting a fresh breadcrumb chain
      // debugger
      var newBreadcrumb = { ...payload, disabled: true };
      if (payload.resetHistory || state.lastBreadcrumbs.length === 0) {
        state.currentBreadcrumbs = [newBreadcrumb];
      } else {
        // There is currently no predictable way to know when a person has hit the back button
        // via the Vuex route capabilities; even using onpopstate manually doesn't work because
        // that also fires when you navigate more than one step; our approach to this will be
        // to assume that going to a URL that's already in your current breadcrumbs means you
        // intend to go back (e.g. if your breadcrumbs are ['/url/1', '/url/2', '/url/3'] and
        // you navigate to '/url/2' we assume you don't want a breadcrumb list of ['/url/1',
        // '/url/2', '/url/3', '/url/2'] even though that reflects where you actually went and
        // will reflect what happens when you hit the back button); as such, we see if the new
        // URL is already in our list of URLs, and if it is we truncate the current breadcrumb
        // list at that point and use the new data for our next one
        let targetBreadcrumbIndex = state.lastBreadcrumbs.findIndex(x => x.to == payload.to);
        if (targetBreadcrumbIndex === -1) {
          targetBreadcrumbIndex = state.lastBreadcrumbs.length;
        }

        // When we create the new breadcrumb it is always in disabled state, so we need to enable
        // the second last breadcrumb as well as adding the new one; this changes if we are using
        // a back navigation to our starting point since we may no longer have a prior breadcrumb
        // to enable
        if (targetBreadcrumbIndex !== 0) {
          // We have a breadcrumb that needs to be updated
          state.currentBreadcrumbs = [
            ...state.lastBreadcrumbs.slice(0, targetBreadcrumbIndex - 1),
            {
              ...state.lastBreadcrumbs[targetBreadcrumbIndex - 1],
              disabled: false,
              exact: true
            },
            newBreadcrumb
          ];
        } else {
          // We're going back to the root of our breadcrumbs; this is once again a simple single
          // entry
          state.currentBreadcrumbs = [newBreadcrumb];
        }
      }

      // Clear the last breadcrumbs; they are not used unless we are in transition between routes
      state.lastBreadcrumbs = [];
    }
  },
  getters: {
    // Brand Image Default Numbers
    // Default
    // brandImageWidth: 300,
    // brandImageHeight: 155,
    // brandImageWidthSmaller: 300,
    // brandImageHeightSmaller: 155,
    brandImageWidth(state, getters): number {
      if (state.brandImageWidthValue < 0) {
        state.brandImageWidthValue = getters.brandImageWidth_Default;
      }
      return state.brandImageWidthValue;
    },
    brandImageWidthSmaller(state, getters): number {
      if (state.brandImageWidthSmallerValue < 0) {
        state.brandImageWidthSmallerValue = getters.brandImageWidth_Default;
      }
      return state.brandImageWidthSmallerValue;
    },
    brandImageHeight(state, getters): number {
      if (state.brandImageHeightValue < 0) {
        state.brandImageHeightValue = getters.brandImageHeight_Default;
      }
      return state.brandImageHeightValue;
    },
    brandImageHeightSmaller(state, getters): number {
      if (state.brandImageHeightSmallerValue < 0) {
        state.brandImageHeightSmallerValue = getters.brandImageHeight_Default;
      }
      return state.brandImageHeightSmallerValue;
    },
    // Brand Image Default Numbers
    // Default
    // brandImageWidth_Default: 300,
    // brandImageHeight_Default: 155,
    brandImageWidth_Default(state): number {
      let width = 300;
      return width;
    },
    brandImageHeight_Default(state): number {
      let height = 155;
      return height;
    },
    // Brand Image Default Numbers < 600px
    // Default
    // brandImageWidth_Smallest: 120,
    // brandImageHeight_Smallest: 62,
    brandImageWidth_Smallest(state): number {
      return 120;
    },
    brandImageHeight_Smallest(state): number {
      return 62;
    },
    // Brand Image Default Numbers Landscape Orientation
    // Default
    // brandImageWidth_Medium: 200,
    // brandImageHeight_Medium: 103,
    // brandImageWidth_Medium_alt: 140,
    // brandImageHeight_Medium_alt: 72,
    brandImageWidth_Medium(state): number {
      return 200;
    },
    brandImageHeight_Medium(state): number {
      return 103;
    },
    brandImageWidth_Medium_alt(state): number {
      return 140;
    },
    brandImageHeight_Medium_alt(state): number {
      return 72;
    },
    backBreadcrumb(state) {
      return state.currentBreadcrumbs[state.currentBreadcrumbs.length - 2];
    }
  },
  actions: {
    async SHOW_SNACKBAR(
      context,
      payload: {
        text: string;
        type: "success" | "error" | "info";
        undoCallback?: () => Promise<void>;
      }
    ) {
      // TODO: Consider collapsing the multiple commits below to a single mutation
      context.commit("SET_SNACKBAR", false);
      await pause(250);
      context.commit("CLEAR_SNACKBAR_COUNTDOWN_INTERVAL");
      context.commit("SET_SNACKBAR_COUNTDOWN", 20);
      context.commit("SET_SNACKBAR", true);
      context.commit("SET_SNACKBAR_TEXT", payload.text);
      context.commit("SET_SNACKBAR_UNDO_CALLBACK", payload.undoCallback);
      context.commit("SET_SNACKBAR_TYPE", payload.type);
    }
  }
});

