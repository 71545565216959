var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "settings" } },
    [
      !_vm.currentUserCanConfigureSettings
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v("\n    " + _vm._s(_vm.$t("settings.no-permission")) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "fd-toolbar",
            { attrs: { extended: "", loading: _vm.processing } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                { ref: "form", on: { submit: _vm.preventSubmit } },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-3", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "center", cols: "12" } },
                        [
                          _c("fd-text-field", {
                            attrs: {
                              label: _vm.$t("settings.org-name"),
                              disabled: _vm.processing,
                              rules: [_vm.rules.required]
                            },
                            model: {
                              value: _vm.environment.name,
                              callback: function($$v) {
                                _vm.$set(_vm.environment, "name", $$v)
                              },
                              expression: "environment.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "12",
                            md: "6"
                          }
                        },
                        [
                          _c("v-file-input", {
                            attrs: {
                              disabled: _vm.processing,
                              placeholder: _vm.$t("settings.select-image-here"),
                              "persistent-hint": "",
                              hint: _vm.$t(
                                "settings.logo-image-dimension-details"
                              )
                            },
                            model: {
                              value: _vm.logo,
                              callback: function($$v) {
                                _vm.logo = $$v
                              },
                              expression: "logo"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "12",
                            md: "6"
                          }
                        },
                        [
                          _c("v-img", {
                            ref: "img",
                            attrs: {
                              src: _vm.logoUrl,
                              "max-width": "300px",
                              "max-height": "153px"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mx-2", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mr-0",
                                  attrs: { type: _vm.inlineMessage.type }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.saving,
                            disabled:
                              _vm.processing ||
                              !_vm.currentUserCanConfigureSettings
                          },
                          on: { click: _vm.save }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.save")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }