var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.inlineMessage.message
        ? _c(
            "v-alert",
            {
              staticClass: "mt-0 mb-3",
              attrs: { type: _vm.inlineMessage.type }
            },
            [_vm._v("\n    " + _vm._s(_vm.inlineMessage.message) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fd-view no-tag-searching" },
        [
          _c(
            "v-toolbar",
            {
              class: [
                _vm.processing
                  ? "view-card-top-color-processing"
                  : "view-card-top-color"
              ],
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return undefined
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-breadcrumbs", {
                        class: [
                          _vm.processing ? "breadcrumb-processing-opacity" : "",
                          "pl-0"
                        ],
                        attrs: {
                          items: _vm.$store.state.currentBreadcrumbs,
                          large: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function() {
                              return [
                                _c("v-icon", [_vm._v("fa-chevron-right")])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-subtitle",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 pb-0 order-0", attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        staticClass: "pt-0 mt-0 pt-md-3 mt-md-1",
                        attrs: {
                          "append-icon": "search",
                          label: _vm.$t("common.search"),
                          "single-line": "",
                          "hide-details": "",
                          clearable: ""
                        },
                        model: {
                          value: _vm.tablesearch,
                          callback: function($$v) {
                            _vm.tablesearch = $$v
                          },
                          expression: "tablesearch"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            directives: [
              {
                name: "fd-column",
                rawName: "v-fd-column:name",
                value: _vm.$t("common.name"),
                expression: "$t('common.name')",
                arg: "name"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:description",
                value: _vm.$t("common.description"),
                expression: "$t('common.description')",
                arg: "description"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:acuityAppointmentTypeName",
                value: _vm.$t("acuity.appointment-type"),
                expression: "$t('acuity.appointment-type')",
                arg: "acuityAppointmentTypeName"
              },
              {
                name: "fd-column",
                rawName: "v-fd-column:enabled.hide-when-extra-small",
                arg: "enabled",
                modifiers: { "hide-when-extra-small": true }
              }
            ],
            staticClass: "fd-enabling-table",
            attrs: {
              items: _vm.sessionTypes,
              search: _vm.tablesearch,
              loading: _vm.processing,
              "loading-text": _vm.$t("common.table-loading-message"),
              "mobile-breakpoint": "0"
            },
            scopedSlots: _vm._u([
              {
                key: "item.enabled",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("v-simple-checkbox", {
                      attrs: { value: item.enabled, disabled: _vm.processing },
                      on: {
                        input: function($event) {
                          return _vm.flipEnabled(item)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }