import FDVue from "@fd/lib/vue";
import { TrackedShot, ShotTypes } from "../../services";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import i18n from "../../i18n";
import VueI18n from "vue-i18n";

type ShotWithBurner = TrackedShot & { isBurner: boolean };

function GetFriendlyShotTypeName(shotType: ShotTypes) {
  var shotTypeKey = "snap";
  if (shotType == ShotTypes.Backhand) {
    shotTypeKey = "backhand";
  } else if (shotType == ShotTypes.SlapShot) {
    shotTypeKey = "slap";
  } else if (shotType == ShotTypes.WristShot) {
    shotTypeKey = "wrist";
  }
  return i18n.t(`shot-tracker.shot-types.${shotTypeKey}`);
}

const ShotList = FDVue.extend({
  name: "fd-shot-list",

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  props: {
    items: {
      default: () => [],
      type: Array
    },
    processing: {
      default: false,
      type: Boolean
    },
    // Used to highlight the selected row when editing
    selectedId: {
      default: undefined,
      type: String
    }
  },

  data: function() {
    return {};
  },

  computed: {
    shots: {
      cache: false,
      get(): ShotWithBurner[] {
        let burner = null as TrackedShot | null;
        (this.items as TrackedShot[]).forEach(x => {
          var number = +(x.speed || 0);
          // Compares stored largest number with current number, stores the largest one
          if (!!burner && number <= burner!.speed!) return;

          burner = x;
        });
        return (this.items as TrackedShot[]).map(x => {
          return {
            ...x,
            isBurner: !!burner && x.id == burner.id
          };
        });
      }
    }
  },

  methods: {
    canRemoveShot(shot: ShotWithBurner): boolean {
      if (!this.shots.length) return false;
      let latestShot = this.shots[this.shots.length - 1];
      return shot.id == latestShot?.id;
    },
    removeLatestShot(e: Event) {
      this.$emit("remove-latest-shot");
      e.stopPropagation();
    },
    rowClick(item: ShotWithBurner) {
      // Shots is cached, so doing an `indexOf` of the object doesn't work. Get the index of the id isntead
      var index = this.shots.map(x => x.id!).indexOf(item.id!);
      let shot = this.items[index];
      this.$emit("rowClick", shot);
    },
    getAverageSpeed(shots: TrackedShot[]): number {
      let speeds = shots.map(x => +(x.speed ?? 0));
      let totalSpeed = speeds.reduce((a, b) => a! + b!, 0);
      let avgSpeed = !!totalSpeed && speeds.length > 0 ? totalSpeed / speeds.length : 0;
      return +avgSpeed.toFixed(2);
    },
    getAverageAccuracy(shots: TrackedShot[]): number {
      let accuracies = shots.map(x => x.accuracyRating);
      let totalAccuracy = accuracies.reduce((a, b) => a! + b!, 0);
      let avgAcc = !!totalAccuracy && accuracies.length > 0 ? totalAccuracy / accuracies.length : 0;
      return +avgAcc.toFixed(2);
    }
  }
});

export default ShotList;

