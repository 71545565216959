var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "tight" },
      on: { "click:outside": _vm.closeDialog },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _vm.previousPreviousItem
                    ? _c(
                        "v-row",
                        {
                          staticClass:
                            "previousPreview i-2 itemPreview blurText-2",
                          on: {
                            click: function($event) {
                              return _vm.rowClick(_vm.previousPreviousItem)
                            }
                          }
                        },
                        [
                          _c("v-col", { attrs: { cols: "5" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.previousPreviousItem.playerName) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.previousPreviousItem.playerEmailAddress
                                ) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.previousItem
                    ? _c(
                        "v-row",
                        {
                          staticClass:
                            "previousPreview i-1 itemPreview blurText-1",
                          on: {
                            click: function($event) {
                              return _vm.rowClick(_vm.previousItem)
                            }
                          }
                        },
                        [
                          _c("v-col", { attrs: { cols: "5" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.previousItem.playerName) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.previousItem.playerEmailAddress) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editingItem
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "5" } },
                            [
                              _c("v-text-field", {
                                ref: "firstField",
                                attrs: {
                                  placeholder: "Player Name",
                                  label: "Player Name",
                                  disabled: _vm.readonly || _vm.processing,
                                  autofocus: "",
                                  rules: _vm.challengeRules.playerName
                                },
                                on: { keydown: _vm.firstFieldKeyDown },
                                model: {
                                  value: _vm.editingItem.playerName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editingItem, "playerName", $$v)
                                  },
                                  expression: "editingItem.playerName"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "7" } },
                            [
                              _c("v-text-field", {
                                ref: "lastField",
                                attrs: {
                                  label: "Player Email Address",
                                  placeholder: "Player Email Address",
                                  disabled: _vm.readonly || _vm.processing,
                                  rules: _vm.challengeRules.playerEmail
                                },
                                on: { keydown: _vm.lastFieldKeyDown },
                                model: {
                                  value: _vm.editingItem.playerEmailAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editingItem,
                                      "playerEmailAddress",
                                      $$v
                                    )
                                  },
                                  expression: "editingItem.playerEmailAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nextItem
                    ? _c(
                        "v-row",
                        {
                          staticClass: "nextPreview i-1 itemPreview blurText-1",
                          on: {
                            click: function($event) {
                              return _vm.rowClick(_vm.nextItem)
                            }
                          }
                        },
                        [
                          _c("v-col", { attrs: { cols: "5" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.nextItem.playerName) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.nextItem.playerEmailAddress) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.nextNextItem
                    ? _c(
                        "v-row",
                        {
                          staticClass: "nextPreview i-2 itemPreview blurText-2",
                          on: {
                            click: function($event) {
                              return _vm.rowClick(_vm.nextNextItem)
                            }
                          }
                        },
                        [
                          _c("v-col", { attrs: { cols: "5" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.nextNextItem.playerName) +
                                "\n          "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "7" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.nextNextItem.playerEmailAddress) +
                                "\n          "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }