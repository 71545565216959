<template>
    
  <v-app>
    <fd-app-bar v-if="showAppBar" />

    <fd-drawer v-if="showDrawer" />

<!--    <fd-settings />-->
    <fd-view />
    <fd-snackbar />
    <fd-bottom-bar v-if="showBottomBar" />
  </v-app>
</template>

<script>
  export default {
    name: 'fd-index',

    components: {
      "fd-app-bar": () => import('./components/layout/AppBar'),
      "fd-drawer": () => import('./components/layout/Drawer'),
      "fd-bottom-bar": () => import('./components/layout/BottomBar'),
      "fd-settings": () => import('./components/layout/Settings'),
      "fd-view": () => import('./components/layout/View'),
      "fd-snackbar": () => import('./components/layout/SnackBar'),
    },
    data: () => ({
      expandOnHover: false,
    }),

    computed: {
      // Used to store the value of the search bar in the store so that if you go to the existing screen and back,
      // that the users filtering setup they have chosen will be preserved.
      showAppBar: {
        get () {
          return this.$store.state.showAppBar;
        },
        set (val) {
          this.$store.commit('SET_SHOW_APP_BAR', val)
        },
      },
      showDrawer: {
        get () {
          return this.$store.state.showDrawer;
        },
        set (val) {
          this.$store.commit('SET_SHOW_DRAWER', val)
        },
      },
      showBottomBar: {
        get () {
            return this.$store.state.showBottomBar;
        },
        set (val) {
            this.$store.commit('SET_SHOW_BOTTOM_BAR', val)
        },
      }
    }
  }
</script>
