import { Tag } from "./index";

// TODO: Properties are optional here only because we've defined our items too loosely
export interface TaggableItem {
  tagIDs?: string[];
  name?: string;
}

export function filterByTags<T extends TaggableItem>(
  tagsSelectedForFiltering: Tag[],
  taggableItems: T[]
): T[] {
  if (tagsSelectedForFiltering.length) {
    let returnValueParts = taggableItems.filter(x => {
      if (!x.tagIDs) return false;
      for (let tag of tagsSelectedForFiltering) {
        if (x.tagIDs.indexOf(tag.id!) !== -1) return true;
      }
      return false;
    });
    // Make sure to sort the returned list by "Name" ascending
    //return returnValueParts.sort((a, b) => { return (a.name!.toLowerCase() < b.name!.toLowerCase()) ? -1 : (a.name!.toLowerCase() > b.name!.toLowerCase()) ? 1 : 0; });
    // CANNOT guarantee that the object being sorted has a property of "name". The "User" object is an example of this.
    return returnValueParts;
  } else {
    return taggableItems;
  }
}

export function getTagsInUse<T extends TaggableItem>(tags: Tag[], taggableItems: T[]): Tag[] {
  let returnValueTags = tags.filter(x => {
    let returnValuePartIndex = taggableItems.findIndex(y => {
      let returnValueTagIndex = y.tagIDs?.indexOf(x.id!);
      return returnValueTagIndex !== undefined && returnValueTagIndex !== -1;
    });
    return returnValuePartIndex !== -1;
  });
  // Make sure to sort the returned list by "Name" ascending
  //return returnValueTags.sort((a, b) => { return (a.name && b.name && (a.name?.toLowerCase() < b.name.toLowerCase())) ? -1 : (a.name && b.name && (a.name.toLowerCase() > b.name.toLowerCase())) ? 1 : 0; });
  // CANNOT guarantee that the object being sorted has a property of "name". The "User" object is an example of this.
  return returnValueTags;
}
