import Vue from "vue";
import Router from "vue-router";

import store from "./store";
import { checkLogin } from "./login";
// Login Services
import { loginService } from "./services";

Vue.use(Router);

var router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Settings",
      path: "/settings",
      component: () => import("./views/Settings.vue")
    },
    {
      name: "Tags",
      path: "/tags",
      component: () => import("../../common/client/views/Tags.vue")
    },
    {
      name: "TagExisting",
      path: "/tags/:id",
      component: () => import("../../common/client/views/TagExisting.vue")
    },
    {
      name: "People",
      path: "/people",
      component: () => import("./views/People.vue")
    },
    {
      name: "PersonExisting",
      path: "/people/:id",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "PersonSearchResult",
      path: "/person/:id",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "PersonalProfile",
      path: "/personalprofile/",
      component: () => import("./views/PersonExisting.vue")
    },
    {
      name: "Default",
      path: "/",
      component: () => import("./views/LandingPage.vue")
    },
    {
      name: "LandingPage",
      path: "/landingpage",
      component: () => import("./views/LandingPage.vue")
    },
    {
      name: "EmailAddressExisting",
      path: "/emailaddress/:emailAddressID",
      component: () => import("../../common/client/views/EmailAddressExisting.vue")
    },
    {
      name: "PhoneNumberExisting",
      path: "/phonenumber/:phoneNumberID",
      component: () => import("../../common/client/views/PhoneNumberExisting.vue")
    },
    {
      name: "MobileMore",
      path: "/mobilemore",
      component: () => import("./views/MobileMore.vue")
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("../../common/client/views/Login.vue")
    },
    {
      name: "ShotTracker",
      path: "/shottracker/:sessionID",
      component: () => import("./views/ShotTracker.vue")
    },
    {
      name: "Sessions",
      path: "/sessions",
      component: () => import("./views/Sessions.vue")
    },
    {
      name: "SkillsChallengeStations",
      path: "/skillschallengestations",
      component: () => import("./views/SkillsChallengeStations.vue")
    },
    {
      name: "SessionTypes",
      path: "/sessiontypes",
      component: () => import("./views/SessionTypes.vue")
    },
    {
      name: "SkillsChallenge",
      path: "/skillschallenge/:sessionID",
      component: () => import("./views/SkillsChallenge.vue")
    }
  ]
});

router.beforeResolve(async function(to, from, next) {
  let targetRoute = to.fullPath;
  //First check to see if the call is attempting to exercise the "forgot password" workflow and DO NOT check the authentication
  //status of the user since they obviously cannot be signed in if they are attempting to reset their password.
  if (targetRoute == "/login") {
    loginService.logout();
    store.commit("NOTIFY_NAVIGATION_STARTED");
    next();
  } else if (targetRoute != "/login" && !(await checkLogin())) {
    // If we don't have a login prior to running, do that first
    store.commit("SET_LOGIN_RETURN_PATH", targetRoute);
    next("/login");
  } else {
    // We've confirmed the user is logged in and navigating to a path "inside" the application
    // As such, we can show the UI bars
    store.commit("SET_SHOW_APP_BAR", true);
    store.commit("SET_SHOW_DRAWER", true);
    store.commit("SET_SHOW_FOOTER", true);
    store.commit("SET_SHOW_BOTTOM_BAR", true);

    // Before committing to navigation, reset the breadcrumb state to nothing; when a new page comes
    // up that wants to use a breadcrumb it will announce itself, which will replace the breadcrumb
    // state
    store.commit("NOTIFY_NAVIGATION_STARTED");
    next();
  }
});

export default router;

