var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "tight", width: "800px", persistent: "" },
      on: { "click:outside": _vm.cancelDialog },
      model: {
        value: _vm.twoWayProps.show,
        callback: function($$v) {
          _vm.$set(_vm.twoWayProps, "show", $$v)
        },
        expression: "twoWayProps.show"
      }
    },
    [
      _c(
        "v-form",
        { ref: "form", on: { submit: _vm.onSubmit } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  class: [
                    _vm.processing
                      ? "dialog-new-top-color-processing"
                      : "dialog-new-top-color"
                  ],
                  staticStyle: { position: "sticky", top: "0", "z-index": "1" }
                },
                [
                  _c(
                    "span",
                    {
                      class: [
                        _vm.processing ? "breadcrumb-processing-opacity" : ""
                      ]
                    },
                    [_vm._v(_vm._s(_vm.$t("sessions.new-player-title")))]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.processing
                    ? _c("v-progress-circular", {
                        attrs: {
                          indeterminate: true,
                          rotate: 0,
                          size: 32,
                          width: 4,
                          color: "white"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center justify-space-between",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-text-field", {
                            ref: "playerNameField",
                            attrs: {
                              label: _vm.$t("sessions.player-name"),
                              disabled: _vm.processing || _vm.saving,
                              rules: _vm.challengeRules.playerName,
                              autofocus: ""
                            },
                            model: {
                              value: _vm.newChallenge.playerName,
                              callback: function($$v) {
                                _vm.$set(_vm.newChallenge, "playerName", $$v)
                              },
                              expression: "newChallenge.playerName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "align-center justify-space-between",
                          attrs: { cols: "12" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("sessions.player-email"),
                              disabled: _vm.processing || _vm.saving,
                              rules: _vm.challengeRules.playerEmail
                            },
                            model: {
                              value: _vm.newChallenge.playerEmailAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newChallenge,
                                  "playerEmailAddress",
                                  $$v
                                )
                              },
                              expression: "newChallenge.playerEmailAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-expansion-panels",
                        {
                          attrs: { tile: "" },
                          model: {
                            value: _vm.panel,
                            callback: function($$v) {
                              _vm.panel = $$v
                            },
                            expression: "panel"
                          }
                        },
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c("v-expansion-panel-header", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("sessions.player-history")) +
                                    "\n              "
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-expansion-panel-content",
                                {
                                  staticStyle: {
                                    "max-height": "230px",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _c("v-data-table", {
                                    directives: [
                                      {
                                        name: "fd-column",
                                        rawName: "v-fd-column:playerName",
                                        value: _vm.$t("sessions.player-name"),
                                        expression:
                                          "$t('sessions.player-name')",
                                        arg: "playerName"
                                      },
                                      {
                                        name: "fd-column",
                                        rawName:
                                          "v-fd-column:playerEmailAddress",
                                        value: _vm.$t("sessions.player-email"),
                                        expression:
                                          "$t('sessions.player-email')",
                                        arg: "playerEmailAddress"
                                      }
                                    ],
                                    attrs: {
                                      items: _vm.savedChallenges,
                                      loading: _vm.processing,
                                      "loading-text": _vm.$t(
                                        "common.table-loading-message"
                                      ),
                                      "items-per-page": _vm.items,
                                      "mobile-breakpoint": "0"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                {
                  staticStyle: {
                    "background-color": "white",
                    position: "sticky",
                    bottom: "0",
                    "z-index": "1"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _vm.inlineMessage.message
                            ? _c(
                                "v-alert",
                                { attrs: { type: _vm.inlineMessage.type } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "white-space": "pre-line" }
                                    },
                                    [_vm._v(_vm._s(_vm.inlineMessage.message))]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-2", attrs: { align: "center" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          !_vm.savedChallenges.length
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    disabled: _vm.processing || _vm.saving
                                  },
                                  on: { click: _vm.cancelDialog }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.close")))]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    disabled: _vm.processing || _vm.saving
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.closeDialog(true)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.close")))]
                              ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.saving,
                                disabled: _vm.processing
                              },
                              on: { click: _vm.saveChallenge }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.save")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }