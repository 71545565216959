var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "data-cy": "personexisting" } },
    [
      _c("fd-image-viewer", {
        attrs: { name: _vm.imageName },
        model: {
          value: _vm.imageSource,
          callback: function($$v) {
            _vm.imageSource = $$v
          },
          expression: "imageSource"
        }
      }),
      _vm._v(" "),
      _c("fd-image-editor", {
        attrs: { name: _vm.imageName },
        on: { edit: _vm.handleEdit },
        model: {
          value: _vm.editImageSource,
          callback: function($$v) {
            _vm.editImageSource = $$v
          },
          expression: "editImageSource"
        }
      }),
      _vm._v(" "),
      !(_vm.currentUserCanConfigureSettings || _vm.isPersonalProfile)
        ? _c("v-alert", { staticClass: "mt-0 mb-3", attrs: { type: "info" } }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("people.no-permission-to-edit")) + "\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-slide-x-reverse-transition",
            [
              _c(
                "v-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.slidein,
                      expression: "slidein"
                    }
                  ]
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      class: [
                        _vm.processing
                          ? "view-card-top-color-processing"
                          : "view-card-top-color"
                      ]
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _c("v-breadcrumbs", {
                                    class: [
                                      "d-sm-flex",
                                      "pl-3",
                                      { "d-none": !_vm.isPersonalProfile },
                                      {
                                        "breadcrumb-processing-opacity":
                                          _vm.processing
                                      }
                                    ],
                                    attrs: {
                                      items:
                                        _vm.$store.state.currentBreadcrumbs,
                                      large: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "divider",
                                        fn: function() {
                                          return [
                                            _c("v-icon", [
                                              _vm._v("fa-chevron-right")
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ])
                                  }),
                                  _vm._v(" "),
                                  !_vm.isPersonalProfile
                                    ? _c("fd-back-button", {
                                        attrs: {
                                          route: function(item) {
                                            return _vm.$router.push(
                                              _vm.$store.getters.backBreadcrumb
                                                .to || "/people"
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.processing
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-3",
                                        attrs: {
                                          indeterminate: true,
                                          rotate: 0,
                                          size: 32,
                                          width: 4,
                                          color: "white"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { fluid: "" } },
                    [
                      _vm.userHasLoaded && _vm.user.isActiveWithUnusedLogin
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "mt-3 mb-0 mx-3",
                              attrs: {
                                color: "warning",
                                transition: "scale-transition"
                              }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { align: "center", "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "shrink pr-2" },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticStyle: { color: "inherit" } },
                                        [_vm._v("fas fa-snooze")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { staticClass: "grow pr-1" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "people.user-not-activated-message"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { staticClass: "shrink" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "white"
                                          },
                                          on: {
                                            click: _vm.resendNewAccountEmail
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "people.user-activation-resend"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-tabs",
                        {
                          ref: "tabs",
                          staticClass: "mt-3",
                          attrs: {
                            "icons-and-text": "",
                            "show-arrows": !_vm.$vuetify.breakpoint.xsOnly,
                            "next-icon": "fa-arrow-circle-right",
                            "prev-icon": "fa-arrow-circle-left"
                          },
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            {
                              key: _vm.detailsTab.key,
                              ref: "tab",
                              attrs: {
                                "data-cy": "details",
                                href: "#tab-" + _vm.detailsTab.key
                              },
                              on: {
                                click: function($event) {
                                  return _vm.tabSelected(_vm.detailsTab)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("common.details")) +
                                  "\n              "
                              ),
                              _c("v-icon", [_vm._v("fas fa-edit")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.emailTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.emailTab.key,
                                  attrs: {
                                    "data-cy": "email",
                                    href: "#tab-" + _vm.emailTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.emailTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.email-shorthand")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-at")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mobileTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.mobileTab.key,
                                  attrs: {
                                    "data-cy": "phone",
                                    href: "#tab-" + _vm.mobileTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.mobileTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.mobile")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-mobile-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPersonalProfile &&
                          (_vm.securityTab.visible ||
                            _vm.$vuetify.breakpoint.smAndUp)
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.securityTab.key,
                                  attrs: {
                                    "data-cy": "security",
                                    href: "#tab-" + _vm.securityTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.securityTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.security")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-shield-alt")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.filesTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.filesTab.key,
                                  attrs: {
                                    "data-cy": "files",
                                    href: "#tab-" + _vm.filesTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.filesTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.files")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [_vm._v("fas fa-folder-open")])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.sessionsTab.visible ||
                          _vm.$vuetify.breakpoint.smAndUp
                            ? _c(
                                "v-tab",
                                {
                                  key: _vm.sessionsTab.key,
                                  attrs: {
                                    "data-cy": "sessions",
                                    href: "#tab-" + _vm.sessionsTab.key
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.tabSelected(_vm.sessionsTab)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("sessions.title")) +
                                      "\n              "
                                  ),
                                  _c("v-icon", [
                                    _vm._v("fas fa-clipboard-list")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", left: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "align-self-center mr-4 d-flex d-sm-none more-tab-button",
                                              staticStyle: {
                                                position: "relative"
                                              },
                                              attrs: { text: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("common.more")) +
                                              "\n\n                  "
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [
                                                _vm._v("fas fa-ellipsis-h")
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { staticClass: "grey lighten-3" },
                                _vm._l(_vm.hiddenTabDefinitions, function(
                                  item
                                ) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.tabname,
                                      on: {
                                        click: function($event) {
                                          return _vm.showNewTabFromMoreMenu(
                                            item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.tabname) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.active_tab,
                            callback: function($$v) {
                              _vm.active_tab = $$v
                            },
                            expression: "active_tab"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.detailsTab.key,
                              attrs: { value: "tab-" + _vm.detailsTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "detailsform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mx-0 px-2 mb-3 fd-inline-request-tags-qualifier",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("fd-chip-selector", {
                                            attrs: {
                                              "available-items":
                                                _vm.userValues.tags,
                                              "selected-items": _vm.user.tags,
                                              "item-label": "name",
                                              disabled:
                                                _vm.isPersonalProfile ||
                                                _vm.processing,
                                              "control-label": _vm.$t(
                                                "common.add-tags"
                                              ),
                                              "chip-class":
                                                "tags-selection-chip-colors",
                                              outlined: false
                                            },
                                            on: {
                                              "update:selectedItems": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.user,
                                                  "tags",
                                                  $event
                                                )
                                              },
                                              "update:selected-items": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.user,
                                                  "tags",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "pa-3",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "avatar-cropper-container",
                                          attrs: {
                                            align: "center",
                                            cols: "12",
                                            sm: "12",
                                            md: "6",
                                            lg: "4"
                                          }
                                        },
                                        [
                                          _c(
                                            "slim-cropper",
                                            {
                                              staticClass: "profile-image",
                                              attrs: {
                                                image: _vm.userPhoto,
                                                disabled: _vm.processing,
                                                label: _vm.$t(
                                                  "common.drop-avatar-image-here"
                                                )
                                              },
                                              on: {
                                                "update:image": function(
                                                  $event
                                                ) {
                                                  _vm.userPhoto = $event
                                                }
                                              }
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "file",
                                                  name: "slim"
                                                }
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 mt-0 mb-0",
                                          attrs: {
                                            cols: "12",
                                            sm: "12",
                                            md: "6",
                                            lg: "8"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-1 pb-lg-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "common.firstname"
                                                      ),
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules.firstName
                                                    },
                                                    model: {
                                                      value: _vm.user.firstName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "firstName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.firstName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "common.lastname"
                                                      ),
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules.lastName
                                                    },
                                                    model: {
                                                      value: _vm.user.lastName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "lastName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.lastName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-date-picker", {
                                                    attrs: {
                                                      cy: "plannederect",
                                                      label: _vm.$t(
                                                        "common.birth-date"
                                                      ),
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules.birthDate,
                                                      "persistent-hint": !!_vm
                                                        .user.ageString,
                                                      hint: _vm.user.ageString,
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value: _vm.user.birthDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "birthDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.birthDate"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "people.sex.label"
                                                      ),
                                                      items: _vm.sexOptions,
                                                      disabled: _vm.processing
                                                    },
                                                    model: {
                                                      value: _vm.user.sexID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "sexID",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "user.sexID"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 mt-0 mb-0",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            { staticClass: "pl-0" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("people.stick.label")
                                                )
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mt-0 mb-5"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            { staticClass: "pt-0 pb-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "people.stick.brand"
                                                      ),
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules.stickBrand
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.stick.brand,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user.stick,
                                                          "brand",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.stick.brand"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      label: _vm.$t(
                                                        "people.handedness.label"
                                                      ),
                                                      items:
                                                        _vm.handednessOptions,
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules
                                                          .stickHandedness
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.stick
                                                          .handednessID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user.stick,
                                                          "handednessID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.stick.handednessID"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "people.stick.flex"
                                                      ),
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules.stickFlex
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.stick.flex,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user.stick,
                                                          "flex",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.stick.flex"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pt-1 pb-0 pt-lg-0",
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "people.stick.curve"
                                                      ),
                                                      disabled: _vm.processing,
                                                      rules:
                                                        _vm.userRules.stickCurve
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.stick.curve,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user.stick,
                                                          "curve",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.stick.curve"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 pb-0 pt-md-3",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "v-subheader",
                                            { staticClass: "pl-0" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.account"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mt-0 mb-5"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            { staticClass: "pt-0 pb-0" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      outlined: "",
                                                      "item-text": "name",
                                                      "item-value": "id",
                                                      items:
                                                        _vm.userValues
                                                          .languageID,
                                                      label: _vm.$t(
                                                        "languages.preferred-language-select"
                                                      ),
                                                      disabled: _vm.processing,
                                                      clearable: "",
                                                      "persistent-hint": "",
                                                      hint: _vm.$t(
                                                        "languages.preferred-language-inline-info"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user.languageID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "languageID",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.languageID"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    sm: "12",
                                                    md: "6"
                                                  }
                                                },
                                                [
                                                  _c("fd-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "people.account-identifier"
                                                      ),
                                                      outlined: "",
                                                      "persistent-hint": "",
                                                      disabled: "",
                                                      readonly: true,
                                                      hint: _vm.$t(
                                                        "people.account-identifier-hint"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.user
                                                          .personalIdentifier,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.user,
                                                          "personalIdentifier",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "user.personalIdentifier"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "data-cy": "archive",
                                              label: _vm.$t("common.archived"),
                                              disabled:
                                                _vm.isPersonalProfile ||
                                                _vm.processing
                                            },
                                            model: {
                                              value: _vm.user.isArchived,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isArchived",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isArchived"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.emailTab.key,
                              attrs: { value: "tab-" + _vm.emailTab.key }
                            },
                            [
                              _c("fd-user-email-address-new", {
                                attrs: {
                                  show: _vm.newEmailAddressDialogVisible,
                                  personID: _vm.userID
                                },
                                on: {
                                  cancel: _vm.cancelNewEmailAddressDialog,
                                  emailAdded: _vm.emailAddressAdded
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1 mr-1",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                fab: ""
                                              },
                                              on: {
                                                click:
                                                  _vm.openNewEmailAddressDialog
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "people.user-email-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.emailAddressTableSearch,
                                              callback: function($$v) {
                                                _vm.emailAddressTableSearch = $$v
                                              },
                                              expression:
                                                "emailAddressTableSearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:emailAddress",
                                            value: _vm.$t("common.address"),
                                            expression: "$t('common.address')",
                                            arg: "emailAddress"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:verified.hide-when-extra-small",
                                            value: _vm.$t("common.verified"),
                                            expression: "$t('common.verified')",
                                            arg: "verified",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:allowNotifications.hide-when-extra-small",
                                            value: _vm.$t("common.notify"),
                                            expression: "$t('common.notify')",
                                            arg: "allowNotifications",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small.class_fd-actions-cell",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true,
                                              "class_fd-actions-cell": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: function(item) {
                                              return _vm.$router.push(
                                                "/emailaddress/" + item.id
                                              )
                                            },
                                            expression:
                                              "\n                      item => $router.push(`/emailaddress/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass: "fd-enabling-table",
                                        attrs: {
                                          items: _vm.user.emailAddresses,
                                          search: _vm.emailAddressTableSearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["address"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.allowNotifications",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.allowNotifications
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.allowNotifications
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.verified",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.isVerified
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !!item.isVerified
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "teal",
                                                          "text-color": "white"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to:
                                                                            "/emailaddress/" +
                                                                            item.id
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deletePersonalEmailAddress(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.mobileTab.key,
                              attrs: { value: "tab-" + _vm.mobileTab.key }
                            },
                            [
                              _c("fd-user-phone-number-new", {
                                attrs: {
                                  show: _vm.newPhoneNumberDialogVisible,
                                  personID: _vm.userID
                                },
                                on: {
                                  cancel: _vm.cancelNewPhoneNumberDialog,
                                  phoneNumberAdded: _vm.phoneNumberAdded
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-card",
                                { attrs: { flat: "", tile: "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticStyle: { "margin-top": "15px" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1 mr-1",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                fab: ""
                                              },
                                              on: {
                                                click:
                                                  _vm.openNewPhoneNumberDialog
                                              }
                                            },
                                            [
                                              _c("v-icon", [_vm._v("mdi-plus")])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider", {
                                            staticClass: "mx-4",
                                            attrs: { inset: "", vertical: "" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              color: "black",
                                                              dark: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "people.user-mobile-number-listing-information"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            staticClass: "pl-0",
                                            attrs: {
                                              "append-icon": "search",
                                              label: _vm.$t("common.search"),
                                              "single-line": "",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.phoneNumberTableSearch,
                                              callback: function($$v) {
                                                _vm.phoneNumberTableSearch = $$v
                                              },
                                              expression:
                                                "phoneNumberTableSearch"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-data-table", {
                                        directives: [
                                          {
                                            name: "fd-column",
                                            rawName: "v-fd-column:phoneNumber",
                                            value: _vm.$t("common.number"),
                                            expression: "$t('common.number')",
                                            arg: "phoneNumber"
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:verified.hide-when-extra-small",
                                            value: _vm.$t("common.verified"),
                                            expression: "$t('common.verified')",
                                            arg: "verified",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:allowNotifications.hide-when-extra-small",
                                            value: _vm.$t("common.notify"),
                                            expression: "$t('common.notify')",
                                            arg: "allowNotifications",
                                            modifiers: {
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-column",
                                            rawName:
                                              "v-fd-column:actions.no-sort.hide-when-extra-small",
                                            arg: "actions",
                                            modifiers: {
                                              "no-sort": true,
                                              "hide-when-extra-small": true
                                            }
                                          },
                                          {
                                            name: "fd-row-navigate",
                                            rawName:
                                              "v-fd-row-navigate.show-when-extra-small",
                                            value: function(item) {
                                              return _vm.$router.push(
                                                "/phonenumber/" + item.id
                                              )
                                            },
                                            expression:
                                              "\n                      item => $router.push(`/phonenumber/${item.id}`)\n                    ",
                                            modifiers: {
                                              "show-when-extra-small": true
                                            }
                                          }
                                        ],
                                        staticClass:
                                          "fd-actions-table fd-enabling-table",
                                        attrs: {
                                          items: _vm.user.phoneNumbers,
                                          search: _vm.phoneNumberTableSearch,
                                          loading: _vm.processing,
                                          "loading-text": _vm.$t(
                                            "common.table-loading-message"
                                          ),
                                          "sort-by": ["phoneNumber"],
                                          "mobile-breakpoint": "0"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item.allowNotifications",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.allowNotifications
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.allowNotifications
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.verified",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                !item.isVerified
                                                  ? _c("v-chip", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t("common.no")
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !!item.isVerified
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "teal",
                                                          "text-color": "white"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.yes"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.actions",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "table-actions-column-min-width"
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          link:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing,
                                                                          to:
                                                                            "/phonenumber/" +
                                                                            item.id
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-pencil\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.edit"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          disabled:
                                                                            _vm.processing
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deletePersonalPhoneNumber(
                                                                                item
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                mdi-delete\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.remove"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.securityTab.key,
                              attrs: { value: "tab-" + _vm.securityTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "securityform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass:
                                            "d-flex align-center justify-start fd-additional-config-control-callout ml-0 mr-0 pt-5 pb-5"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "fd-security-tab-extra-info d-none d-sm-flex"
                                            },
                                            [_vm._v("fas fa-user-shield")]
                                          ),
                                          _vm._v(" "),
                                          _c("v-switch", {
                                            staticClass:
                                              "fd-security-tab-switch-placement",
                                            attrs: {
                                              label: _vm.$t(
                                                "people.can-sign-in-label"
                                              ),
                                              disabled:
                                                _vm.user.isArchived ||
                                                !_vm.userHasContactMethod ||
                                                _vm.processing,
                                              "persistent-hint": "",
                                              hint: _vm.$t(
                                                "people.can-sign-in-hint"
                                              )
                                            },
                                            model: {
                                              value: _vm.user.isLoginActive,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "isLoginActive",
                                                  $$v
                                                )
                                              },
                                              expression: "user.isLoginActive"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.user.isArchived ||
                                          !_vm.userHasContactMethod
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "pb-0",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _vm.user.isArchived
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          staticClass: "mb-0",
                                                          attrs: {
                                                            type: "info"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "people.existing-person.unarchive-to-allow-login"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : !_vm.userHasContactMethod
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          staticClass: "mb-0",
                                                          attrs: {
                                                            type: "info"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "people.existing-person.add-contact-method-to-allow-login"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-2 ml-5" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12",
                                                sm: "6",
                                                md: "4",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "people.user-security-configure-settings"
                                                  ),
                                                  disabled:
                                                    !_vm.user.isLoginActive ||
                                                    _vm.processing,
                                                  "persistent-hint": "",
                                                  hint: _vm.$t(
                                                    "people.user-security-configure-settings-hint"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.user
                                                      .canConfigureSettings,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "canConfigureSettings",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "user.canConfigureSettings"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.filesTab.key,
                              attrs: { value: "tab-" + _vm.filesTab.key }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "filesform",
                                  on: { submit: _vm.preventSubmit }
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "", tile: "" } },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-2",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "12" }
                                            },
                                            [
                                              _c(
                                                "v-card-title",
                                                [
                                                  _c("fd-add-file-button", {
                                                    attrs: {
                                                      disabled: _vm.processing
                                                    },
                                                    on: {
                                                      change: _vm.selectNewFile
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c("v-divider", {
                                                    staticClass: "mx-4",
                                                    attrs: {
                                                      inset: "",
                                                      vertical: ""
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u([
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                    attrs: {
                                                                      color:
                                                                        "black",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [_vm._v("info")]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ])
                                                    },
                                                    [
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "people.files-table-listing-tooltip"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    staticClass: "pl-0",
                                                    attrs: {
                                                      "append-icon": "search",
                                                      label: _vm.$t(
                                                        "common.search"
                                                      ),
                                                      "single-line": "",
                                                      "hide-details": "",
                                                      clearable: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tablesearchfiles,
                                                      callback: function($$v) {
                                                        _vm.tablesearchfiles = $$v
                                                      },
                                                      expression:
                                                        "tablesearchfiles"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-data-table", {
                                                directives: [
                                                  {
                                                    name: "fd-column",
                                                    rawName: "v-fd-column:name",
                                                    value: _vm.$t(
                                                      "common.name"
                                                    ),
                                                    expression:
                                                      "$t('common.name')",
                                                    arg: "name"
                                                  },
                                                  {
                                                    name: "fd-column",
                                                    rawName:
                                                      "v-fd-column:actions.no-sort.class_fd-actions-cell",
                                                    arg: "actions",
                                                    modifiers: {
                                                      "no-sort": true,
                                                      "class_fd-actions-cell": true
                                                    }
                                                  }
                                                ],
                                                attrs: {
                                                  items: _vm.files,
                                                  search: _vm.tablesearchfiles,
                                                  loading: _vm.processing,
                                                  "loading-text": _vm.$t(
                                                    "common.table-loading-message"
                                                  ),
                                                  "sort-by": ["name"],
                                                  "mobile-breakpoint": "0"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "item.actions",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "table-4-actions-column-min-width"
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp,
                                                                  left: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.viewFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-eye\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.view"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    !item.isPreviewable ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.editFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-pencil\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.edit"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  link:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.downloadFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-cloud-download\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.download"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  disabled: !_vm
                                                                    .$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp,
                                                                  top: ""
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "activator",
                                                                      fn: function(
                                                                        ref
                                                                      ) {
                                                                        var on =
                                                                          ref.on
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                attrs: {
                                                                                  icon:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.isPersonalProfile ||
                                                                                    _vm.processing
                                                                                }
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    small:
                                                                                      ""
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteFile(
                                                                                        item
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    mdi-delete\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      }
                                                                    }
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "common.remove"
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            {
                              key: _vm.sessionsTab.key,
                              attrs: { value: "tab-" + _vm.sessionsTab.key }
                            },
                            [
                              _c("v-data-table", {
                                directives: [
                                  {
                                    name: "fd-column",
                                    rawName: "v-fd-column:startedTime",
                                    value: _vm.$t("common.start-date"),
                                    expression: "$t('common.start-date')",
                                    arg: "startedTime"
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:sessionTypeName.hide-when-extra-small",
                                    value: _vm.$t("common.type"),
                                    expression: "$t('common.type')",
                                    arg: "sessionTypeName",
                                    modifiers: { "hide-when-extra-small": true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:sessionStatus.hide-when-small",
                                    value: _vm.$t("sessions.status"),
                                    expression: "$t('sessions.status')",
                                    arg: "sessionStatus",
                                    modifiers: { "hide-when-small": true }
                                  },
                                  {
                                    name: "fd-column",
                                    rawName:
                                      "v-fd-column:action.no-sort.class_fd-action-cell",
                                    arg: "action",
                                    modifiers: {
                                      "no-sort": true,
                                      "class_fd-action-cell": true
                                    }
                                  }
                                ],
                                attrs: {
                                  items: _vm.sessions,
                                  search: _vm.sessiontablesearch,
                                  loading: _vm.processing,
                                  "loading-text": _vm.$t(
                                    "common.table-loading-message"
                                  ),
                                  "sort-by": ["startedTime"],
                                  "sort-desc": true,
                                  "mobile-breakpoint": "0"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item.startedTime",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.formatDateTime(
                                                item.startedTime
                                              )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.sessionStatus",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              !item.completedTime
                                                ? _vm.$t(
                                                    "sessions.in-progress-status-message"
                                                  )
                                                : _vm.$t(
                                                    "sessions.complete-status-message"
                                                  )
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.action",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        !!item && !item.completedTime
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.workSession(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-clipboard-play"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "sessions.work-session"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : !!item && !!item.completedTime
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      icon: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.viewSession(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "mdi-table-eye"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "sessions.view-session"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-form",
                        { ref: "form", on: { submit: _vm.onSubmit } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "mx-2", attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _vm.inlineMessage.message
                                    ? _c(
                                        "v-alert",
                                        {
                                          staticClass: "mr-0",
                                          attrs: {
                                            type: _vm.inlineMessage.type
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-line"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.inlineMessage.message
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              !_vm.isPersonalProfile
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        link: "",
                                        disabled: _vm.processing,
                                        color: "error",
                                        outlined: "",
                                        small:
                                          _vm.$vuetify.breakpoint.name == "xs"
                                      },
                                      on: { click: _vm.deleteItem }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.delete")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.currentUserCanConfigureSettings &&
                              _vm.isPersonalProfile
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        outlined: "",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.loadFullDetails }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "people.existing-person.full-details-button-label"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.currentUserCanConfigureSettings &&
                              _vm.isPersonalProfile
                                ? _c("div", { staticClass: "ml-4 mr-3" }, [
                                    _vm._v(
                                      "\n                |\n              "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isPersonalProfile
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        link: "",
                                        disabled: _vm.processing
                                      },
                                      on: { click: _vm.cancel }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("fd-menu-save", {
                                attrs: {
                                  disabled:
                                    _vm.processing ||
                                    !(
                                      _vm.currentUserCanConfigureSettings ||
                                      _vm.isPersonalProfile
                                    ),
                                  loading: _vm.saving,
                                  small: _vm.$vuetify.breakpoint.xsOnly,
                                  "can-close": !_vm.isPersonalProfile
                                },
                                on: {
                                  "click:save": function($event) {
                                    return _vm.save(false)
                                  },
                                  "click:save-and-close": function($event) {
                                    return _vm.save(true)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }