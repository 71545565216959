var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    directives: [
      {
        name: "fd-column",
        rawName: "v-fd-column:burner.class_fd-table-icon-cell",
        value: "",
        expression: "''",
        arg: "burner",
        modifiers: { "class_fd-table-icon-cell": true }
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:sessionShotNumber",
        value: _vm.$t("shot-tracker.shot-number"),
        expression: "$t('shot-tracker.shot-number')",
        arg: "sessionShotNumber"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:speed",
        value: _vm.$t("shot-tracker.speed"),
        expression: "$t('shot-tracker.speed')",
        arg: "speed"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:accuracyRating",
        value: _vm.$t("shot-tracker.accuracy-rating"),
        expression: "$t('shot-tracker.accuracy-rating')",
        arg: "accuracyRating"
      },
      {
        name: "fd-column",
        rawName: "v-fd-column:action.class_fd-action-cell",
        arg: "action",
        modifiers: { "class_fd-action-cell": true }
      }
    ],
    attrs: {
      items: _vm.shots,
      loading: _vm.processing,
      "mobile-breakpoint": "0",
      "disable-sort": ""
    },
    on: {
      mouseover: function(e) {
        return _vm.emit("mouseover", e)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "body.append",
        fn: function(ref) {
          return [
            _c("tr", { staticClass: "fd-table-totals-row" }, [
              _c("td", { attrs: { colspan: "2" } }, [
                _c("strong", [_vm._v("Averages")])
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.getAverageSpeed(_vm.items)) + " mph")
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(_vm.getAverageAccuracy(_vm.items)) + " stars")
              ]),
              _vm._v(" "),
              _c("td", [_vm._v("\n         \n        ")])
            ])
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "tr",
              {
                class: { selected: item.id == _vm.selectedId },
                on: {
                  click: function($event) {
                    return _vm.rowClick(item)
                  }
                }
              },
              [
                _c(
                  "td",
                  { staticClass: "text-xs-right fd-table-icon-cell" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      { class: { "d-none": !item.isBurner } },
                                      on
                                    ),
                                    [_vm._v("fa-fire")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("shot-tracker.burner-shot")) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "text-xs-right" }, [
                  _vm._v(_vm._s(item.sessionShotNumber))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-xs-right" }, [
                  _vm._v(_vm._s(item.speed) + " mph")
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-xs-right" },
                  [
                    _c("v-rating", {
                      attrs: {
                        "background-color": "orange lighten-5",
                        color: "orange lighten-2",
                        readonly: "",
                        length: "3"
                      },
                      model: {
                        value: item.accuracyRating,
                        callback: function($$v) {
                          _vm.$set(item, "accuracyRating", $$v)
                        },
                        expression: "item.accuracyRating"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "fd-action-cell" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          disabled:
                            !_vm.$vuetify.breakpoint.lgAndUp || _vm.processing,
                          left: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          icon: "",
                                          disabled:
                                            _vm.processing ||
                                            !_vm.canRemoveShot(item)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            disabled: _vm.processing
                                          },
                                          on: { click: _vm.removeLatestShot }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-delete\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$t("common.remove")))])
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }