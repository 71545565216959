import FDVue from "@fd/lib/vue";
import { AccessInformation } from "../services";

export default FDVue.extend({
  computed: {
    curUserID(): string {
      return this.$store.state.curUserID;
    },
    curUserAccess(): AccessInformation {
      return this.$store.state.curUserAccess;
    },
    curUserAccessibleKeys(): string[] {
      return Object.keys(this.curUserAccess).filter(
        accessKey => accessKey.startsWith("can") && (this.curUserAccess as any)[accessKey] == true
      );
    },
    currentUserCanConfigureSettings(): Boolean {
      return this.curUserAccess?.canConfigureSettings ?? false;
    }
  }
});
