import FDVue from "@fd/lib/vue";
import errorHandling from "@fd/lib/vue/mixins/errorHandling";
import fileHandling, {
  confirmUniqueName,
  isFilePreviewable,
  FileData,
  isFilePhoto
} from "@fd/lib/vue/mixins/fileHandling";
import { mapMutations } from "vuex";
import i18n from "../i18n";
import userData from "../dataMixins/person";
import {
  userService,
  personalEmailAddressService,
  personalPhoneNumberService,
  loginService,
  sessionService,
  SessionWithType,
  sessionTypeService,
  SessionTypeWithAppointmentType,
  personStickService,
  PersonStick
} from "../services/index";
import { VForm } from "@fd/lib/vue/types";
import Slim from "@fd/lib/vue/components/SlimCropper.vue";
import { FDColumnDirective, FDRowNavigateDirective } from "@fd/lib/vue/utility/dataTable";
import downloadBlob from "@fd/lib/client-util/downloadBlob";
import tabbedView, { Tab } from "@fd/lib/vue/mixins/tabbedView";
import { showAccessCodeEntryDialog } from "../../../common/client/views/components/AccessCodeEntryDialog.vue";
import userAccess from "../dataMixins/userAccess";
import { getYearsDifference } from "../../../lib/client-util/datetime";
import * as DateUtil from "@fd/lib/client-util/datetime";

export default FDVue.extend({
  name: "fd-User-Existing",

  mixins: [errorHandling, tabbedView, userData.load(), userAccess, fileHandling],

  components: {
    "fd-chip-selector": () => import("@fd/lib/vue/components/ChipItemSelector.vue"),
    "fd-back-button": () => import("@fd/lib/vue/components/BackButton.vue"),
    "fd-user-email-address-new": () =>
      import("../../../common/client/views/components/EmailAddressNewDialog.vue"),
    "fd-user-phone-number-new": () =>
      import("../../../common/client/views/components/PhoneNumberNewDialog.vue"),
    "slim-cropper": Slim,
    "fd-inline-edit-dialog": () => import("@fd/lib/vue/components/InlineEditDialog.vue"),
    "fd-add-file-button": () => import("@fd/lib/vue/components/AddFileButton.vue")
  },

  directives: {
    fdColumn: FDColumnDirective,
    fdRowNavigate: FDRowNavigateDirective
  },

  data: function() {
    return {
      // *** GLOBAL ***
      // The following will control whether or not the save button shows the processing/loading indicator
      saving: false,
      userHasLoaded: false,
      isPersonalProfile: false,

      slidein: false,

      detailserror: false,
      permissionserror: false,
      fileserror: false,

      // The following defines all the non "Details" tabs for the view and controls whether they are visible.
      // This is quite relevant for when the platform is on a mobile device in portrait orientation.
      firstTabKey: `0`,
      detailsTab: {
        tabname: "Details",
        key: "0",
        visible: true
      } as Tab,
      emailTab: {
        tabname: "Email",
        key: "1",
        visible: false
      } as Tab,
      mobileTab: {
        tabname: "Mobile",
        key: "2",
        visible: false
      } as Tab,
      securityTab: {
        tabname: "Security",
        key: "3",
        visible: false
      } as Tab,
      filesTab: {
        tabname: "Files",
        key: "4",
        visible: false
      } as Tab,
      sessionsTab: {
        tabname: "Sessions",
        key: "5",
        visible: false
      } as Tab,

      // *** DETAILS ***
      userPhoto: null as File | null,
      originalUserPhoto: null as File | null,

      // *** EMAIL ***
      newEmailAddressDialogVisible: false,
      emailAddressTableSearch: "",

      // *** MOBILE ***
      newPhoneNumberDialogVisible: false,
      phoneNumberTableSearch: "",

      // *** FILES ***
      tablesearchfiles: "",
      // Files are uploaded immediately upon selection, so we don't need to track locally added files
      files: [] as FileData[],
      /*** IMAGE EDIT ****/
      newFileData: undefined as FileData | undefined,
      editingFileData: undefined as FileData | undefined,

      // *** SESSIONS ***
      sessiontablesearch: "",
      sessions: [] as SessionWithType[],
      sessionTypes: [] as SessionTypeWithAppointmentType[]
    };
  },

  computed: {
    tabDefinitions(): Tab[] {
      // Details is not included since it's the first tab and is always visible
      return [
        this.emailTab,
        this.mobileTab,
        this.securityTab,
        this.filesTab,
        this.sessionsTab
      ] as Tab[];
    },

    unwatchedMethodNames(): string[] {
      return ["formatDateTime"];
    },

    // *** SECURITY ***
    userHasContactMethod(): boolean {
      return !!this.user.emailAddresses?.length || !!this.user.phoneNumbers?.length;
    }
  },

  methods: {
    async _initialize() {
      console.log(`beforeUpdate`);
      var userID = this.$route.params.id;
      if (this.$route.name == "PersonalProfile") {
        this.isPersonalProfile = true;
        userID = this.curUserID;
      } else {
        this.isPersonalProfile = false;
      }
      this.processing = true;
      this.userHasLoaded = false;
      await this.loadUser(userID);
      this.userHasLoaded = true;

      var sessionTypes = await sessionTypeService.getAll();
      this.sessionTypes = sessionTypes;
      await this.loadSessions();

      let userPhoto = await userService.downloadUserPhoto(userID);
      if (userPhoto) {
        this.userPhoto = this.originalUserPhoto = new File([userPhoto], "userPhoto", {
          type: userPhoto.type
        });
      }

      await Promise.all([this.loadUserFiles()]);

      this.processing = false;
    },

    async loadFullDetails() {
      if (!this.isPersonalProfile) return;
      this.$router.push(`/people/${this.curUserID}`);
      this._initialize();
    },

    // *** GLOBAL ***
    onSubmit(e: Event) {
      e.preventDefault();
      this.save(false);
    },

    preventSubmit(e: Event) {
      e.preventDefault();
      return false;
    },

    async resendNewAccountEmail() {
      await loginService.sendNewAccountNotice(
        `${document.location.protocol}//${document.location.host}`,
        this.userID!
      );
    },

    validate(): boolean {
      this.detailserror = !((this.$refs.detailsform as VForm)?.validate() ?? true);
      this.fileserror = !((this.$refs.filesform as VForm)?.validate() ?? true);
      return !(this.detailserror || this.fileserror);
    },

    // Method used in conjunction with the Save button.
    async save(closeOnComplete: boolean) {
      if (!this.validate()) {
        var message = i18n.t("people.existing-person.error-message");
        if (this.detailserror) message += "\n\t- " + i18n.t("people.existing-person.tabs.details");
        if (this.fileserror) message += "\n\t- " + i18n.t("people.existing-person.tabs.files");

        this.inlineMessage.message = message;
        this.inlineMessage.type = "error";

        return;
      }

      if (
        this.user.isLoginActive &&
        !this.user.emailAddresses?.length &&
        !this.user.phoneNumbers?.length
      ) {
        this.permissionserror = true;

        this.inlineMessage.message = this.$t("people.existing-person.login-requires-contact-info");
        this.inlineMessage.type = "error";

        return;
      }

      this.saving = true;
      try {
        var archivedDate = null;
        if (this.user.isArchived && !this.user.archivedDate) {
          archivedDate = new Date(new Date().toUTCString());
        }
        this.user.archivedDate = archivedDate;

        var loginDisabledDate = null;
        // If we're archiving a person who is able to login, disable their login as well
        if ((this.user.isArchived || !this.user.isLoginActive) && !this.user.loginDisabledDate) {
          loginDisabledDate = new Date(new Date().toUTCString());
        }
        this.user.loginDisabledDate = loginDisabledDate;

        if (this.isPersonalProfile) {
          await this.savePartialUser();
        } else {
          await this.saveUser();
        }

        if (!!this.user.stick.id) {
          await personStickService.updateItem(this.user.stick.id!, this.user.stick);
        } else {
          this.user.stick.id = await personStickService.addItem(this.user.stick);
        }

        if (this.userPhoto !== this.originalUserPhoto) {
          if (this.userPhoto) {
            await userService.uploadUserPhoto(this.userID, this.userPhoto);
          } else {
            await userService.deleteUserPhoto(this.userID);
          }
        }
        // User's file's are added/deleted in real time.  They don't need to be saved here.

        if (!this.isPersonalProfile) {
          if (closeOnComplete) {
            this.$router.push("/people");
          }
        }
      } catch (error) {
        this.handleError(error as Error, "people.save-network-error");
      } finally {
        this.saving = false;
      }
    },

    async deleteItem() {
      if (this.isPersonalProfile) return;

      await this.deleteUser();
      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/people");
    },

    // Method used in conjunction with the Cancel button.
    cancel() {
      if (this.isPersonalProfile) return;

      this.$router.push(this.$store.getters.backBreadcrumb?.to || "/people");
    },

    ...mapMutations({
      setUser: "SET_USER",
      notifyNewBreadcrumb: "NOTIFY_NEW_BREADCRUMB",
      setFilteringContext: "SET_FILTERING_CONTEXT"
    }),

    // *** DETAILS ***

    // *** EMAIL ADDRESSES ***
    async deletePersonalEmailAddress(item: any) {
      if (!this.user.emailAddresses) return;

      this.processing = true;
      try {
        await personalEmailAddressService.deleteItem(item.id!);
        const index = this.user.emailAddresses.indexOf(item);
        if (index < 0) {
          return;
        }
        this.user.emailAddresses.splice(index, 1);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    openNewEmailAddressDialog() {
      this.newEmailAddressDialogVisible = true;
    },

    cancelNewEmailAddressDialog() {
      this.newEmailAddressDialogVisible = false;
    },

    async emailAddressAdded(item: any) {
      if (!this.user.emailAddresses) this.user.emailAddresses = [];

      this.user.emailAddresses.push(item);
      this.newEmailAddressDialogVisible = false;

      if (this.userID != this.curUserID) return;

      this.optOutOfErrorHandling();
      if (await showAccessCodeEntryDialog(item.emailAddress, null)) {
        this.loadUser(this.userID);
      }
    },

    /// *** PHONE NUMBER ***
    async deletePersonalPhoneNumber(item: any) {
      if (!this.user.phoneNumbers) return;

      this.processing = true;
      try {
        await personalPhoneNumberService.deleteItem(item.id!);
        const index = this.user.phoneNumbers.indexOf(item);
        if (index < 0) {
          return;
        }
        this.user.phoneNumbers.splice(index, 1);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    openNewPhoneNumberDialog() {
      this.newPhoneNumberDialogVisible = true;
    },

    cancelNewPhoneNumberDialog() {
      this.newPhoneNumberDialogVisible = false;
    },

    async phoneNumberAdded(item: any) {
      if (!this.user.phoneNumbers) this.user.phoneNumbers = [];

      this.user.phoneNumbers.push(item);
      this.newPhoneNumberDialogVisible = false;

      if (this.userID != this.curUserID) return;

      this.optOutOfErrorHandling();
      if (await showAccessCodeEntryDialog(null, item.phoneNumber)) {
        this.loadUser(this.userID);
      }
    },

    // *** FILES ***
    async loadUserFiles() {
      this.processing = true;
      try {
        var fileNames = await userService.getUserFileList(this.userID);
        this.files = fileNames.map(function(fileName) {
          return {
            name: fileName,
            isPreviewable: isFilePreviewable(fileName),
            isPhoto: isFilePhoto(fileName)
          };
        });
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async selectNewFile(file: any) {
      var fileData = await this.optimizedFileDataForUpload(file, this.files);
      if (!fileData) return;

      if (fileData.isPreviewable) {
        this.newFileData = fileData;
        this.imageName = fileData.name;
        this.editImageSource = this.covertFileToDataURL(fileData.file);
      } else {
        await this.saveNewFileData(fileData);
      }
    },
    async handleEdit(res: File, fileName: string | undefined) {
      this.editImageSource = undefined;
      this.imageName = "";

      if (!!this.newFileData) {
        this.newFileData.file = res;
        if (!!fileName) this.newFileData.name = confirmUniqueName(fileName, this.files);

        await this.saveNewFileData(this.newFileData);

        this.newFileData = undefined;
      } else if (!!this.editingFileData) {
        var originalFileName = this.editingFileData.name;

        var allFilesWithoutEditedFileData = this.files.slice();
        allFilesWithoutEditedFileData.splice(
          allFilesWithoutEditedFileData.indexOf(this.editingFileData),
          1
        );
        var uniqueFileName = confirmUniqueName(
          fileName ?? originalFileName,
          allFilesWithoutEditedFileData
        );

        this.editingFileData.name = uniqueFileName;
        this.editingFileData.file = res;

        this.saveEditedFileData(this.editingFileData, originalFileName);

        this.editingFileData = undefined;
      }
    },
    async saveEditedFileData(fileData: FileData, originalFileName: string) {
      if (!fileData) return;

      this.processing = true;
      try {
        await userService.uploadUserFile(this.userID, fileData.name, fileData.file as Blob);

        if (!!originalFileName && originalFileName != fileData.name) {
          // File has been renamed.  The file in the list has already been updated with all relevant data, but we need to delete the file with the old name
          // We don't call the delete method here because we don't care about its data, an undo, or a delete snackbar
          await userService.deleteUserFile(this.userID, originalFileName);
        }

        var snackbarPayload = {
          text: this.$t("people.existing-person.update-file-success", [fileData.name]),
          type: "success"
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async saveNewFileData(fileData: FileData | undefined) {
      if (!fileData) return;

      this.processing = true;
      try {
        await userService.uploadUserFile(this.userID, fileData.name, fileData.file as Blob);
        this.files.push(fileData);

        var snackbarPayload = {
          text: this.$t("people.existing-person.save-file-success", [fileData.name]),
          type: "success",
          undoCallback: null
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    editFile(fileData: FileData) {
      if (fileData.isPreviewable) {
        this.editingFileData = fileData;
        this.imageName = fileData.name;
        if (!!fileData.file) {
          this.editImageSource = this.covertFileToDataURL(fileData.file);
        } else {
          this.editImageSource = `/services/FormidableDesigns.Services.V1.UserService.DownloadUserFile?personId=${this.userID}&fileName=${fileData.name}`;
        }
      }
    },
    async downloadFile(fileData: FileData) {
      let fileName = fileData.name;
      this.processing = true;
      try {
        var file = await userService.downloadUserFile(this.userID, fileName);
        downloadBlob(file, fileName);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },
    async viewFile(fileData: FileData) {
      this.imageName = fileData.name;
      if (!!fileData.file) {
        this.imageSource = this.covertFileToDataURL(fileData.file);
      } else {
        let url = `/services/FormidableDesigns.Services.V1.UserService.DownloadUserFile?personId=${this.userID}&fileName=${fileData.name}`;
        this.imageSource = url;
      }
    },
    async deleteFile(fileData: any) {
      this.processing = true;
      try {
        if (!fileData.file) {
          // When deleting from the table, the data probably hasn't been downloaded yet
          // So we can't do an undo unless we get the file data to re-save first
          fileData.file = await userService.downloadUserFile(this.userID, fileData.name);
        }
        await userService.deleteUserFile(this.userID, fileData.name);

        this.files.splice(this.files.indexOf(fileData), 1);

        var snackbarPayload = {
          text: this.$t("people.existing-person.delete-file-success", [fileData.name]),
          type: "info",
          undoCallback: async () => {
            await this.saveNewFileData(fileData);
          }
        };
        this.$store.dispatch("SHOW_SNACKBAR", snackbarPayload);
      } catch (error) {
        this.handleError(error as Error);
      } finally {
        this.processing = false;
      }
    },

    // *** SESSIONS ***
    async loadSessions() {
      this.sessions = await sessionService.getByPersonID(this.userID);
    },

    formatDateTime(date: Date | string | undefined | null) {
      return DateUtil.localizedDateTimeString(date);
    },
    workSession(session: SessionWithType) {
      let shotTrackingID = this.sessionTypes.filter(x => x.name == "Shot Tracking")[0].id;
      if (session.sessionTypeID == shotTrackingID) {
        this.$router.push(`/shottracker/${session.id}`);
      } else {
        this.$router.push(`/skillschallenge/${session.id}`);
      }
    },
    viewSession(session: SessionWithType) {
      let shotTrackingID = this.sessionTypes.filter(x => x.name == "Shot Tracking")[0].id;
      if (session.sessionTypeID == shotTrackingID) {
        this.$router.push(`/shottracker/${session.id}`);
      } else {
        this.$router.push(`/skillschallenge/${session.id}`);
      }
    }
  },

  watch: {
    "user.birthDate": function() {
      this.user.age = getYearsDifference(this.user.birthDate);
      this.user.ageString = !!this.user.birthDate
        ? `${getYearsDifference(this.user.birthDate)} ${this.$t("common.years")}`
        : undefined;
    },
    "user.isLoginActive": function(newValue, oldValue) {
      if (!newValue) {
        this.user.canConfigureSettings = false;
      }
    },
    user(newValue) {
      this.userHasLoaded = !!newValue;

      if (this.isPersonalProfile) {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: this.$t("people.existing-person.profile-title"),
          to: "/personalprofile",
          resetHistory: true
        });
      } else if (this.$route.name == "PersonSearchResult") {
        // This is needed in order to salvage the "last breadcrumbs" in the store.
        this.$store.commit("NOTIFY_NAVIGATION_STARTED");

        this.notifyNewBreadcrumb({
          text: newValue.firstName + " " + newValue.lastName,
          to: `/person/${this.userID}`
        });
      } else {
        // Since we might be coming to this screen from anywhere in the system (via the "Profile" menu access from the Avatar button),
        // We may need to reset the breadcrumbs since they could be pointing "Back" to the wrong screen.
        if ((this.$store.state.lastBreadcrumbs[0]?.to || "") != "/people") {
          this.notifyNewBreadcrumb({
            text: i18n.t("people.menu-title"),
            to: "/people",
            resetHistory: true
          });
          // This is needed in order to salvage the "last breadcrumbs" in the store.
          this.$store.commit("NOTIFY_NAVIGATION_STARTED");
        }
        this.notifyNewBreadcrumb({
          text: newValue.firstName + " " + newValue.lastName,
          to: `/people/${this.userID}`
        });
      }
    }
  },

  created: async function() {
    // Add a small delay of time before the view comes in so that the "slide in" animation will be seen by the user.
    setInterval(() => {
      this.slidein = true;
    }, 100);

    // Set the context for the User Filtering in the store so that if the user navigates to a screen that is
    // a sub screen of something that is currently filtered by their choices that those choices will be
    // preserved as they move between the two screens.
    this.setFilteringContext({
      context: "users-existing",
      parentalContext: "users",
      selectedTab: this.firstTabKey
    });

    await this._initialize();
  },

  beforeUpdate: async function() {
    console.log(`beforeUpdate`);
    var wasProfile = this.isPersonalProfile;
    var willBeProfile = !this.$route.params.id;
    var detailIDsDifferent =
      !!this.$route.params.id && !!this.userID && this.userID != this.$route.params.id;
    var didNavigate = wasProfile != willBeProfile || detailIDsDifferent;

    if (didNavigate) {
      this.initUser();
      await this._initialize();
    }
  }
});

